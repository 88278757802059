import { Card } from "@merge-api/merge-javascript-shared";
import ConfigurationTypeButtons from "../buttons/ConfigurationTypeButtons";
import { useEffect, useState } from "react";
import {
  AuthConfigIntegrationBuilder,
  DateTimeFormatEnums,
  GeneralAuthType,
  TokenExpiresInType,
} from "../../../utils/Entities";
import ScraperConfiguration from "../scraper/ScraperConfiguration";
import SFTPConfiguration from "../sftp/SFTPConfiguration";
import { Row } from "../../../shared/postman-table/PostmanTableRow";
import NameConfiguration from "./NameConfiguration";
import BinaryChoiceButtons from "../shared/BinaryChoiceButtons";
import LineIndent from "../shared/LineIndent";
import OAuthGrantPage from "../oauth/OAuthGrantPage";
import {
  ClientCredentialSource,
  GrantTypes,
  ParamsLocation,
  PgpKeys,
} from "../../../../integrations/auth-config/AuthConfigShared";
import AuthenticationConfigurationSetupOptionsRenderedComponents from "./AuthenticationConfigurationSetupOptionsRenderedComponents";
import { AuthType } from "../../../../../models/Entities";
import {
  addHttpsToString,
  formatPostmanRowsToString,
  mapAuthTypeToGeneralAuthType,
  mapAuthTypeToOAuthStates,
  parseStringToPostmanTableRows,
  removeHttpsFromString,
} from "../../../utils/helpers";
import useCreateOrPatchAuthenticationConfiguration from "../../hooks/useCreateOrPatchAuthenticationConfiguration";
import useIntegrationBuilderContext from "../../../context/useIntegrationBuilderContext";
import BasicAuthWithTokenExchange from "../non-oauth/BasicAuthWithTokenExchange";
import useDeleteAuthenticationConfiguration from "../../hooks/useDeleteAuthenticationConfiguration";
import { DiffModelTypeEnum } from "../../../../../models/DiffModels";
import EditorLeavingGuard from "../../../../shared/unsaved-changes/EditorLeavingGuard";
import PermissionWrapper from "../../../shared/PermissionWrapper";

interface AuthenticationConfigurationSetupOptionsProps {
  selectedAuthConfig: AuthConfigIntegrationBuilder | undefined;
  integrationID: string;
  setAuthConfigs: React.Dispatch<React.SetStateAction<AuthConfigIntegrationBuilder[] | undefined>>;
  isNewAuthConfig: boolean;
}

const AUTHENTICATION_CONFIGURATION_SUBMIT_TEXT = "Save auth config";
const AUTHENTICATION_CONFIGURATION_DELETE_TEXT = "Delete auth config";

const AuthenticationConfigurationSetupOptions = ({
  selectedAuthConfig,
  integrationID,
  setAuthConfigs,
  isNewAuthConfig,
}: AuthenticationConfigurationSetupOptionsProps) => {
  // Retrieve IntegrationBuilder context
  const {
    integration,
    setOnSubmit,
    setOnDelete,
    setCurrentStateForDiff,
    setNewStateForDiff,
    computeHasUnsavedChanges,
    hasPermissionToEdit,
    setHasPermissionToEdit,
  } = useIntegrationBuilderContext();

  // Used to determine whether to use SOAP or JSON body for basic auth exchange token body format
  const isSoapIntegration = integration?.is_soap_integration ?? false;

  /*
   * Field states
   */

  // name
  const [authConfigName, setAuthConfigName] = useState<string | undefined>(
    selectedAuthConfig?.name ?? undefined
  );

  // auth state
  const [generalAuthConfigType, setGeneralAuthConfigType] = useState<GeneralAuthType | undefined>(
    mapAuthTypeToGeneralAuthType(selectedAuthConfig?.auth_type)
  );
  const [actualAuthType, setActualAuthType] = useState<AuthType | undefined>(
    selectedAuthConfig?.auth_type
  );
  const {
    isOAuth: initialIsOAuth,
    isNonOAuthBasedTokenExchange: initialIsNonOAuthBasedTokenExchange,
  } = mapAuthTypeToOAuthStates(selectedAuthConfig?.auth_type);

  /*
   * All auth flows
   */
  const [additionalHeaderFields, setAdditionalHeaderFields] = useState<Row[]>(
    parseStringToPostmanTableRows(selectedAuthConfig?.additional_header_fields)
  );

  /*
   * OAuth flow
   */
  const [oAuthSetSoapBodyHeaderToNull, setOAuthSetSoapBodyHeaderToNull] = useState<boolean>(
    selectedAuthConfig?.oauth_set_soap_body_header_to_null ?? false
  );
  const [isOAuth, setIsOAuth] = useState<boolean | undefined>(initialIsOAuth);
  const [grantType, setGrantType] = useState<GrantTypes | undefined>(
    selectedAuthConfig?.oauth_grant_type
  );

  const [oAuthParamsScopes, setOAuthParamsScopes] = useState<string | undefined>(
    selectedAuthConfig?.oauth_params_scopes ?? undefined
  );

  // auth url card states
  const [oAuthAuthorizeURL, setOAuthAuthorizeURL] = useState<string | undefined>(
    removeHttpsFromString(selectedAuthConfig?.oauth_authorize_url) ?? undefined
  );

  const [redirectQueryParams, setRedirectQueryParams] = useState<string[] | null>(
    selectedAuthConfig?.oauth_authorize_url_additional_redirect_uri_query_param_keys ?? null
  );
  const [doesOAuthUsePKCE, setDoesOAuthUsePKCE] = useState<boolean | undefined>(
    selectedAuthConfig?.oauth_use_pkce ?? false
  );
  const [clientCredentialSourceChoice, setClientCredentialSourceChoice] = useState<
    ClientCredentialSource | undefined
  >(selectedAuthConfig?.oauth_client_credential_source ?? undefined);
  const [oAuthClientID, setOAuthClientID] = useState<string | undefined>(
    selectedAuthConfig?.oauth_client_id
  );
  const [oAuthClientSecret, setOAuthClientSecret] = useState<string | undefined>(
    selectedAuthConfig?.oauth_client_secret
  );

  // auth url advanced
  const [oAuthShouldFetchAccessToken, setOAuthShouldFetchAccessToken] = useState<
    boolean | undefined
  >(selectedAuthConfig?.oauth_should_fetch_access_token ?? false);

  const [oAuthAuthorizeURLParamKeysToExclude, setOAuthAuthorizeURLParamKeysToExclude] = useState<
    string[] | null
  >(selectedAuthConfig?.oauth_authorize_url_param_keys_to_exclude ?? null);

  // token url request card states
  const [tokenRequestURL, setTokenRequestURL] = useState<string | undefined>(
    removeHttpsFromString(selectedAuthConfig?.oauth_token_url) ?? undefined
  );
  const [tokenURLRequestHeaders, setTokenURLRequestHeaders] = useState<Row[]>(
    parseStringToPostmanTableRows(selectedAuthConfig?.oauth_token_url_header_format)
  );
  const [tokenURLRequestParams, setTokenURLRequestParams] = useState<string[] | null>(
    selectedAuthConfig?.oauth_request_fields_to_exclude ?? null
  );
  const [requestParamType, setRequestParamType] = useState<ParamsLocation | undefined>(
    selectedAuthConfig?.oauth_token_url_params_location
  );

  // response card states
  const [doesResponseIncludeExpiresInParam, setDoesResponseIncludeExpiresInParam] = useState<
    boolean | undefined
  >(selectedAuthConfig?.oauth_access_token_lifespan_in_seconds ? false : true); // default this to true if there is no value for oauth_access_token_lifespan_in_seconds
  const [tokenExpiresInType, setTokenExpiresInType] = useState<TokenExpiresInType | undefined>(
    selectedAuthConfig?.oauth_response_created_datetime_format ||
      (selectedAuthConfig?.oauth_response_created_at_key_path &&
        selectedAuthConfig?.oauth_response_created_at_key_path.length > 0)
      ? TokenExpiresInType.CREATED
      : TokenExpiresInType.FETCHED
  ); // if either oauth_response_created_datetime_format or oauth_response_created_at_key_path is filled out, default to CREATED, else use FETCHED

  const [oAuthResponseTokenKeyPath, setOAuthResponseTokenKeyPath] = useState<string[] | null>(
    selectedAuthConfig?.oauth_response_token_key_path ?? null
  );
  const [oAuthResponseCreatedAtKeyPath, setOAuthResponseCreatedAtKeyPath] = useState<
    string[] | null
  >(selectedAuthConfig?.oauth_response_created_at_key_path ?? null);

  const [dateTimeFormat, setDateTimeFormat] = useState<DateTimeFormatEnums | undefined>(
    selectedAuthConfig?.oauth_response_created_datetime_format ?? DateTimeFormatEnums.ISO_8601
  );
  const [oAuthResponseTokenLifespanInSeconds, setOAuthResponseTokenLifespanInSeconds] = useState<
    number | undefined
  >(selectedAuthConfig?.oauth_access_token_lifespan_in_seconds ?? undefined);

  // advanced token url states
  const [oAuthTokenURLHeaderKeysToExclude, setOAuthTokenURLHeaderKeysToExclude] = useState<
    string[] | null
  >(selectedAuthConfig?.oauth_token_url_header_keys_to_exclude ?? null);

  // refresh url card states
  const [oAuthRefreshURL, setOAuthRefreshURL] = useState<string | undefined>(
    removeHttpsFromString(selectedAuthConfig?.oauth_refresh_url) ?? undefined
  );
  const [shouldRefreshAfterInitialLink, setShouldRefreshAfterInitialLink] = useState<
    boolean | undefined
  >(selectedAuthConfig?.oauth_should_refresh_access_token ?? false);
  const [shouldUseTokenURLForRefresh, setShouldUseTokenURLForRefresh] = useState<
    boolean | undefined
  >(selectedAuthConfig?.oauth_refresh_url ? true : false);
  const [doesRefreshIncludeExpiresIn, setDoesRefreshIncludeExpiresIn] = useState<
    boolean | undefined
  >(selectedAuthConfig?.oauth_refresh_token_lifespan_in_seconds ? false : true); // if oauth_refresh_token_lifespan is filled out default to false, otherwise select true
  const [oAuthRefreshTokenLifespanInSeconds, setOAuthRefreshTokenLifespanInSeconds] = useState<
    number | undefined
  >(selectedAuthConfig?.oauth_refresh_token_lifespan_in_seconds ?? undefined);

  // advanced refresh url states
  const [refreshURLFieldsToExclude, setRefreshURLFieldsToExclude] = useState<string[] | null>(
    selectedAuthConfig?.oauth_refresh_fields_to_exclude ?? null
  );
  /*
   * Basic auth flow
   */

  const [basicAuthAdditionalAuthFieldKeys, setBasicAuthAdditionalAuthFieldKeys] = useState<
    string[] | null
  >(selectedAuthConfig?.additional_auth_field_keys ?? null);

  const [isNonOAuthBasedTokenExchange, setIsNonOAuthBasedTokenExchange] = useState<
    boolean | undefined
  >(initialIsNonOAuthBasedTokenExchange);
  const [basicAuthKeyHeaderFormat, setBasicAuthKeyHeaderFormat] = useState<
    // also used for scrapers
    Row[]
  >(parseStringToPostmanTableRows(selectedAuthConfig?.basic_auth_key_header_format));

  // basic auth w/ token exchange request card states
  const [shouldConvertBodyDataToString, setShouldConvertBodyDataToString] = useState<
    boolean | undefined
  >(
    isSoapIntegration
      ? true
      : selectedAuthConfig?.basic_auth_key_token_exchange_request_data_convert_to_string ?? false
  );

  const [basicAuthExchangeURL, setBasicAuthExchangeURL] = useState<string | undefined>(
    removeHttpsFromString(selectedAuthConfig?.basic_auth_key_token_exchange_url) ?? undefined
  );
  const [refreshTokenHeaders, setRefreshTokenHeaders] = useState<Row[]>(
    parseStringToPostmanTableRows(selectedAuthConfig?.basic_auth_key_token_exchange_header_format)
  );
  const [refreshTokenBodyParams, setRefreshTokenBodyParams] = useState<Row[]>(
    parseStringToPostmanTableRows(
      selectedAuthConfig?.basic_auth_key_token_exchange_request_data_format
    )
  );
  const [refreshTokenStringBodyParams, setRefreshTokenStringBodyParams] = useState<
    string | undefined
  >(selectedAuthConfig?.basic_auth_key_token_exchange_request_data_format ?? undefined);

  // basic auth w/ token exchange response card states
  const [
    basicAuthKeyTokenExchangeResponseAuthKeyPath,
    setBasicAuthKeyTokenExchangeResponseAuthKeyPath,
  ] = useState<string[] | null>(
    selectedAuthConfig?.basic_auth_key_token_exchange_response_auth_key_path ?? null
  );
  const [
    basicAuthKeyTokenExchangeAccessTokenLifespanInSeconds,
    setBasicAuthKeyTokenExchangeAccessTokenLifespanInSeconds,
  ] = useState<number | undefined>(
    selectedAuthConfig?.basic_auth_key_token_exchange_access_token_lifespan_in_seconds ?? undefined
  );
  const [
    basicAuthKeyTokenAccessTokenLifespanKeyPath,
    setBasicAuthKeyTokenAccessTokenLifespanKeyPath,
  ] = useState<string[] | null>(
    selectedAuthConfig?.basic_auth_key_token_access_token_lifespan_key_path ?? null
  );
  const [isTokenLifespanInResponse, setIsTokenLifespanInResponse] = useState<boolean | undefined>(
    selectedAuthConfig?.basic_auth_key_token_access_token_lifespan_key_path == null ||
      selectedAuthConfig?.basic_auth_key_token_access_token_lifespan_key_path?.length === 0
      ? false
      : true
  );

  /*
   * SFTP
   */

  const [shouldEndUserProvidePublicKey, setShouldEndUserProvidePublicKey] = useState<
    boolean | undefined
  >(selectedAuthConfig?.should_end_user_provide_public_key ?? undefined);
  const [shouldEndUserProvidePgpPublicKey, setShouldEndUserProvidePgpPublicKey] = useState<
    boolean | undefined
  >(selectedAuthConfig?.should_end_user_provide_pgp_public_key ?? undefined);
  const [isManualUploadOnlySftp, setIsManualUploadOnlySftp] = useState<boolean | undefined>(
    selectedAuthConfig?.is_manual_upload_only_sftp ?? false
  );
  const [pgpKey, setPgpKey] = useState<PgpKeys>(
    selectedAuthConfig?.sftp_ssm_pgp_key_name ?? PgpKeys.PRIMARY
  );

  /*
   * Scraper
   */

  const [authConfigScraperSessionLifespan, setAuthConfigScraperSessionLifespan] = useState<
    number | undefined
  >(selectedAuthConfig?.scraper_session_lifespan_in_seconds ?? undefined);
  const [authConfigServiceAccountFieldKeys, setAuthConfigServiceAccountFieldKeys] = useState<
    string[] | null
  >(selectedAuthConfig?.service_account_field_keys ?? null);

  /*
   * Submission states
   */

  // hooks
  const {
    createAuthenticationConfiguration,
    patchAuthenticationConfiguration,
    isLoading,
  } = useCreateOrPatchAuthenticationConfiguration({
    setAuthConfigs,
    integrationID,
    authConfigID: selectedAuthConfig?.id ?? undefined,
    actualAuthType,
    authConfigName,
    // scraper
    authConfigScraperSessionLifespan,
    authConfigServiceAccountFieldKeys,

    // sftp
    shouldEndUserProvidePublicKey,
    shouldEndUserProvidePgpPublicKey,
    isManualUploadOnlySftp,
    pgpKey,

    // basic auth
    basicAuthKeyHeaderFormat,
    basicAuthExchangeURL,
    shouldConvertBodyDataToString,
    refreshTokenHeaders,
    isSoapIntegration,
    refreshTokenBodyParams,
    refreshTokenStringBodyParams,
    basicAuthKeyTokenExchangeResponseAuthKeyPath,
    basicAuthKeyTokenExchangeAccessTokenLifespanInSeconds,
    basicAuthKeyTokenAccessTokenLifespanKeyPath,
    isTokenLifespanInResponse,
    basicAuthAdditionalAuthFieldKeys,

    // basic auth and oauth
    additionalHeaderFields,

    // oauth authorize
    grantType,
    oAuthAuthorizeURL,
    oAuthParamsScopes,
    doesOAuthUsePKCE,
    clientCredentialSourceChoice,
    redirectQueryParams,
    oAuthClientID,
    oAuthClientSecret,
    oAuthShouldFetchAccessToken,
    oAuthAuthorizeURLParamKeysToExclude,
    oAuthSetSoapBodyHeaderToNull,

    // oauth token configuration
    tokenRequestURL,
    tokenURLRequestHeaders,
    requestParamType,
    tokenURLRequestParams,
    doesResponseIncludeExpiresInParam,
    oAuthResponseTokenKeyPath,
    oAuthResponseCreatedAtKeyPath,
    dateTimeFormat,
    oAuthResponseTokenLifespanInSeconds,
    tokenExpiresInType,
    oAuthTokenURLHeaderKeysToExclude,

    // oauth refresh token
    shouldRefreshAfterInitialLink,
    shouldUseTokenURLForRefresh,
    oAuthRefreshURL,
    doesRefreshIncludeExpiresIn,
    oAuthRefreshTokenLifespanInSeconds,
    refreshURLFieldsToExclude,
  });
  const {
    deleteAuthenticationConfiguration,
    isLoadingAuthConfigDeletion,
  } = useDeleteAuthenticationConfiguration({
    integrationID: integrationID,
    setAuthConfigs: setAuthConfigs,
    authConfigID: selectedAuthConfig?.id,
  });

  const authConfigOnSubmit = () => {
    actualAuthType &&
      (isNewAuthConfig ? createAuthenticationConfiguration() : patchAuthenticationConfiguration());
  };

  // consts from derived state for submission
  const canSubmitWebConnector = actualAuthType === AuthType.AUTH_TYPE_WEB_CONNECTOR;
  const canSubmitScraper = actualAuthType === AuthType.AUTH_TYPE_SCRAPER;
  const canSubmitSFTP =
    actualAuthType === AuthType.AUTH_TYPE_SFTP && shouldEndUserProvidePublicKey !== undefined;
  const canSubmitOAuthNonClientCredential =
    (actualAuthType === AuthType.OAUTH2 || actualAuthType === AuthType.OAUTH1) &&
    (grantType === GrantTypes.authorization_code || grantType === GrantTypes.jwt_bearer) &&
    !!oAuthAuthorizeURL &&
    !!tokenRequestURL &&
    !!requestParamType;

  const canSubmitOAuthClientCredential =
    (actualAuthType === AuthType.OAUTH2 || actualAuthType === AuthType.OAUTH1) &&
    grantType === GrantTypes.client_credentials &&
    !!tokenRequestURL &&
    !!requestParamType;

  const canSubmitBasicAuthWithTokenExchange =
    actualAuthType === AuthType.BASIC_AUTH_WITH_TOKEN_EXCHANGE &&
    isNonOAuthBasedTokenExchange === true &&
    !!basicAuthExchangeURL &&
    ((isTokenLifespanInResponse === true && !!basicAuthKeyTokenAccessTokenLifespanKeyPath) ||
      (isTokenLifespanInResponse === false &&
        !!basicAuthKeyTokenExchangeAccessTokenLifespanInSeconds));

  const canSubmitBasicAuthNoTokenExchange = actualAuthType === AuthType.BASIC_AUTH;

  const authConfigCanSubmit =
    canSubmitWebConnector ||
    canSubmitScraper ||
    canSubmitSFTP ||
    canSubmitOAuthNonClientCredential ||
    canSubmitOAuthClientCredential ||
    canSubmitBasicAuthWithTokenExchange ||
    canSubmitBasicAuthNoTokenExchange;

  // context
  useIntegrationBuilderContext({
    submitButtonText: AUTHENTICATION_CONFIGURATION_SUBMIT_TEXT,
    canSubmit: authConfigCanSubmit,
    isLoadingSubmit: isLoading,
    isLoadingDelete: isLoadingAuthConfigDeletion,
    deleteButtonText: AUTHENTICATION_CONFIGURATION_DELETE_TEXT,
    shouldRenderSubmitButton: true,
    shouldRenderNavigationButtons: false,
    shouldRenderDeleteButton: !isNewAuthConfig,
    modelTypeForDiff: DiffModelTypeEnum.AUTHENTICATION_CONFIGURATION,
    isGatedByPermissioning: true,
    hasPermissionToEdit: hasPermissionToEdit,
    shouldHideDiffModal: isNewAuthConfig,
    markForDeletion: false,
    shouldRenderStageButton: false,
  });

  // set permission
  useEffect(() => {
    if (selectedAuthConfig) {
      setHasPermissionToEdit(selectedAuthConfig.does_user_have_edit_permission ?? true);
    } else {
      setHasPermissionToEdit(true);
    }
  }, [selectedAuthConfig]);

  // sets onSubmit with controlled state inputs
  useEffect(() => {
    setOnSubmit(authConfigOnSubmit);
  }, [
    setOnSubmit,
    isLoading,
    actualAuthType,
    authConfigName,
    // scraper
    authConfigScraperSessionLifespan,
    authConfigServiceAccountFieldKeys,
    // sftp
    shouldEndUserProvidePublicKey,
    shouldEndUserProvidePgpPublicKey,
    isManualUploadOnlySftp,
    pgpKey,
    // basic auth
    basicAuthKeyHeaderFormat,
    basicAuthExchangeURL,
    shouldConvertBodyDataToString,
    refreshTokenHeaders,
    isSoapIntegration,
    refreshTokenBodyParams,
    refreshTokenStringBodyParams,
    additionalHeaderFields,
    basicAuthAdditionalAuthFieldKeys,
    // basic auth with token exchange
    basicAuthKeyTokenExchangeResponseAuthKeyPath,
    basicAuthKeyTokenExchangeAccessTokenLifespanInSeconds,
    basicAuthKeyTokenAccessTokenLifespanKeyPath,
    isTokenLifespanInResponse,
    // oauth authorize
    grantType,
    oAuthAuthorizeURL,
    oAuthParamsScopes,
    doesOAuthUsePKCE,
    redirectQueryParams,
    clientCredentialSourceChoice,
    oAuthClientID,
    oAuthClientSecret,
    oAuthShouldFetchAccessToken,
    oAuthAuthorizeURLParamKeysToExclude,
    oAuthSetSoapBodyHeaderToNull,
    // oauth token configuration
    tokenRequestURL,
    tokenURLRequestParams,
    tokenURLRequestHeaders,
    requestParamType,
    doesResponseIncludeExpiresInParam,
    oAuthResponseTokenKeyPath,
    oAuthResponseCreatedAtKeyPath,
    dateTimeFormat,
    oAuthResponseTokenLifespanInSeconds,
    tokenExpiresInType,
    oAuthTokenURLHeaderKeysToExclude,
    //oauth refresh token
    shouldRefreshAfterInitialLink,
    shouldUseTokenURLForRefresh,
    oAuthRefreshURL,
    doesRefreshIncludeExpiresIn,
    oAuthRefreshTokenLifespanInSeconds,
    refreshURLFieldsToExclude,
  ]);

  useEffect(() => {
    setOnDelete(deleteAuthenticationConfiguration);
  }, [setOnDelete, selectedAuthConfig]);

  // sets auth type for oauth/non-oauth flows
  useEffect(() => {
    if (generalAuthConfigType === GeneralAuthType.API) {
      if (isOAuth === true) {
        setActualAuthType(selectedAuthConfig?.auth_type ?? AuthType.OAUTH2);
      } else if (isNonOAuthBasedTokenExchange === true) {
        setActualAuthType(AuthType.BASIC_AUTH_WITH_TOKEN_EXCHANGE);
      } else if (isNonOAuthBasedTokenExchange === false) {
        setActualAuthType(AuthType.BASIC_AUTH);
      }
    }
  }, [generalAuthConfigType, isOAuth, isNonOAuthBasedTokenExchange, setActualAuthType]);

  /* Set diff states, and add helper fields
   * This useEffect is to set up the dictionary requestBodyWithHelperFields which stores the initial model state to be used in the diff modal.
   * When adding/removing fields, you must update helpers-auth-config-diff.ts to ensure that these state changes get effectively captured by diff modal.
   */
  useEffect(() => {
    const requestBodyWithHelperFields = {
      // helper fields for diff
      general_auth_type: generalAuthConfigType,
      is_oauth: isOAuth,
      is_non_oauth_based_token_exchange: isNonOAuthBasedTokenExchange,
      token_expires_in_type: tokenExpiresInType,
      does_oauth_response_include_expires_in_param: doesResponseIncludeExpiresInParam,
      does_oauth_refresh_include_expires_in_param: doesRefreshIncludeExpiresIn,
      should_use_token_url_for_refresh: shouldUseTokenURLForRefresh,
      is_basic_auth_exchange_token_lifespan_in_response: isTokenLifespanInResponse,
      // auth setup fields
      name: authConfigName,
      auth_type: actualAuthType,
      scraper_session_lifespan_in_seconds: authConfigScraperSessionLifespan ?? null,
      service_account_field_keys: authConfigServiceAccountFieldKeys,
      // sftp
      should_end_user_provide_public_key: shouldEndUserProvidePublicKey,
      should_end_user_provide_pgp_public_key: shouldEndUserProvidePgpPublicKey,
      is_manual_upload_only_sftp: isManualUploadOnlySftp,
      sftp_ssm_pgp_key_name: pgpKey,
      // basic auth
      basic_auth_key_header_format: formatPostmanRowsToString(basicAuthKeyHeaderFormat) ?? null,
      basic_auth_key_token_exchange_url: basicAuthExchangeURL
        ? addHttpsToString(basicAuthExchangeURL)
        : undefined,
      basic_auth_key_token_exchange_request_data_convert_to_string: shouldConvertBodyDataToString,
      basic_auth_key_token_exchange_header_format:
        formatPostmanRowsToString(refreshTokenHeaders) ?? null,
      basic_auth_key_token_exchange_request_data_format:
        isSoapIntegration || shouldConvertBodyDataToString
          ? refreshTokenStringBodyParams
          : formatPostmanRowsToString(refreshTokenBodyParams) ?? null,
      basic_auth_key_token_exchange_response_auth_key_path: basicAuthKeyTokenExchangeResponseAuthKeyPath,
      basic_auth_key_token_exchange_access_token_lifespan_in_seconds:
        isTokenLifespanInResponse === false
          ? basicAuthKeyTokenExchangeAccessTokenLifespanInSeconds
          : null,
      basic_auth_key_token_access_token_lifespan_key_path:
        basicAuthKeyTokenAccessTokenLifespanKeyPath && isTokenLifespanInResponse === true
          ? basicAuthKeyTokenAccessTokenLifespanKeyPath
          : null,
      additional_auth_field_keys: basicAuthAdditionalAuthFieldKeys ?? [],
      // basic auth with token exchange and oauth
      additional_header_fields: formatPostmanRowsToString(additionalHeaderFields) ?? null,
      // oauth
      oauth_grant_type: grantType ?? undefined,
      // oauth authorize
      oauth_authorize_url: oAuthAuthorizeURL ? addHttpsToString(oAuthAuthorizeURL) : undefined,
      oauth_use_pkce: doesOAuthUsePKCE,
      oauth_authorize_url_additional_redirect_uri_query_param_keys: redirectQueryParams ?? null,
      oauth_params_scopes: oAuthParamsScopes ?? null,
      oauth_client_credential_source: clientCredentialSourceChoice ?? undefined,
      oauth_client_id: oAuthClientID ?? undefined,
      oauth_client_secret: oAuthClientSecret ?? undefined,
      oauth_should_fetch_access_token: oAuthShouldFetchAccessToken,
      oauth_authorize_url_param_keys_to_exclude: oAuthAuthorizeURLParamKeysToExclude ?? null,
      oauth_set_soap_body_header_to_null: oAuthSetSoapBodyHeaderToNull,
      // oauth token configuration
      oauth_token_url: tokenRequestURL ? addHttpsToString(tokenRequestURL) : undefined,
      oauth_token_url_header_format: formatPostmanRowsToString(tokenURLRequestHeaders) ?? null,
      oauth_token_url_params_location: requestParamType,
      oauth_request_fields_to_exclude: tokenURLRequestParams ?? null,
      oauth_response_token_key_path: oAuthResponseTokenKeyPath,
      oauth_response_created_at_key_path:
        tokenExpiresInType === TokenExpiresInType.CREATED ? oAuthResponseCreatedAtKeyPath : null,
      oauth_access_token_lifespan_in_seconds:
        doesResponseIncludeExpiresInParam === false ? oAuthResponseTokenLifespanInSeconds : null,
      oauth_response_created_datetime_format:
        tokenExpiresInType === TokenExpiresInType.CREATED ? dateTimeFormat : null,
      oauth_token_url_header_keys_to_exclude: oAuthTokenURLHeaderKeysToExclude ?? null,
      // oauth refresh url
      oauth_should_refresh_access_token: shouldRefreshAfterInitialLink,
      oauth_refresh_url: shouldUseTokenURLForRefresh
        ? addHttpsToString(oAuthRefreshURL)
        : undefined,
      oauth_refresh_token_lifespan_in_seconds: doesRefreshIncludeExpiresIn
        ? null
        : oAuthRefreshTokenLifespanInSeconds,
      oauth_refresh_fields_to_exclude: refreshURLFieldsToExclude ?? null,
    };
    setCurrentStateForDiff(requestBodyWithHelperFields);
    setNewStateForDiff(requestBodyWithHelperFields);
  }, [isNewAuthConfig, selectedAuthConfig, isSoapIntegration]);

  /* Update new state for diff
   * This useEffect is to set up the dictionary requestBodyWithHelperFields which stores the initial model state to be used in the diff modal.
   * When adding/removing fields, you must update multiple places to ensure that these state changes get effectively captured by diff modal.
   */
  useEffect(() => {
    setNewStateForDiff({
      // helper fields for diff
      general_auth_type: generalAuthConfigType,
      is_oauth: isOAuth,
      is_non_oauth_based_token_exchange: isNonOAuthBasedTokenExchange,
      token_expires_in_type: tokenExpiresInType,
      does_oauth_response_include_expires_in_param: doesResponseIncludeExpiresInParam,
      does_oauth_refresh_include_expires_in_param: doesRefreshIncludeExpiresIn,
      should_use_token_url_for_refresh: shouldUseTokenURLForRefresh,
      is_basic_auth_exchange_token_lifespan_in_response: isTokenLifespanInResponse,
      // auth setup fields
      name: authConfigName,
      auth_type: actualAuthType,
      scraper_session_lifespan_in_seconds: authConfigScraperSessionLifespan ?? null,
      service_account_field_keys: authConfigServiceAccountFieldKeys,
      // sftp
      should_end_user_provide_public_key: shouldEndUserProvidePublicKey,
      should_end_user_provide_pgp_public_key: shouldEndUserProvidePgpPublicKey,
      is_manual_upload_only_sftp: isManualUploadOnlySftp,
      sftp_ssm_pgp_key_name: pgpKey,
      // basic auth
      basic_auth_key_header_format: formatPostmanRowsToString(basicAuthKeyHeaderFormat) ?? null,
      basic_auth_key_token_exchange_url: basicAuthExchangeURL
        ? addHttpsToString(basicAuthExchangeURL)
        : undefined,
      basic_auth_key_token_exchange_request_data_convert_to_string: shouldConvertBodyDataToString,
      basic_auth_key_token_exchange_header_format:
        formatPostmanRowsToString(refreshTokenHeaders) ?? null,
      basic_auth_key_token_exchange_request_data_format:
        isSoapIntegration || shouldConvertBodyDataToString
          ? refreshTokenStringBodyParams
          : formatPostmanRowsToString(refreshTokenBodyParams) ?? null,
      basic_auth_key_token_exchange_response_auth_key_path: basicAuthKeyTokenExchangeResponseAuthKeyPath,
      basic_auth_key_token_exchange_access_token_lifespan_in_seconds:
        isTokenLifespanInResponse === false
          ? basicAuthKeyTokenExchangeAccessTokenLifespanInSeconds
          : null,
      basic_auth_key_token_access_token_lifespan_key_path:
        basicAuthKeyTokenAccessTokenLifespanKeyPath && isTokenLifespanInResponse === true
          ? basicAuthKeyTokenAccessTokenLifespanKeyPath
          : null,
      additional_auth_field_keys: basicAuthAdditionalAuthFieldKeys ?? [],
      // basic auth with token exchange and oauth
      additional_header_fields: formatPostmanRowsToString(additionalHeaderFields) ?? null,
      // oauth
      oauth_grant_type: grantType ?? undefined,
      // oauth authorize
      oauth_authorize_url: oAuthAuthorizeURL ? addHttpsToString(oAuthAuthorizeURL) : undefined,
      oauth_use_pkce: doesOAuthUsePKCE,
      oauth_authorize_url_additional_redirect_uri_query_param_keys: redirectQueryParams ?? null,
      oauth_params_scopes: oAuthParamsScopes ?? null,
      oauth_client_credential_source: clientCredentialSourceChoice ?? undefined,
      oauth_client_id: oAuthClientID ?? undefined,
      oauth_client_secret: oAuthClientSecret ?? undefined,
      oauth_should_fetch_access_token: oAuthShouldFetchAccessToken,
      oauth_authorize_url_param_keys_to_exclude: oAuthAuthorizeURLParamKeysToExclude ?? null,
      oauth_set_soap_body_header_to_null: oAuthSetSoapBodyHeaderToNull,
      // oauth token configuration
      oauth_token_url: tokenRequestURL ? addHttpsToString(tokenRequestURL) : undefined,
      oauth_token_url_header_format: formatPostmanRowsToString(tokenURLRequestHeaders) ?? null,
      oauth_token_url_params_location: requestParamType,
      oauth_request_fields_to_exclude: tokenURLRequestParams ?? null,
      oauth_response_token_key_path: oAuthResponseTokenKeyPath,
      oauth_response_created_at_key_path:
        tokenExpiresInType === TokenExpiresInType.CREATED ? oAuthResponseCreatedAtKeyPath : null,
      oauth_access_token_lifespan_in_seconds:
        doesResponseIncludeExpiresInParam === false ? oAuthResponseTokenLifespanInSeconds : null,
      oauth_response_created_datetime_format:
        tokenExpiresInType === TokenExpiresInType.CREATED ? dateTimeFormat : null,
      oauth_token_url_header_keys_to_exclude: oAuthTokenURLHeaderKeysToExclude ?? null,
      // oauth refresh url
      oauth_should_refresh_access_token: shouldRefreshAfterInitialLink,
      oauth_refresh_url: shouldUseTokenURLForRefresh
        ? addHttpsToString(oAuthRefreshURL)
        : undefined,
      oauth_refresh_token_lifespan_in_seconds: doesRefreshIncludeExpiresIn
        ? null
        : oAuthRefreshTokenLifespanInSeconds,
      oauth_refresh_fields_to_exclude: refreshURLFieldsToExclude ?? null,
    });
  }, [
    actualAuthType,
    authConfigName,
    // scraper
    authConfigScraperSessionLifespan,
    authConfigServiceAccountFieldKeys,

    // sftp
    shouldEndUserProvidePublicKey,
    shouldEndUserProvidePgpPublicKey,
    isManualUploadOnlySftp,
    pgpKey,

    // basic auth
    basicAuthKeyHeaderFormat,
    basicAuthExchangeURL,
    shouldConvertBodyDataToString,
    refreshTokenHeaders,
    isSoapIntegration,
    refreshTokenBodyParams,
    refreshTokenStringBodyParams,
    basicAuthKeyTokenExchangeResponseAuthKeyPath,
    basicAuthKeyTokenExchangeAccessTokenLifespanInSeconds,
    basicAuthKeyTokenAccessTokenLifespanKeyPath,
    isTokenLifespanInResponse,
    basicAuthAdditionalAuthFieldKeys,

    // basic auth and oauth
    additionalHeaderFields,

    // oauth authorize
    grantType,
    oAuthAuthorizeURL,
    oAuthParamsScopes,
    doesOAuthUsePKCE,
    clientCredentialSourceChoice,
    redirectQueryParams,
    oAuthClientID,
    oAuthClientSecret,
    oAuthShouldFetchAccessToken,
    oAuthAuthorizeURLParamKeysToExclude,
    oAuthSetSoapBodyHeaderToNull,

    // oauth token configuration
    tokenRequestURL,
    tokenURLRequestHeaders,
    requestParamType,
    tokenURLRequestParams,
    doesResponseIncludeExpiresInParam,
    oAuthResponseTokenKeyPath,
    oAuthResponseCreatedAtKeyPath,
    dateTimeFormat,
    oAuthResponseTokenLifespanInSeconds,
    tokenExpiresInType,
    oAuthTokenURLHeaderKeysToExclude,

    // oauth refresh token
    shouldRefreshAfterInitialLink,
    shouldUseTokenURLForRefresh,
    oAuthRefreshURL,
    doesRefreshIncludeExpiresIn,
    oAuthRefreshTokenLifespanInSeconds,
    refreshURLFieldsToExclude,
  ]);

  return (
    <EditorLeavingGuard computeHasUnsavedChanges={computeHasUnsavedChanges}>
      <>
        <Card className="pt-4 px-5 pb-5 bg-white mb-6 min-w-fit">
          <div className="text-slate-90 text-lg font-semibold mb-2">Authentication setup</div>
          <PermissionWrapper hasPermissionToEdit={hasPermissionToEdit}>
            <NameConfiguration
              authConfigName={authConfigName}
              setAuthConfigName={setAuthConfigName}
            />
            <ConfigurationTypeButtons
              generalAuthConfigType={generalAuthConfigType}
              setGeneralAuthConfigType={setGeneralAuthConfigType}
              setActualAuthType={setActualAuthType}
            />

            {generalAuthConfigType === GeneralAuthType.API && (
              <>
                <LineIndent className="mt-6">
                  <BinaryChoiceButtons
                    dataTestID="field-auth-config-is-oauth"
                    title="Is this an OAuth integration?"
                    binaryChoice={isOAuth}
                    setBinaryChoice={setIsOAuth}
                    required
                  />
                </LineIndent>
                {isOAuth !== undefined && (
                  <AuthenticationConfigurationSetupOptionsRenderedComponents
                    actualAuthType={actualAuthType}
                    setActualAuthType={setActualAuthType}
                    grantType={grantType}
                    setGrantType={setGrantType}
                    isOAuth={isOAuth}
                    oAuthParamsScopes={oAuthParamsScopes}
                    setOAuthParamsScopes={setOAuthParamsScopes}
                    isNonOAuthBasedTokenExchange={isNonOAuthBasedTokenExchange}
                    setIsNonOAuthBasedTokenExchange={setIsNonOAuthBasedTokenExchange}
                    basicAuthKeyHeaderFormat={basicAuthKeyHeaderFormat}
                    setBasicAuthKeyHeaderFormat={setBasicAuthKeyHeaderFormat}
                    additionalHeaderFields={additionalHeaderFields}
                    setAdditionalHeaderFields={setAdditionalHeaderFields}
                    basicAuthAdditionalAuthFieldKeys={basicAuthAdditionalAuthFieldKeys}
                    setBasicAuthAdditionalAuthFieldKeys={setBasicAuthAdditionalAuthFieldKeys}
                    isSoapIntegration={isSoapIntegration}
                    oAuthSetSoapBodyHeaderToNull={oAuthSetSoapBodyHeaderToNull}
                    setOAuthSetSoapBodyHeaderToNull={setOAuthSetSoapBodyHeaderToNull}
                  />
                )}
              </>
            )}
            {generalAuthConfigType === GeneralAuthType.SCRAPER && (
              <ScraperConfiguration // basicAuthKeyHeaderFormat is used for scrapers
                authConfigScraperSessionLifespan={authConfigScraperSessionLifespan}
                setAuthConfigScraperSessionLifespan={setAuthConfigScraperSessionLifespan}
                authConfigServiceAccountFieldKeys={authConfigServiceAccountFieldKeys}
                setAuthConfigServiceAccountFieldKeys={setAuthConfigServiceAccountFieldKeys}
                basicAuthKeyHeaderFormat={basicAuthKeyHeaderFormat}
                setBasicAuthKeyHeaderFormat={setBasicAuthKeyHeaderFormat}
              />
            )}
            {generalAuthConfigType === GeneralAuthType.SFTP && (
              <SFTPConfiguration
                pgpKey={pgpKey}
                setPgpKey={setPgpKey}
                shouldEndUserProvidePublicKey={shouldEndUserProvidePublicKey}
                setShouldEndUserProvidePublicKey={setShouldEndUserProvidePublicKey}
                shouldEndUserProvidePgpPublicKey={shouldEndUserProvidePgpPublicKey}
                setShouldEndUserProvidePgpPublicKey={setShouldEndUserProvidePgpPublicKey}
                isManualUploadOnlySftp={isManualUploadOnlySftp}
                setIsManualUploadOnlySftp={setIsManualUploadOnlySftp}
              />
            )}
          </PermissionWrapper>
        </Card>
        {isNonOAuthBasedTokenExchange === true &&
          isOAuth === false &&
          generalAuthConfigType === GeneralAuthType.API && (
            <BasicAuthWithTokenExchange
              basicAuthExchangeURL={basicAuthExchangeURL}
              setBasicAuthExchangeURL={setBasicAuthExchangeURL}
              refreshTokenHeaders={refreshTokenHeaders}
              setRefreshTokenHeaders={setRefreshTokenHeaders}
              refreshTokenBodyParams={refreshTokenBodyParams}
              setRefreshTokenBodyParams={setRefreshTokenBodyParams}
              isSoapIntegration={isSoapIntegration}
              refreshTokenStringBodyParams={refreshTokenStringBodyParams}
              setRefreshTokenStringBodyParams={setRefreshTokenStringBodyParams}
              shouldConvertBodyDataToString={shouldConvertBodyDataToString}
              setShouldConvertBodyDataToString={setShouldConvertBodyDataToString}
              basicAuthKeyTokenExchangeResponseAuthKeyPath={
                basicAuthKeyTokenExchangeResponseAuthKeyPath
              }
              setBasicAuthKeyTokenExchangeResponseAuthKeyPath={
                setBasicAuthKeyTokenExchangeResponseAuthKeyPath
              }
              isTokenLifespanInResponse={isTokenLifespanInResponse}
              setIsTokenLifespanInResponse={setIsTokenLifespanInResponse}
              basicAuthKeyTokenExchangeAccessTokenLifespanInSeconds={
                basicAuthKeyTokenExchangeAccessTokenLifespanInSeconds
              }
              setBasicAuthKeyTokenExchangeAccessTokenLifespanInSeconds={
                setBasicAuthKeyTokenExchangeAccessTokenLifespanInSeconds
              }
              basicAuthKeyTokenAccessTokenLifespanKeyPath={
                basicAuthKeyTokenAccessTokenLifespanKeyPath
              }
              setBasicAuthKeyTokenAccessTokenLifespanKeyPath={
                setBasicAuthKeyTokenAccessTokenLifespanKeyPath
              }
              hasPermissionToEdit={hasPermissionToEdit}
            />
          )}
        {grantType !== undefined &&
          isOAuth === true &&
          generalAuthConfigType === GeneralAuthType.API && (
            <OAuthGrantPage
              grantType={grantType}
              oAuthAuthorizeURL={oAuthAuthorizeURL}
              setOAuthAuthorizeURL={setOAuthAuthorizeURL}
              redirectQueryParams={redirectQueryParams}
              setRedirectQueryParams={setRedirectQueryParams}
              doesOAuthUsePKCE={doesOAuthUsePKCE}
              setDoesOAuthUsePKCE={setDoesOAuthUsePKCE}
              clientCredentialSourceChoice={clientCredentialSourceChoice}
              setClientCredentialSourceChoice={setClientCredentialSourceChoice}
              oAuthClientID={oAuthClientID}
              setOAuthClientID={setOAuthClientID}
              oAuthClientSecret={oAuthClientSecret}
              setOAuthClientSecret={setOAuthClientSecret}
              tokenURLRequestHeaders={tokenURLRequestHeaders}
              setTokenURLRequestHeaders={setTokenURLRequestHeaders}
              tokenURLRequestParams={tokenURLRequestParams}
              setTokenURLRequestParams={setTokenURLRequestParams}
              requestParamType={requestParamType}
              setRequestParamType={setRequestParamType}
              tokenRequestURL={tokenRequestURL}
              setTokenRequestURL={setTokenRequestURL}
              doesResponseIncludeExpiresInParam={doesResponseIncludeExpiresInParam}
              setDoesResponseIncludeExpiresInParam={setDoesResponseIncludeExpiresInParam}
              tokenExpiresInType={tokenExpiresInType}
              setTokenExpiresInType={setTokenExpiresInType}
              oAuthResponseTokenKeyPath={oAuthResponseTokenKeyPath}
              setOAuthResponseTokenKeyPath={setOAuthResponseTokenKeyPath}
              oAuthResponseCreatedAtKeyPath={oAuthResponseCreatedAtKeyPath}
              setOAuthResponseCreatedAtKeyPath={setOAuthResponseCreatedAtKeyPath}
              dateTimeFormat={dateTimeFormat}
              setDateTimeFormat={setDateTimeFormat}
              oAuthResponseTokenLifespanInSeconds={oAuthResponseTokenLifespanInSeconds}
              setOAuthResponseTokenLifespanInSeconds={setOAuthResponseTokenLifespanInSeconds}
              shouldRefreshAfterInitialLink={shouldRefreshAfterInitialLink}
              setShouldRefreshAfterInitialLink={setShouldRefreshAfterInitialLink}
              shouldUseTokenURLForRefresh={shouldUseTokenURLForRefresh}
              setShouldUseTokenURLForRefresh={setShouldUseTokenURLForRefresh}
              doesRefreshIncludeExpiresIn={doesRefreshIncludeExpiresIn}
              setDoesRefreshIncludeExpiresIn={setDoesRefreshIncludeExpiresIn}
              oAuthRefreshURL={oAuthRefreshURL}
              setOAuthRefreshURL={setOAuthRefreshURL}
              oAuthRefreshTokenLifespanInSeconds={oAuthRefreshTokenLifespanInSeconds}
              setOAuthRefreshTokenLifespanInSeconds={setOAuthRefreshTokenLifespanInSeconds}
              oAuthShouldFetchAccessToken={oAuthShouldFetchAccessToken}
              setOAuthShouldFetchAccessToken={setOAuthShouldFetchAccessToken}
              oAuthAuthorizeURLParamKeysToExclude={oAuthAuthorizeURLParamKeysToExclude}
              setOAuthAuthorizeURLParamKeysToExclude={setOAuthAuthorizeURLParamKeysToExclude}
              oAuthTokenURLHeaderKeysToExclude={oAuthTokenURLHeaderKeysToExclude}
              setOAuthTokenURLHeaderKeysToExclude={setOAuthTokenURLHeaderKeysToExclude}
              refreshURLFieldsToExclude={refreshURLFieldsToExclude}
              setRefreshURLFieldsToExclude={setRefreshURLFieldsToExclude}
              hasPermissionToEdit={hasPermissionToEdit}
              integrationId={integrationID}
            />
          )}
      </>
    </EditorLeavingGuard>
  );
};

export default AuthenticationConfigurationSetupOptions;
