import { PgpKeys } from "../../../../integrations/auth-config/AuthConfigShared";
import MultiSelectHeader from "../../../shared/MultiSelectHeader";

interface SFTPPGPKeyButtonsProps {
  className?: string;
  pgpKey: PgpKeys;
  setPgpKey: React.Dispatch<React.SetStateAction<PgpKeys>>;
}

const SFTPPGPKeyButtons = ({ className, pgpKey, setPgpKey }: SFTPPGPKeyButtonsProps) => {
  const handleButtonClick = (value: PgpKeys) => {
    setPgpKey(value);
  };

  const options = [
    {
      value: PgpKeys.PRIMARY,
      text: "Primary",
      disabled: false,
      selected: pgpKey === PgpKeys.PRIMARY,
      onClick: handleButtonClick,
    },
    {
      value: PgpKeys.RSA3072,
      text: "RSA3072",
      disabled: false,
      selected: pgpKey === PgpKeys.RSA3072,
      onClick: handleButtonClick,
    },
  ];

  return (
    <MultiSelectHeader
      title="PGP Key"
      subtitle="Select the PGP key to use based on the integration's encryption requirements "
      learnMoreText="The only integration that requires a non primary PGP key is ADP SFTP. All other integrations should use the primary PGP key."
      options={options}
      className={className}
      required
    />
  );
};

export default SFTPPGPKeyButtons;
