import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { useMemo } from "react";
import IntegrationOverview from "../components/integrations/IntegrationOverview";
import IntegrationsDashboard from "../components/integrations/dashboard/IntegrationsDashboard";
import APIEndpointEditForm from "../components/integrations/api-endpoints/APIEndpointEditForm";
import AuthConfigEditForm from "../components/integrations/auth-config/AuthConfigEditForm";
import ScraperEditorRoot from "../components/scraper/ScraperEditorRoot";
import { fetchCurrentUser, hasAuthToken } from "../api-client/api_client";
import {
  ADMIN_ROOT_PATH,
  API_ENDPOINT_CREATE_PATH,
  API_ENDPOINT_EDIT_PATH,
  AUTH_CONFIG_EDIT_PATH,
  BLUEPRINT_EDITOR_PATH,
  INTEGRATION_DASHBOARD_PATH,
  INTEGRATION_OVERVIEW_PATH,
  LOGIN_PATH,
  SCRAPER_EDITOR_PATH,
  MAPPING_TEST_EDITOR_PATH,
  SCRAPER_HISTORY_PATH,
  INTEGRATIONS_EXPORT_PATH,
  SCRAPER_VERSION_EDITOR_PATH,
  AUTH_CONFIG_CREATE_PATH,
  REPORT_TEMPLATE_EDIT_PATH,
  REPORT_TEMPLATE_CREATE_PATH,
  INTEGRATION_BUILDER_PATH,
  INTEGRATION_BUILDER_PATH_INITIALIZATION,
  ALERTS_DASHBOARD_PATH,
} from "./RouterUtils";
import MappingTestEditorRoot from "../components/mapping-tests/root/MappingTestEditorRoot";
import PortalPageContainer from "../components/portal/PortalPageContainer";
import ScraperHistoryRoot from "../components/integrations/scrapers/ScraperHistoryRoot";
import IntegrationsExportPage from "../components/integrations/IntegrationsExportPage";
import BlueprintEditor from "../components/blueprint-editor/BlueprintEditor";
import ReportTemplateEditForm from "../components/integrations/report-templates/ReportTemplateEditForm";
import IntegrationBuilder from "../components/integration-builder/IntegrationBuilder";
import { useEffect, useState } from "react";
import { User } from "src/models/Entities";
import AlertDashboard from "../components/alerts/dashboard/AlertDashboard";
import { MergeFlagFeature, useMergeFlag } from "../../src/components/shared/hooks/useMergeFlag";
import TopNavigationBar from "../components/portal/TopNavigationBar";

const AdminPortalRouter = () => {
  const [user, setUser] = useState<User>();
  const history = useHistory();
  const { enabled } = useMergeFlag({
    feature: MergeFlagFeature.MERGE_FLAG_ALERT_DASHBOARD,
    isEnabledForUser: true,
  });

  const shouldDisplayTopNavBar = useMemo(() => {
    return !history.location.pathname.includes("editor");
  }, [history.location.pathname]);

  useEffect(() => {
    if (!user && hasAuthToken()) {
      fetchCurrentUser(setUser);
    }
  }, [hasAuthToken, user, setUser]);

  const fsLoaded = "FS" in window;

  useEffect(() => {
    if (user && fsLoaded && process.env.REACT_APP_MERGE_ENV === "PRODUCTION") {
      const userVars: any = {
        displayName: user.name,
        email: user.email,
      };

      // @ts-ignore
      window.FS.identify(user.uuid.toString(), userVars);
    }
  }, [user, fsLoaded]);

  if (!hasAuthToken()) {
    return (
      <Redirect to={LOGIN_PATH + "?redirect=" + encodeURIComponent(window.location.pathname)} />
    );
  }

  return (
    <>
      {shouldDisplayTopNavBar ? <TopNavigationBar isAlertTabEnabled={enabled} /> : null}
      <PortalPageContainer>
        <Switch>
          <Route exact path={INTEGRATION_DASHBOARD_PATH} component={IntegrationsDashboard} />
          {enabled ? <Route exact path={ALERTS_DASHBOARD_PATH} component={AlertDashboard} /> : null}
          <Route
            path={[API_ENDPOINT_EDIT_PATH, API_ENDPOINT_CREATE_PATH]}
            component={APIEndpointEditForm}
          />
          <Route
            path={[AUTH_CONFIG_EDIT_PATH, AUTH_CONFIG_CREATE_PATH]}
            component={AuthConfigEditForm}
          />
          <Route
            path={[REPORT_TEMPLATE_EDIT_PATH, REPORT_TEMPLATE_CREATE_PATH]}
            component={ReportTemplateEditForm}
          />
          <Route path={BLUEPRINT_EDITOR_PATH} component={BlueprintEditor} />
          <Route
            path={`${INTEGRATION_BUILDER_PATH}/:integrationID?`}
            component={IntegrationBuilder}
          />
          <Route path={INTEGRATION_BUILDER_PATH_INITIALIZATION} component={IntegrationBuilder} />
          <Route
            path={SCRAPER_EDITOR_PATH}
            children={({ match }: { match: any }) => <ScraperEditorRoot match={match} />}
          />
          <Route
            path={SCRAPER_VERSION_EDITOR_PATH}
            children={({ match }: { match: any }) => <ScraperEditorRoot match={match} />}
          />
          <Route
            path={MAPPING_TEST_EDITOR_PATH}
            children={({ match }: { match: any }) => <MappingTestEditorRoot match={match} />}
          />
          <Route path={SCRAPER_HISTORY_PATH} component={ScraperHistoryRoot} />
          <Route path={INTEGRATIONS_EXPORT_PATH} component={IntegrationsExportPage} />
          <Route
            path={INTEGRATION_OVERVIEW_PATH}
            children={({ match }: { match: any }) => <IntegrationOverview match={match} />}
          />
          <Redirect from={ADMIN_ROOT_PATH} to={INTEGRATION_DASHBOARD_PATH} />
        </Switch>
        {/* End Integrations/Common Models Pages */}
      </PortalPageContainer>
    </>
  );
};

export default AdminPortalRouter;
