import { Text } from "@merge-api/merge-javascript-shared";
import MergeTable from "../../../../../components/shared/MergeTable";

interface AlertDashboardDrawerExecutionDetailsProps {
  totalSuccessful: number;
  totalFailed: number;
}

const AlertDashboardDrawerExecutionDetails = ({
  totalSuccessful,
  totalFailed,
}: AlertDashboardDrawerExecutionDetailsProps) => {
  const totalExecutions = totalSuccessful + totalFailed;

  return (
    <div className="mb-6">
      <Text variant="h4" className="mb-5">
        Blueprint Executions
      </Text>
      <MergeTable
        hover
        hasMarginBottom={false}
        header={
          <>
            <th>
              <Text variant="h6">Total number of blueprint executions</Text>
            </th>
            <th>
              <Text variant="h6">{totalExecutions}</Text>
            </th>
          </>
        }
        content={
          <>
            <tr>
              <td>
                <Text variant="md">Successful blueprint executions</Text>
              </td>
              <td>
                <Text variant="md">{totalSuccessful}</Text>
              </td>
            </tr>
            <tr>
              <td>
                <Text variant="md">Failed blueprint executions</Text>
              </td>
              <td>
                <Text variant="md">{totalFailed}</Text>
              </td>
            </tr>
          </>
        }
      />
    </div>
  );
};

export default AlertDashboardDrawerExecutionDetails;
