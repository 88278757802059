import { Text } from "@merge-api/merge-javascript-shared";

import { usePublishModuleContext } from "./context/PublishModuleContext";
import PublishModuleContextProvider from "./context/PublishModuleContextProvider";
import PublishModuleChecksCardV2 from "./IntegrationPublishChecks/PublishModuleChecksCardV2";
import PublishModuleStagedVersionsCardV2 from "./IntegrationStagedVersions/PublishModuleStagedVersionsCardV2";
import PublishModulePublishCard from "./PublishModulePublishCard";

const IntegrationPublishModuleSubtabWithValidatorV2 = () => {
  const { hasChanges } = usePublishModuleContext();

  return (
    <div className="space-y-8 mb-12">
      <div>
        <Text variant="h3" className="mb-6">{`Staged components`}</Text>
        <PublishModuleStagedVersionsCardV2 />
      </div>
      {hasChanges && (
        <div className="space-y-8">
          <div className="space-y-4">
            <div className="flex flex-row justify-between items-center">
              <Text variant="h3">Checks</Text>
            </div>
            <PublishModuleChecksCardV2 />
          </div>
          <div className="flex flex-row justify-between items-center">
            <Text variant="h3">Publish</Text>
          </div>
          <PublishModulePublishCard />
        </div>
      )}
    </div>
  );
};

const IntegrationPublishModuleWithContext = ({ integrationID }: { integrationID: string }) => {
  return (
    <PublishModuleContextProvider integrationID={integrationID}>
      <IntegrationPublishModuleSubtabWithValidatorV2 />
    </PublishModuleContextProvider>
  );
};

export default IntegrationPublishModuleWithContext;
