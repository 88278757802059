import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchWithAuth, PaginatedAPIResponse, useQuery } from "../../../api-client/api_client";
import { ScraperExecutionPreview } from "../../scraper/types";
import FullPageSpinner from "../../shared/layout/FullPageSpinner";
import { showErrorToast } from "../../shared/Toasts";
import ScraperHistoryView from "./ScraperHistoryView";
import { Container } from "react-bootstrap";

const ScraperHistoryRoot = () => {
  const { scraperID, integrationID } = useParams<{
    integrationID: string;
    scraperID: string;
  }>();
  const query = useQuery();
  const [executions, setExecutions] = useState<ScraperExecutionPreview[] | undefined>(undefined);
  const [nextPageURL, setNextPageURL] = useState<string | null>(null);
  const [previousPageURL, setPreviousPageURL] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [scraperName, setScraperName] = useState<string>("");
  const [integrationName, setIntegrationName] = useState<string>("");
  const linkedAccountID: string | null = query.get("linkedAccountID");
  useEffect(() => {
    fetchExecutions();
  }, [linkedAccountID]);

  const fetchExecutions = (cursorURL?: string) => {
    const linkedAccountParam = linkedAccountID ? `&linked_account_id=${linkedAccountID}` : "";
    setIsLoading(true);
    fetchWithAuth({
      path: cursorURL || `/scrapers/executions?scraper_id=${scraperID}${linkedAccountParam}`,
      method: "GET",
      onResponse: (data: PaginatedAPIResponse<ScraperExecutionPreview>) => {
        setExecutions(data.results);
        setNextPageURL(data.next);
        setPreviousPageURL(data.previous);
        setIsLoading(false);
        if (data.results.length > 0) {
          setScraperName(data.results[0].name || "");
          setIntegrationName(data.results[0].integration_name || "");
        }
      },
      onError: () => {
        showErrorToast("Failed to fetch scraper executions.");
        setIsLoading(false);
      },
    });
  };

  if (isLoading) {
    return <FullPageSpinner />;
  }

  return (
    <Container fluid className="pb-8 px-8 pt-16">
      <ScraperHistoryView
        integrationID={integrationID}
        scraperID={scraperID}
        executions={executions ?? undefined}
        nextPageURL={nextPageURL}
        previousPageURL={previousPageURL}
        fetchExecutions={fetchExecutions}
        integrationName={integrationName}
        scraperName={scraperName}
      />
    </Container>
  );
};

export default ScraperHistoryRoot;
