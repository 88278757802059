import { useCallback, useMemo, useEffect, useState } from "react";
import MappingTestChangesModal from "./MappingTestChangesModal";

import {
  LinkedAccountInMappingTest,
  MappingTestBodyParameters,
  MappingTestBodyParameterSchema,
  MappingTestExistingCommonModels,
  MappingTestExpectedResult,
  MappingTestReadTestInformation,
  MappingTestVersion,
  MappingTestCommonModel,
  MappingTestCommonModelExpectedMappings,
  MappingTestExecution,
  MappingTestRequestMock,
  MappingTestExecutionInput,
  MappingTestBlockExecution,
  MappingTestExecutionResult,
  // MappingTestExecutionResult,
} from "../../../models/MappingTests";
import MappingTestEditorTopControlPanel from "../top-control-panel/MappingTestEditorTopControlPanel";
import { fetchMappingTestResults, testMappingTest } from "../utils/MappingTestFetchUtils";
import useMappingTestReducer from "./useMappingTestReducer";
import { BlueprintMeta, Integration } from "../../../models/Entities";
import { Route, useHistory } from "react-router-dom";
import useFetchAsyncResult from "../../shared/hooks/useFetchAsyncResult";
import {
  navigateToMappingTestEditor,
  MAPPING_TEST_EDITOR_MAIN_TAB_PATH,
  MAPPING_TEST_EDITOR_README_TAB_PATH,
} from "../../../router/RouterUtils";
import MappingTestEditorReadmePanel from "../central-panel/MappingTestEditorReadmePanel";
import { NextComponentVersionState } from "../../integrations/versioned-components/types";
import { BlueprintOperationType } from "../../../models/Blueprints";
import EditorLeavingGuard from "../../shared/unsaved-changes/EditorLeavingGuard";
import MappingTestV2EditorLeftPanel from "../left-panel/MappingTestV2EditorLeftPanel";
import MappingTestV2CentralPanel from "../central-panel/MappingTestV2CentralPanel";
import MappingTestV2RightPanel from "../right-panel/MappingTestV2RightPanel";
import MappingTestPanelContainer from "./MappingTestPanelContainer";
import { panelPositionEnum } from "../utils/MappingTestBuildingUtils";

const RESET_MOCK_TITLE = "Reset mock response";
const RESET_MOCK_BODY =
  "Are you sure you want to erase your edited response & get data from the original API source?";
const RESET_MOCK_CONFIRM = "Reset";
const RESET_MOCK_CANCEL = "Cancel";

export type MappingTestAssertionsProps = {
  commonModels: { [commonModelID: string]: MappingTestCommonModel };
  operationType: BlueprintOperationType;
  doesReadTestHaveDraftChanges: boolean;
  hasUnsavedChanges: boolean;
  testExecutionResults: MappingTestExecution | undefined;
  setCommonModelCountAssertionForBlock: (
    mappingTestBlockID: string,
    commonModelID: string,
    commonModelCountAssertion: number
  ) => void;
  updateCommonModelExpectedMappingsForBlock: (
    mappingTestBlockID: string,
    commonModelID: string,
    commonModelExpectedMappings: undefined | MappingTestCommonModelExpectedMappings
  ) => void;
  updateRelationName: (
    commonModelID: string,
    mappingTestBlockID: string,
    oldName: string,
    newName: string
  ) => void;
};

type Props = {
  readTestInformation: undefined | MappingTestReadTestInformation;
  bodyParameterSchema: MappingTestBodyParameterSchema | null;
  mappingTestState: NextComponentVersionState;
  integration: Integration;
  blueprints: BlueprintMeta[];
  draftBlueprints: BlueprintMeta[] | undefined;
  common_models: { [commonModelID: string]: MappingTestCommonModel };
  mappingTestVersionUnderConstruction: MappingTestVersion;
  mappingTestID: string;
  mappingTestOperationType: BlueprintOperationType;
  mappingTestName: string;
  linkedAccount: LinkedAccountInMappingTest;
  expectedResult: MappingTestExpectedResult | undefined;
  writtenOrUpdatedCommonModel: undefined | string | null;
  webhookReceiverEventTypeID: string | null;
  isMappingTestNextVersionNull: boolean;
  isReadTestCollection: boolean;
  nestedWritesMapForMappingTestModelArray: string[];
  commonModelName?: string;
};

const MappingTestV2EditorView = ({
  readTestInformation,
  bodyParameterSchema,
  mappingTestState,
  integration,
  blueprints,
  draftBlueprints,
  common_models,
  expectedResult,
  mappingTestVersionUnderConstruction,
  mappingTestID,
  mappingTestOperationType,
  mappingTestName,
  linkedAccount,
  writtenOrUpdatedCommonModel,
  webhookReceiverEventTypeID,
  isMappingTestNextVersionNull,
  isReadTestCollection,
  nestedWritesMapForMappingTestModelArray,
  commonModelName,
}: Props) => {
  const history = useHistory();
  const [apiRequestIDToReset, setApiRequestIDToReset] = useState<string | null>(null);

  const [
    mappingTestReducerState,
    doesMappingTestHaveUnsavedChanges,
    isSaving,
    reducerActions,
  ] = useMappingTestReducer(
    { mappingTestVersionUnderConstruction, mappingTestState },
    mappingTestID
  );

  const {
    resetMappingTestRequestBody: resetMappingTestRequestBody,
    updateMappingTestBPMetadata: updateMappingTestBPMetadata,
  } = reducerActions;

  const [isShowingResetResponseModal, setIsShowingResetResponseModal] = useState<boolean>(false);

  // Right panel data
  const [isShowingRightPanel, setIsShowingRightPanel] = useState<boolean>(false);
  const [selectedBlockExecution, setSelectedBlockExecution] = useState<
    MappingTestBlockExecution | undefined
  >();
  const [selectedBlockName, setSelectedBlockName] = useState<string | undefined>();

  const {
    max_loop_iterations: maxLoopIterations,
    max_page_iterations: maxPageIterations,
    frozen_time: mappingTestFreezeTime,
    should_check_auth_headers: shouldMatchOnRequestHeaders,
    requests,
    body_parameters: bodyParameters,
    existing_common_models: existingCommonModels,
    existing_common_model_name: existingCommonModelName,
    blueprint_metadata: blueprintsMetadata,
    mapping_test_blocks: mappingTestBlocks,
    construction_errors,
  } = mappingTestReducerState.mappingTestVersionUnderConstruction;
  const [apiRequests, setApiRequests] = useState<MappingTestRequestMock[]>(requests);

  const setExistingCommonModels = (existingCommonModels: MappingTestExistingCommonModels) =>
    reducerActions.updateMappingTestField("existing_common_models", existingCommonModels);
  const setMaxLoopIterations = (maxLoopIterations: number | undefined) =>
    reducerActions.updateMappingTestField("max_loop_iterations", maxLoopIterations);
  const setMaxPageIterations = (maxPageIterations: number | undefined) =>
    reducerActions.updateMappingTestField("max_page_iterations", maxPageIterations);
  const setShouldMatchOnRequestHeaders = (shouldMatchOnRequestHeaders: boolean) =>
    reducerActions.updateMappingTestField("should_check_auth_headers", shouldMatchOnRequestHeaders);
  const setBodyParameters = (bodyParameters: MappingTestBodyParameters) =>
    reducerActions.updateMappingTestField("body_parameters", bodyParameters);
  const setExistingCommonModelName = (existingCommonModelName: string) =>
    reducerActions.updateMappingTestField("existing_common_model_name", existingCommonModelName);

  const resetApiRequest = useCallback(
    (apiRequestID: string) => {
      setIsShowingResetResponseModal(true);
      setApiRequestIDToReset(apiRequestID);
    },
    [setIsShowingResetResponseModal, setApiRequestIDToReset]
  );

  const [
    isRunningMappingTest,
    testExecutionResults,
    _clearTestExecutionResults,
    runMappingTest,
  ] = useFetchAsyncResult<MappingTestExecution, MappingTestExecutionInput>(
    (props) =>
      testMappingTest({
        mappingTestVersion: mappingTestReducerState.mappingTestVersionUnderConstruction,
        mappingTestID,
        ...props,
      }),
    fetchMappingTestResults,
    "mapping test"
  );

  useEffect(
    () => setApiRequests(requests),
    // We don't want this to update every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [requests]
  );

  const scraperBlueprintsHaveInputdata = () => {
    const bpIDs = blueprints.map((bp) => bp.blueprint_id);
    return bpIDs.every((bpID) => ![{}, null, undefined].includes(blueprintsMetadata?.[bpID]));
  };

  const isMappingTestInitialized = requests.length > 0 || scraperBlueprintsHaveInputdata();

  const leftPanelAvailableRelationLookupDict = useMemo(() => {
    // pulled from available_relation_lookup_dict on backend
    const readTestSchema = readTestInformation?.available_relation_lookup_dict ?? {};

    const existingCommonModelSchema = Object.fromEntries(
      Object.entries(existingCommonModels ?? {}).map(([modelID, value]) => [
        modelID.split(".")[1],
        Object.keys(value),
      ])
    );

    let concatenatedSchema: { [key: string]: Array<string> } = {};
    let keys = new Set<string>();

    // current backend logic will ignore read test common models if existing common models are present, we want to only show the user the option to select from the models that will make up
    // the model logs on the backend
    if (existingCommonModels && Object.keys(existingCommonModels).length > 0) {
      keys = new Set(Object.keys(existingCommonModelSchema));
      for (let key of keys) {
        let valuesC = existingCommonModelSchema?.[key] || [];
        concatenatedSchema[key] = [...new Set([...valuesC])];
      }
    } else {
      keys = new Set([...Object.keys(readTestSchema)]);
      for (let key of keys) {
        let valuesA = readTestSchema?.[key] || [];
        concatenatedSchema[key] = [...new Set([...valuesA])];
      }
    }

    return concatenatedSchema;
  }, [readTestInformation?.available_relation_lookup_dict, existingCommonModels]);

  const areRequiredParametersFilledOut = useMemo(() => {
    if (!bodyParameters || !bodyParameterSchema) {
      return true;
    }

    return (bodyParameterSchema?.properties?.model?.required ?? []).every(
      (requiredPropertyKey) => !!bodyParameters?.model?.[requiredPropertyKey]
    );
  }, [bodyParameters, bodyParameterSchema]);

  const isWriteTestWithMissingReadTest = readTestInformation
    ? !readTestInformation.is_there_a_non_draft_read_test_version
    : false;
  const isMissingExistingCommonModels = !existingCommonModels;
  const isWriteTestWithMissingInitializedModels =
    isWriteTestWithMissingReadTest && isMissingExistingCommonModels;

  const doesReadTestHaveDraftChanges = readTestInformation
    ? readTestInformation.does_read_test_have_draft_changes
    : false;

  const isInitializingDisabled =
    !areRequiredParametersFilledOut || isWriteTestWithMissingInitializedModels;
  const isTestRunningDisabled =
    !areRequiredParametersFilledOut || isWriteTestWithMissingInitializedModels;

  const navigateToFetchTest = readTestInformation
    ? () => navigateToMappingTestEditor(history, integration.id, readTestInformation.id, true)
    : undefined;

  // Hook that auto-opens panel after a successful run, and pinpoints the first failed block to show the user where they went wrong. Otherwise opens as successful
  useEffect(() => {
    if (!isRunningMappingTest && testExecutionResults?.result != null) {
      if (
        testExecutionResults &&
        testExecutionResults.block_executions &&
        testExecutionResults.block_executions.length > 0
      ) {
        let firstFailedBlockExecutionIndex = testExecutionResults.block_executions.findIndex(
          (blockExecution) =>
            blockExecution.result !== MappingTestExecutionResult.SUCCESS ||
            blockExecution.result !== null ||
            blockExecution.result !== undefined
        );
        if (firstFailedBlockExecutionIndex !== -1) {
          setSelectedBlockName(`Mapping test block - ${firstFailedBlockExecutionIndex + 1}`);
          setSelectedBlockExecution(
            testExecutionResults.block_executions[firstFailedBlockExecutionIndex]
          );
          setIsShowingRightPanel(true);
        }
      }
    }
  }, [isRunningMappingTest, testExecutionResults]);

  // const derived from state to tell if the execution has finished
  const isExecutionFinished =
    testExecutionResults?.result != null && testExecutionResults?.block_executions != null;

  return (
    <EditorLeavingGuard hasUnsavedChanges={doesMappingTestHaveUnsavedChanges}>
      <div className="flex flex-column h-screen">
        <MappingTestChangesModal
          show={isShowingResetResponseModal}
          title={RESET_MOCK_TITLE}
          bodyText={RESET_MOCK_BODY}
          confirmText={RESET_MOCK_CONFIRM}
          rejectText={RESET_MOCK_CANCEL}
          onHide={() => {
            setApiRequestIDToReset(null);
            setIsShowingResetResponseModal(false);
          }}
          onConfirm={() => {
            resetMappingTestRequestBody(apiRequestIDToReset!);
            setApiRequestIDToReset(null);
            setIsShowingResetResponseModal(false);
          }}
        />
        <MappingTestEditorTopControlPanel
          mappingTestState={mappingTestReducerState.mappingTestState}
          mappingTestName={mappingTestName}
          integration={integration}
          isSaving={isSaving}
          hasUnsavedChanges={doesMappingTestHaveUnsavedChanges}
          reducerActions={reducerActions}
        />
        <div className="flex flex-row pt-10 h-[calc(100vh)]">
          <MappingTestPanelContainer
            panelPosition={panelPositionEnum.LEFT}
            numberOfPanels={isShowingRightPanel ? 3 : 2}
          >
            <MappingTestV2EditorLeftPanel
              nestedWritesMapForMappingTestModelArray={nestedWritesMapForMappingTestModelArray}
              isReadTestCollection={isReadTestCollection}
              isMappingTestNextVersionNull={isMappingTestNextVersionNull}
              leftPanelAvailableRelationLookupDict={leftPanelAvailableRelationLookupDict}
              bodyParameters={bodyParameters}
              setBodyParameters={setBodyParameters}
              existingCommonModels={existingCommonModels}
              setExistingCommonModels={setExistingCommonModels}
              existingCommonModelName={existingCommonModelName}
              setExistingCommonModelName={setExistingCommonModelName}
              bodyParameterSchema={bodyParameterSchema}
              integration={integration}
              maxLoopIterations={maxLoopIterations}
              setMaxLoopIterations={setMaxLoopIterations}
              maxPageIterations={maxPageIterations}
              navigateToFetchTest={navigateToFetchTest}
              setMaxPageIterations={setMaxPageIterations}
              mappingTestID={mappingTestID}
              mappingTestState={mappingTestReducerState.mappingTestState}
              linkedAccountEndUserName={linkedAccount.end_user_organization_name}
              linkedAccountID={linkedAccount.id}
              expectedResult={expectedResult}
              isMappingTestInitialized={isMappingTestInitialized}
              operationType={mappingTestOperationType}
              writtenOrUpdatedCommonModel={writtenOrUpdatedCommonModel}
              webhookReceiverEventTypeID={webhookReceiverEventTypeID}
              blueprints={blueprints}
              constructionErrors={construction_errors}
              updateMappingTestBPMetadata={updateMappingTestBPMetadata}
              blueprintsMetadata={blueprintsMetadata}
              requests={apiRequests}
              addRequestMock={reducerActions.addRequestMock}
              editRequestMock={reducerActions.editRequestMock}
              pasteRequestMock={reducerActions.pasteRequestMock}
              deleteRequestMock={reducerActions.deleteRequestMock}
              saveMappingTest={reducerActions.save}
              mappingTestFreezeTime={mappingTestFreezeTime}
              setMappingTestFreezeTime={reducerActions.setMappingTestFreezeTime}
              mappingTestBlocks={mappingTestBlocks}
              commonModelName={commonModelName}
              shouldMatchOnRequestHeaders={shouldMatchOnRequestHeaders}
              setShouldMatchOnRequestHeaders={setShouldMatchOnRequestHeaders}
            />
          </MappingTestPanelContainer>
          <MappingTestPanelContainer
            panelPosition={panelPositionEnum.CENTER}
            numberOfPanels={isShowingRightPanel ? 3 : 2}
          >
            <Route path={MAPPING_TEST_EDITOR_MAIN_TAB_PATH}>
              <MappingTestV2CentralPanel
                readTestInformation={readTestInformation}
                existingCommonModels={existingCommonModels}
                isExecutionFinished={isExecutionFinished}
                operationType={mappingTestOperationType}
                testExecutionResults={testExecutionResults}
                blueprints={blueprints}
                draftBlueprints={draftBlueprints}
                integration={integration}
                mappingTestState={mappingTestReducerState.mappingTestState}
                mappingTestID={mappingTestID}
                requests={apiRequests}
                editResponseMock={reducerActions.updateMappingTestRequestBody}
                resetApiRequest={resetApiRequest}
                isInitializingDisabled={isInitializingDisabled}
                updateMappingTestBPMetadata={updateMappingTestBPMetadata}
                blueprintsMetadata={blueprintsMetadata}
                linkedAccountID={linkedAccount.id}
                mappingTestBlocks={mappingTestBlocks}
                addMappingTestBlock={reducerActions.addMappingTestBlock}
                pasteMappingTestBlock={reducerActions.pasteMappingTestBlock}
                removeMappingTestBlock={reducerActions.removeMappingTestBlock}
                addBlueprintToMappingTestBlock={reducerActions.addBlueprintToMappingTestBlock}
                removeBlueprintFromMappingTestBlock={
                  reducerActions.removeBlueprintFromMappingTestBlock
                }
                addRequestMockToMappingTestBlock={reducerActions.addRequestMockToMappingTestBlock}
                removeRequestMockFromMappingTestBlock={
                  reducerActions.removeRequestMockFromMappingTestBlock
                }
                commonModels={common_models}
                isRunningMappingTest={isRunningMappingTest}
                runMappingTest={runMappingTest}
                hasUnsavedChanges={doesMappingTestHaveUnsavedChanges}
                isTestRunningDisabled={isTestRunningDisabled}
                doesReadTestHaveDraftChanges={doesReadTestHaveDraftChanges}
                setCommonModelCountAssertionForBlock={
                  reducerActions.updateCommonModelCountAssertionForBlock
                }
                updateCommonModelExpectedMappingsForBlock={
                  reducerActions.updateCommonModelExpectedMappingsForBlock
                }
                updateRelationName={reducerActions.updateRelationName}
                setDisableFilterByDateValue={reducerActions.setDisableFilterByDateValue}
                setOverrideLastRunAtValue={reducerActions.setOverrideLastRunAtValue}
                setIsShowingRightPanel={setIsShowingRightPanel}
                selectedBlockExecution={selectedBlockExecution}
                setSelectedBlockExecution={setSelectedBlockExecution}
                setSelectedBlockName={setSelectedBlockName}
              />
            </Route>
          </MappingTestPanelContainer>
          {isShowingRightPanel && selectedBlockName && selectedBlockExecution && (
            <MappingTestPanelContainer
              panelPosition={panelPositionEnum.RIGHT}
              numberOfPanels={isShowingRightPanel ? 3 : 2}
            >
              <MappingTestV2RightPanel
                blockName={selectedBlockName}
                setIsShowingRightPanel={setIsShowingRightPanel}
                selectedBlockExecution={selectedBlockExecution}
              />
            </MappingTestPanelContainer>
          )}
          <Route path={MAPPING_TEST_EDITOR_README_TAB_PATH}>
            <MappingTestEditorReadmePanel mappingTestID={mappingTestID} />
          </Route>
        </div>
      </div>
    </EditorLeavingGuard>
  );
};

export default MappingTestV2EditorView;
