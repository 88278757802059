import { Card } from "@merge-api/merge-javascript-shared";
import { APIEndpointTypes, APIProtocols } from "../../../../models/APIEndpointModels";
import CardHeader from "../../shared/CardHeader";
import MultiSelectHeader from "../../shared/MultiSelectHeader";
import SelectOption from "../../shared/SelectOption";
import TextFieldHeader from "../../shared/TextFieldHeader";
import { SelectOptionsData } from "../../utils/types";
import { API_ENDPOINT_TYPE_SELECT_OPTIONS } from "../constants";
import { useContext } from "react";
import APIEndpointContext from "../context/APIEndpointContext";
import TypeaheadHeader from "../../shared/TypeaheadHeader";

const BasicAPIEndpointSetupOptions = () => {
  const { name, setName, protocol, setProtocol, endpointType, setEndpointType } = useContext(
    APIEndpointContext
  );

  const handleButtonClick = (value: APIProtocols) => {
    setProtocol(value);
  };

  const apiProtocolOptions = [
    {
      value: APIProtocols.REST,
      text: "REST (JSON)",
      disabled: false,
      selected: protocol === APIProtocols.REST,
      onClick: handleButtonClick,
    },
    {
      value: APIProtocols.SOAP,
      text: "SOAP (XML)",
      disabled: false,
      selected: protocol === APIProtocols.SOAP,
      onClick: handleButtonClick,
    },
    {
      value: APIProtocols.GRAPHQL,
      text: "GraphQL",
      disabled: false,
      selected: protocol === APIProtocols.GRAPHQL,
      onClick: handleButtonClick,
    },
  ];

  return (
    <Card className="pt-4 mt-6 px-5 pb-5 bg-white mb-6 min-w-fit" variant="shadow">
      <CardHeader title="API endpoint configuration" />
      <TextFieldHeader
        dataTestID="field-api-endpoint-name"
        required
        title="Name"
        placeholder="API endpoint name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        hasSource={false}
      />
      <MultiSelectHeader
        dataTestID="field-api-endpoint-api-protocol"
        required
        title="API protocol"
        subtitle="Identify the API protocol this endpoint uses"
        learnMoreText="Choose REST (JSON) if the endpoint uses URL paths and HTTP methods for different actions; SOAP (XML) for structured XML messages; GraphQL a single endpoint that accepts flexible queries."
        options={apiProtocolOptions}
        className="mt-6"
      />
      <TypeaheadHeader
        dataTestID="field-api-endpoint-endpoint-type"
        required
        title="Endpoint type"
        subtitle="Select the type of endpoint this is based on data it receives and sends"
        className="mt-6"
        options={API_ENDPOINT_TYPE_SELECT_OPTIONS}
        onChange={(_: any, selectedOption: SelectOptionsData | SelectOptionsData[] | null) => {
          if (selectedOption && !Array.isArray(selectedOption)) {
            setEndpointType(selectedOption.value as APIEndpointTypes);
          }
        }}
        value={API_ENDPOINT_TYPE_SELECT_OPTIONS.find((option) => option.value === endpointType)}
        renderOption={(option: SelectOptionsData) => <SelectOption optionData={option} />}
        getOptionLabel={(option: SelectOptionsData) => option.title}
        disabled={false}
        hasSource={false}
      />
    </Card>
  );
};

export default BasicAPIEndpointSetupOptions;
