import { Container } from "react-bootstrap";
import { Tab, TabPanel, Tabs, TabsList } from "@merge-api/merge-javascript-shared";
import { useLoadPaginatedAlerts } from "../hooks/useLoadPaginatedAlerts";
import AlertDashboardBadge from "./components/AlertDashboardBadge";
import { snakeCaseToFirstLetterUpperCase } from "../../../utils";
import { ALERT_CATEGORIES, AlertsData } from "./components/utils/constants";
import { useState } from "react";
import { TypeaheadComparatorClusterOption } from "@merge-api/merge-javascript-shared/dist/designSystem/molecules/Typeahead/types/types";
import AlertDashboardTable from "./components/AlertDashboardTable";
import AlertDashboardHeader from "./components/AlertDashboardHeader";
import { constructAlertQueryParamsFromSearchFilters } from "./components/utils/helpers";

const AlertDashboard = () => {
  // Search state
  const [searchFilters, setSearchFilters] = useState<TypeaheadComparatorClusterOption[]>([]);
  const [activeCategory, setActiveCategory] = useState<ALERT_CATEGORIES>(
    ALERT_CATEGORIES.INTEGRATION
  );
  const [showResolved, setShowResolved] = useState(false);
  const [showSilenced, setShowSilenced] = useState(false);

  // Hooks for loading paginated alerts for each category
  const alertCategories = Object.values(ALERT_CATEGORIES);

  const queryParams = constructAlertQueryParamsFromSearchFilters(
    searchFilters,
    showResolved,
    showSilenced
  );
  const alertsData: Record<ALERT_CATEGORIES, AlertsData> = {
    [ALERT_CATEGORIES.INTEGRATION]: useLoadPaginatedAlerts({
      alertCategory: ALERT_CATEGORIES.INTEGRATION,
      queryParams,
    }),
    [ALERT_CATEGORIES.ORGANIZATION]: useLoadPaginatedAlerts({
      alertCategory: ALERT_CATEGORIES.ORGANIZATION,
      queryParams,
    }),
    [ALERT_CATEGORIES.LINKED_ACCOUNT]: useLoadPaginatedAlerts({
      alertCategory: ALERT_CATEGORIES.LINKED_ACCOUNT,
      queryParams,
    }),
  };

  const getAlertCountForCategory = (category: ALERT_CATEGORIES) => {
    return alertsData[category]?.alerts?.length || 0;
  };

  const onTabChange = (_: any, newValue: string | number | null) => {
    if (typeof newValue === "string" || typeof newValue === "number") {
      const index = Number(newValue);
      if (!isNaN(index)) {
        setActiveCategory(alertCategories[index]);
        setSearchFilters([]);
      }
    }
  };

  return (
    <Container fluid className="pb-8 px-8 pt-16">
      {/* Header */}
      <h2 className="mt-10">Alerts</h2>
      <br />
      <Tabs variant="button" onChange={onTabChange}>
        <TabsList>
          {alertCategories.map((category) => (
            <Tab key={category}>
              {snakeCaseToFirstLetterUpperCase(category)}{" "}
              <AlertDashboardBadge count={getAlertCountForCategory(ALERT_CATEGORIES[category])} />
            </Tab>
          ))}
        </TabsList>
        <AlertDashboardHeader
          category={activeCategory}
          alertsData={alertsData[activeCategory]}
          searchFilters={searchFilters}
          setSearchFilters={setSearchFilters}
          showResolved={showResolved}
          setShowResolved={setShowResolved}
          showSilenced={showSilenced}
          setShowSilenced={setShowSilenced}
        />
        {/* Dynamic Tab Content */}
        {alertCategories.map((category) => (
          <TabPanel key={category}>
            <AlertDashboardTable
              alerts={alertsData[category]?.alerts}
              tableType={category}
              hasNext={alertsData[category]?.hasNext}
              onNext={alertsData[category]?.onNext}
              isLoading={alertsData[category]?.isLoading}
            />
          </TabPanel>
        ))}
      </Tabs>
    </Container>
  );
};

export default AlertDashboard;
