import { Container } from "react-bootstrap";
import { useLoadPaginatedIntegrations } from "./hooks/useLoadPaginatedIntegrations";
import {
  IntegrationsDashboardFilters,
  IntegrationsDashboardTable,
  IntegrationsOrderBy,
} from "./components";

const IntegrationsDashboard = () => {
  // hooks
  const {
    searchTerm,
    setSearchTerm,
    category,
    setCategory,
    orderBy,
    setOrderBy,
    integrations,
    hasNext,
    onNext,
    isLoading,
  } = useLoadPaginatedIntegrations({ isLeftNav: false });

  return (
    <Container fluid className="pb-8 px-8 pt-16">
      {/* header */}
      <h2 className="mt-10">Integrations</h2>
      <div className="my-6 bg-gray-20 h-[0.5px]" />

      <div className="flex flex-col mb-3">
        {/* filters */}
        <IntegrationsDashboardFilters
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          category={category}
          setCategory={setCategory}
        />

        {/* ordering */}
        <div className="mt-6">
          <IntegrationsOrderBy setOrderBy={setOrderBy} orderBy={orderBy} />
        </div>
      </div>

      {/* table */}
      <IntegrationsDashboardTable
        integrations={integrations}
        hasNext={hasNext}
        onNext={onNext}
        isLoading={isLoading}
      />
    </Container>
  );
};

export default IntegrationsDashboard;
