import { AdminAlert, PaginationType } from "../../../models/Entities";
import { usePaginatedRequest } from "../../../../src/hooks/usePaginatedRequest";
import queryString from "query-string";
import { ALERT_CATEGORIES, AlertSearchQueryParams } from "../dashboard/components/utils/constants";
interface useLoadPaginatedAlertsProps {
  alertCategory: ALERT_CATEGORIES;
  queryParams: AlertSearchQueryParams;
}

const useLoadPaginatedAlerts = ({ alertCategory, queryParams }: useLoadPaginatedAlertsProps) => {
  queryParams.alert_level = alertCategory;
  const paramsPath = queryString.stringify(queryParams);

  const { results: alerts, hasNext, onNext, isLoading } = usePaginatedRequest<AdminAlert>({
    paramsPath,
    rootPath: "analytics/alert-dashboard/search",
    isInfiniteScroll: true,
    paginationType: PaginationType.OFFSET,
  });

  return {
    alerts,
    hasNext,
    onNext,
    isLoading,
  };
};

export { useLoadPaginatedAlerts };
