import { useState } from "react";
import { AdminAlert } from "../../../../models/Entities";
import AlertDashboardTableCell from "./AlertDashboardTableCell";
import { ALERT_CATEGORIES, TABLE_TYPE_COLUMN_ORDERINGS } from "./utils/constants";
import AlertDashboardDrawer from "./drawer/AlertDashboardDrawer";

type AlertDashboardTableRowProps = {
  alert: AdminAlert;
  tableType: ALERT_CATEGORIES;
};

const AlertDashboardTableRow = ({ alert, tableType }: AlertDashboardTableRowProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const columnOrder = TABLE_TYPE_COLUMN_ORDERINGS[tableType] || [];

  const renderColumns = () => {
    return columnOrder.map((column, index) => (
      <>
        <td key={index}>
          <AlertDashboardTableCell column={column} alert={alert} />
        </td>
      </>
    ));
  };

  return (
    <>
      <AlertDashboardDrawer
        alert={alert}
        category={tableType}
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
      />
      <tr
        className="cursor-pointer text-align-left"
        onClick={() => {
          setIsDrawerOpen(true);
        }}
      >
        {" "}
        {renderColumns()}
      </tr>
    </>
  );
};

export default AlertDashboardTableRow;
