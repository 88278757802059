import { Badge } from "@merge-api/merge-javascript-shared";

interface AlertDashboardBadgeProps {
  count: number;
}

const AlertDashboardBadge = ({ count }: AlertDashboardBadgeProps) => {
  return (
    <Badge color="blue" className="ml-2">
      {count}
    </Badge>
  );
};

export default AlertDashboardBadge;
