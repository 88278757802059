import { Text, TextField, TextFieldVariant } from "@merge-api/merge-javascript-shared";
import {
  AbstractCondition,
  CONDITION_OPERATORS,
  ConditionOperator,
} from "../../../../../integration-builder/selective-sync-filters/types";
import { UserFacingFilterType } from "../../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import {
  AbstractConditionAction,
  UPDATE_CONDITION,
} from "../../../../reducers/AbstractConditionsForSelectiveSyncReducer";
import Typeahead from "@merge-api/merge-javascript-shared/dist/designSystem/molecules/Typeahead/Typeahead";

interface Props {
  index: number;
  abstractConditionsForSelectiveSync: AbstractCondition[];
  dispatchAbstractConditionsForSelectiveSync: React.Dispatch<AbstractConditionAction>;
  filterTypeOptions: UserFacingFilterType[];
}

/**
 * Renders the fields for an individual Selective Sync filter in Blueprint Editor left console
 */
const LeftPanelSelectiveSyncFilterFields = ({
  index,
  abstractConditionsForSelectiveSync,
  dispatchAbstractConditionsForSelectiveSync,
  filterTypeOptions,
}: Props) => {
  const setFilterType = (filterTypeID: string) => {
    const updatedCondition: AbstractCondition = {
      ...abstractConditionsForSelectiveSync[index],
      filter_type_id: filterTypeID,
    };
    dispatchAbstractConditionsForSelectiveSync({
      type: UPDATE_CONDITION,
      payload: { index, updatedCondition },
    });
  };

  const setOperator = (operator: string) => {
    const updatedCondition: AbstractCondition = {
      ...abstractConditionsForSelectiveSync[index],
      operator: operator,
    };
    dispatchAbstractConditionsForSelectiveSync({
      type: UPDATE_CONDITION,
      payload: { index, updatedCondition },
    });
  };

  const setValue = (value: string) => {
    const updatedCondition: AbstractCondition = {
      ...abstractConditionsForSelectiveSync[index],
      value: {
        value: value,
        typed_value: value,
      },
    };
    dispatchAbstractConditionsForSelectiveSync({
      type: UPDATE_CONDITION,
      payload: { index, updatedCondition },
    });
  };

  return (
    <div className="flex flex-col border border-gray-30 rounded-md bg-white divide-gray-30 divide-y">
      <Typeahead
        className="my-[2px]"
        placeholder="Select model & field..."
        options={filterTypeOptions}
        getOptionLabel={(option: UserFacingFilterType) =>
          option.common_model_class_id.split(".").length > 1
            ? `${option.common_model_class_id.split(".")[1]}.${option.filter_name}`
            : `${option.common_model_class_id}.${option.filter_name}`
        }
        renderOption={(option: UserFacingFilterType) => (
          <Text>
            {option.common_model_class_id.split(".").length > 1
              ? `${option.common_model_class_id.split(".")[1]}.${option.filter_name}`
              : `${option.common_model_class_id}.${option.filter_name}`}
          </Text>
        )}
        value={filterTypeOptions.find(
          (option) => option.id === abstractConditionsForSelectiveSync[index].filter_type_id
        )}
        onChange={(_, option) => {
          setFilterType(option?.id ?? "");
        }}
        borderVariant={TextFieldVariant.InlinedRounded}
      />
      <Typeahead
        placeholder="Select operator..."
        className="my-[1px]"
        options={Object.entries(CONDITION_OPERATORS).map(([key]) => key)}
        getOptionLabel={(option: string) => {
          return CONDITION_OPERATORS[option as ConditionOperator] ?? "";
        }}
        renderOption={(option: string) => {
          const label = CONDITION_OPERATORS[option as ConditionOperator] ?? "";
          return <Text>{label}</Text>;
        }}
        value={
          abstractConditionsForSelectiveSync[index]?.operator
            ? abstractConditionsForSelectiveSync[index].operator
            : null
        }
        onChange={(_, option) => {
          setOperator(option ?? "");
        }}
        borderVariant={TextFieldVariant.InlinedRounded}
      />
      <TextField
        placeholder="Enter filter value..."
        className="my-[2px]"
        variant={TextFieldVariant.InlinedRounded}
        value={abstractConditionsForSelectiveSync[index]?.value.value}
        onChange={(event) => {
          setValue(event.target.value);
        }}
      />
    </div>
  );
};

export default LeftPanelSelectiveSyncFilterFields;
