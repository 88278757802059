import { DiffStateFieldTypeEnum, DiffState } from "../../../../models/DiffModels";
import { generateDiffState } from "./helpers-diff";

// Generate diff for Authentication configuration
export const generateDiffForAuthenticationConfiguration = (): DiffState => {
  return generateDiffState({
    fields: [
      {
        type: DiffStateFieldTypeEnum.HEADER,
        name: "auth_setup",
        displayName: "Authentication setup",
        childDiffStateInputFields: [
          { name: "name", displayName: "Name" },
          { name: "general_auth_type", displayName: "Integration type" },
          { name: "basic_auth_key_header_format", displayName: "Header fields" },
          {
            name: "additional_auth_field_keys",
            displayName: "Additional auth field keys",
          },
          { name: "additional_header_fields", displayName: "Additional header fields" },
          {
            type: DiffStateFieldTypeEnum.SECTION,
            name: "api_auth_config_section",
            displayName: "API-based auth setup",
            childDiffStateInputFields: [
              {
                name: "is_oauth",
                displayName: "Is this an OAuth integration?",
                isRenderChildrenAsNested: true,
                childDiffStateInputFields: [
                  {
                    name: "auth_type",
                    displayName: "Auth type",
                  },
                  {
                    name: "oauth_grant_type",
                    displayName: "Grant type",
                  },
                  {
                    name: "oauth_params_scopes",
                    displayName: "Scopes",
                  },
                  {
                    name: "is_non_oauth_based_token_exchange",
                    displayName: "Is there a non-OAuth based token exchange?",
                  },
                  {
                    name: "oauth_set_soap_body_header_to_null",
                    displayName: "Set the SOAP body header to null?",
                  },
                ],
              },
            ],
          },
          {
            type: DiffStateFieldTypeEnum.SECTION,
            name: "scraper_config_section",
            displayName: "Scraper auth setup",
            childDiffStateInputFields: [
              {
                name: "scraper_session_lifespan_in_seconds",
                displayName: "Scraper session lifespan",
              },
              {
                name: "service_account_field_keys",
                displayName: "Service account field keys",
              },
            ],
          },
          {
            type: DiffStateFieldTypeEnum.SECTION,
            name: "sftp_config_section",
            displayName: "SFTP auth setup",
            childDiffStateInputFields: [
              {
                name: "should_end_user_provide_public_key",
                displayName: "Should the end user provide a public key?",
              },
              {
                name: "should_end_user_provide_pgp_public_key",
                displayName: "Should the end user provide a pgp public key?",
              },
              {
                name: "is_manual_upload_only_sftp",
                displayName: "Is this auth config for an SFTP flow that only supports CSV Upload?",
              },
              {
                name: "sftp_ssm_pgp_key_name",
                displayName: "What is the PGP key name?",
              },
            ],
          },
        ],
      },
      {
        type: DiffStateFieldTypeEnum.HEADER,
        name: "oauth_authorization_section",
        displayName: "OAuth authorization configuration",
        childDiffStateInputFields: [
          {
            name: "oauth_authorize_url",
            displayName: "Authorization URL",
          },
          {
            name: "oauth_client_credential_source",
            displayName: "Client credential source",
            isRenderChildrenAsNested: true,
            childDiffStateInputFields: [
              {
                name: "oauth_client_id",
                displayName: "Client ID",
              },
              {
                name: "oauth_client_secret",
                displayName: "Client secret",
                isRenderAsSecret: true,
              },
            ],
          },
          {
            type: DiffStateFieldTypeEnum.SECTION,
            name: "advanced",
            displayName: "Advanced",
            isRenderNumberOfChildrenInDisplayName: true,
            childDiffStateInputFields: [
              {
                name: "oauth_authorize_url_additional_redirect_uri_query_param_keys",
                displayName: "Redirect query params",
              },
              {
                name: "oauth_authorize_url_param_keys_to_exclude",
                displayName: "Authorize URL parameter keys to exclude",
              },
              {
                name: "oauth_use_pkce",
                displayName: "Does the OAuth flow use PKCE?",
              },
              {
                name: "oauth_should_fetch_access_token",
                displayName:
                  "Does access token need to be fetched immediately after authorization?",
              },
            ],
          },
        ],
      },
      {
        type: DiffStateFieldTypeEnum.HEADER,
        name: "oauth_token_section",
        displayName: "OAuth token configuration",
        childDiffStateInputFields: [
          {
            name: "oauth_token_url",
            displayName: "Token URL",
          },
          {
            name: "oauth_token_url_header_format",
            displayName: "Header fields",
          },
          {
            name: "oauth_token_url_params_location",
            displayName: "Params location",
          },
          {
            type: DiffStateFieldTypeEnum.SECTION,
            name: "advanced",
            displayName: "Advanced",
            isRenderNumberOfChildrenInDisplayName: true,
            childDiffStateInputFields: [
              {
                name: "oauth_token_url_header_keys_to_exclude",
                displayName: "Token URL header keys to exclude",
              },
              {
                name: "oauth_request_fields_to_exclude",
                displayName: "Params to exclude",
              },
              {
                name: "oauth_response_token_key_path",
                displayName: "Response token key path override",
              },
            ],
          },
        ],
      },
      {
        type: DiffStateFieldTypeEnum.HEADER,
        name: "oauth_refresh_section",
        displayName: "OAuth refresh configuration",
        childDiffStateInputFields: [
          {
            name: "oauth_should_refresh_access_token",
            displayName: "Does the access token need to be refreshed periodically?",
            isRenderChildrenAsNested: true,
            childDiffStateInputFields: [
              {
                name: "does_oauth_response_include_expires_in_param",
                displayName: "Does the token URL response include an `expires_in` parameter?",
                isRenderChildrenAsNested: true,
                childDiffStateInputFields: [
                  {
                    name: "token_expires_in_type",
                    displayName: "Does `expires_in` refer to fetched or created?",
                    isRenderChildrenAsNested: true,
                    childDiffStateInputFields: [
                      {
                        name: "oauth_response_created_at_key_path",
                        displayName: "Created time key path",
                      },
                      {
                        name: "oauth_response_created_datetime_format",
                        displayName: "Datetime format",
                      },
                    ],
                  },
                  {
                    name: "oauth_access_token_lifespan_in_seconds",
                    displayName: "Access token lifespan",
                  },
                ],
              },
            ],
          },
          {
            type: DiffStateFieldTypeEnum.SECTION,
            name: "advanced",
            displayName: "Advanced",
            isRenderNumberOfChildrenInDisplayName: true,
            childDiffStateInputFields: [
              {
                name: "does_oauth_refresh_include_expires_in_param",
                displayName: "Does the refresh URL response include an `expires_in` parameter?",
                isRenderChildrenAsNested: true,
                childDiffStateInputFields: [
                  {
                    name: "oauth_refresh_token_lifespan_in_seconds",
                    displayName: "Refresh token lifespan",
                  },
                ],
              },
              {
                name: "should_use_token_url_for_refresh",
                displayName: "Is the refresh URL different from the token URL?",
                isRenderChildrenAsNested: true,
                childDiffStateInputFields: [
                  {
                    name: "oauth_refresh_url",
                    displayName: "Refresh URL",
                  },
                ],
              },
              {
                name: "oauth_refresh_fields_to_exclude",
                displayName: "Refresh URL fields to exclude",
              },
            ],
          },
        ],
      },
      {
        type: DiffStateFieldTypeEnum.HEADER,
        name: "token_exchange_section",
        displayName: "Token exchange configuration",
        childDiffStateInputFields: [
          {
            name: "basic_auth_key_token_exchange_url",
            displayName: "Token exchange URL",
          },
          {
            name: "basic_auth_key_token_exchange_header_format",
            displayName: "Header fields",
          },
          {
            name: "basic_auth_key_token_exchange_request_data_format",
            displayName: "Body data",
          },
          {
            name: "basic_auth_key_token_exchange_request_data_convert_to_string",
            displayName: "Convert body data to string",
          },
          {
            name: "basic_auth_key_token_exchange_response_auth_key_path",
            displayName: "Token exchange response key path",
          },
          {
            name: "is_basic_auth_exchange_token_lifespan_in_response",
            displayName: "Is token lifespan in the response?",
            isRenderChildrenAsNested: true,
            childDiffStateInputFields: [
              {
                name: "basic_auth_key_token_exchange_access_token_lifespan_in_seconds",
                displayName: "Token lifespan",
              },
              {
                name: "basic_auth_key_token_access_token_lifespan_key_path",
                displayName: "Token lifespan key path",
              },
            ],
          },
        ],
      },
    ],
  });
};
