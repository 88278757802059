import { useContext, useState } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { showErrorToast, showSuccessToast } from "../../../shared/Toasts";
import { APIEndpointMethod, AuthType } from "../../../../models/Entities";
import { navigateToIntegrationBuilderAuthConfigPostAuthConfigCreation } from "../../../../router/RouterUtils";
import { useHistory } from "react-router-dom";
import { Row } from "../../shared/postman-table/PostmanTableRow";
import { addHttpsToString, formatPostmanRowsToString } from "../../utils/helpers";
import {
  AuthConfigIntegrationBuilder,
  DateTimeFormatEnums,
  TokenExpiresInType,
} from "../../utils/Entities";
import { ParamsLocation, PgpKeys } from "../../../integrations/auth-config/AuthConfigShared";
import IntegrationBuilderContext from "../../context/IntegrationBuilderContext";

interface AuthenticationConfigurationParams {
  setAuthConfigs: React.Dispatch<React.SetStateAction<AuthConfigIntegrationBuilder[] | undefined>>;
  integrationID: string;
  authConfigID?: string;
  actualAuthType?: AuthType;
  authConfigName?: string;
  // Scraper
  authConfigScraperSessionLifespan?: number;
  authConfigServiceAccountFieldKeys: string[] | null;
  // SFTP
  shouldEndUserProvidePublicKey?: boolean;
  shouldEndUserProvidePgpPublicKey?: boolean;
  isManualUploadOnlySftp?: boolean;
  pgpKey?: PgpKeys;
  // Basic Auth
  basicAuthKeyHeaderFormat?: Row[];
  basicAuthExchangeURL?: string;
  shouldConvertBodyDataToString?: boolean;
  refreshTokenHeaders?: Row[];
  isSoapIntegration?: boolean;
  refreshTokenBodyParams?: Row[];
  refreshTokenStringBodyParams?: string;
  basicAuthKeyTokenExchangeResponseAuthKeyPath: string[] | null;
  basicAuthKeyTokenExchangeAccessTokenLifespanInSeconds?: number;
  basicAuthKeyTokenAccessTokenLifespanKeyPath: string[] | null;
  isTokenLifespanInResponse?: boolean;
  basicAuthAdditionalAuthFieldKeys: string[] | null;
  // basic auth with token exchange and oauth
  additionalHeaderFields?: Row[];
  // OAuth authorize
  grantType?: string;
  oAuthAuthorizeURL?: string;
  oAuthParamsScopes?: string;
  doesOAuthUsePKCE?: boolean;
  clientCredentialSourceChoice?: string;
  redirectQueryParams: string[] | null;
  oAuthClientID?: string;
  oAuthClientSecret?: string;
  oAuthShouldFetchAccessToken?: boolean;
  oAuthAuthorizeURLParamKeysToExclude: string[] | null;
  oAuthSetSoapBodyHeaderToNull: boolean;
  // oauth token configuration
  tokenRequestURL?: string;
  tokenURLRequestHeaders?: Row[];
  requestParamType?: ParamsLocation;
  tokenURLRequestParams: string[] | null;
  doesResponseIncludeExpiresInParam?: boolean;
  oAuthResponseTokenKeyPath: string[] | null;
  oAuthResponseCreatedAtKeyPath: string[] | null;
  oAuthResponseTokenLifespanInSeconds?: number;
  dateTimeFormat?: DateTimeFormatEnums;
  tokenExpiresInType?: TokenExpiresInType;

  // advanced oauth token configuration
  oAuthTokenURLHeaderKeysToExclude: string[] | null;
  // oauth refresh token
  shouldRefreshAfterInitialLink?: boolean;
  shouldUseTokenURLForRefresh?: boolean;
  oAuthRefreshURL?: string;
  doesRefreshIncludeExpiresIn?: boolean;
  oAuthRefreshTokenLifespanInSeconds?: number;
  refreshURLFieldsToExclude: string[] | null;
}

const useCreateOrPatchAuthenticationConfiguration = (params: AuthenticationConfigurationParams) => {
  // hooks and state
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const {
    isAuthenticationConfigurationComplete,
    setIsAuthenticationConfigurationComplete,
    resetDiffStates,
  } = useContext(IntegrationBuilderContext);

  // body of api call shared between create and patch
  const authConfigBody = {
    name: params.authConfigName,
    auth_type: params.actualAuthType,
    scraper_session_lifespan_in_seconds: params.authConfigScraperSessionLifespan ?? null,
    service_account_field_keys: params.authConfigServiceAccountFieldKeys,
    // sftp
    should_end_user_provide_public_key: params.shouldEndUserProvidePublicKey,
    should_end_user_provide_pgp_public_key: params.shouldEndUserProvidePgpPublicKey,
    is_manual_upload_only_sftp: params.isManualUploadOnlySftp,
    sftp_ssm_pgp_key_name: params.pgpKey,
    // basic auth
    basic_auth_key_header_format:
      formatPostmanRowsToString(params.basicAuthKeyHeaderFormat) ?? null,
    basic_auth_key_token_exchange_url: params.basicAuthExchangeURL
      ? addHttpsToString(params.basicAuthExchangeURL)
      : undefined,
    basic_auth_key_token_exchange_request_data_convert_to_string:
      params.shouldConvertBodyDataToString,
    basic_auth_key_token_exchange_header_format:
      formatPostmanRowsToString(params.refreshTokenHeaders) ?? null,
    basic_auth_key_token_exchange_request_data_format:
      params.isSoapIntegration || params.shouldConvertBodyDataToString
        ? params.refreshTokenStringBodyParams
        : formatPostmanRowsToString(params.refreshTokenBodyParams) ?? null,
    basic_auth_key_token_exchange_response_auth_key_path:
      params.basicAuthKeyTokenExchangeResponseAuthKeyPath,
    basic_auth_key_token_exchange_access_token_lifespan_in_seconds:
      params.isTokenLifespanInResponse === false
        ? params.basicAuthKeyTokenExchangeAccessTokenLifespanInSeconds
        : null,
    basic_auth_key_token_access_token_lifespan_key_path:
      params.basicAuthKeyTokenAccessTokenLifespanKeyPath &&
      params.isTokenLifespanInResponse === true
        ? params.basicAuthKeyTokenAccessTokenLifespanKeyPath
        : null,
    additional_auth_field_keys: params.basicAuthAdditionalAuthFieldKeys ?? [],
    // basic auth with token exchange and oauth
    additional_header_fields: formatPostmanRowsToString(params.additionalHeaderFields) ?? null,
    // oauth
    oauth_grant_type: params.grantType ?? undefined,
    // oauth authorize
    oauth_authorize_url: params.oAuthAuthorizeURL
      ? addHttpsToString(params.oAuthAuthorizeURL)
      : undefined,
    oauth_use_pkce: params.doesOAuthUsePKCE,
    oauth_authorize_url_additional_redirect_uri_query_param_keys:
      params.redirectQueryParams ?? null,
    oauth_params_scopes: params.oAuthParamsScopes ?? null,
    oauth_client_credential_source: params.clientCredentialSourceChoice ?? undefined,
    oauth_client_id: params.oAuthClientID ?? undefined,
    oauth_client_secret: params.oAuthClientSecret ?? undefined,
    oauth_should_fetch_access_token: params.oAuthShouldFetchAccessToken,
    oauth_authorize_url_param_keys_to_exclude: params.oAuthAuthorizeURLParamKeysToExclude ?? null,
    oauth_set_soap_body_header_to_null: params.oAuthSetSoapBodyHeaderToNull,

    // oauth token configuration
    oauth_token_url: params.tokenRequestURL ? addHttpsToString(params.tokenRequestURL) : undefined,
    oauth_token_url_header_format: formatPostmanRowsToString(params.tokenURLRequestHeaders) ?? null,
    oauth_token_url_params_location: params.requestParamType,
    oauth_request_fields_to_exclude: params.tokenURLRequestParams ?? null,
    oauth_response_token_key_path: params.oAuthResponseTokenKeyPath,
    oauth_response_created_at_key_path:
      params.tokenExpiresInType === TokenExpiresInType.CREATED
        ? params.oAuthResponseCreatedAtKeyPath
        : null,
    oauth_access_token_lifespan_in_seconds:
      params.doesResponseIncludeExpiresInParam === false
        ? params.oAuthResponseTokenLifespanInSeconds
        : null,
    oauth_response_created_datetime_format:
      params.tokenExpiresInType === TokenExpiresInType.CREATED ? params.dateTimeFormat : null,
    oauth_token_url_header_keys_to_exclude: params.oAuthTokenURLHeaderKeysToExclude ?? null,
    // oauth refresh url
    oauth_should_refresh_access_token: params.shouldRefreshAfterInitialLink,
    oauth_refresh_url: params.shouldUseTokenURLForRefresh
      ? addHttpsToString(params.oAuthRefreshURL)
      : undefined,
    oauth_refresh_token_lifespan_in_seconds: params.doesRefreshIncludeExpiresIn
      ? null
      : params.oAuthRefreshTokenLifespanInSeconds,
    oauth_refresh_fields_to_exclude: params.refreshURLFieldsToExclude ?? null,
  };

  const createAuthenticationConfiguration = () => {
    setIsLoading(true);
    fetchWithAuth({
      path: `/integrations/${params.integrationID}/auth-configs?auth_type=${params.actualAuthType}`,
      method: APIEndpointMethod.POST,
      body: authConfigBody,
      onResponse: (responseData: AuthConfigIntegrationBuilder) => {
        showSuccessToast("Authentication Configuration successfully created.");
        // reset diff states, so that unsaved modal doesn't pop up
        resetDiffStates();
        // navigates to url for auth config with id
        navigateToIntegrationBuilderAuthConfigPostAuthConfigCreation(
          history,
          params.integrationID,
          responseData.id
        );
        setIsLoading(false);
        // add new auth config to authConfigs array
        params.setAuthConfigs((prevAuthConfigs) => {
          return prevAuthConfigs ? [...prevAuthConfigs, responseData] : [responseData];
        });
        //update AuthenticationConfiguration status in context
        if (isAuthenticationConfigurationComplete === false) {
          setIsAuthenticationConfigurationComplete(true);
        }
      },
      onError: () => {
        showErrorToast("Failed to create your Authentication Configuration.");
        setIsLoading(false);
      },
    });
  };

  const patchAuthenticationConfiguration = () => {
    setIsLoading(true);
    fetchWithAuth({
      path: `/integrations/${params.integrationID}/auth-configs/${params.authConfigID}`,
      method: APIEndpointMethod.PATCH,
      body: authConfigBody,
      onResponse: (responseData: AuthConfigIntegrationBuilder) => {
        showSuccessToast("Authentication Configuration successfully updated.");
        // reset diff states, so that unsaved modal doesn't pop up
        resetDiffStates();
        setIsLoading(false);
        // replace auth config in authConfigs array with new data
        params.setAuthConfigs((prevAuthConfigs) => {
          return prevAuthConfigs?.map((config) =>
            config.id === params.authConfigID ? responseData : config
          );
        });
      },
      onError: () => {
        showErrorToast("Failed to update your Authentication Configuration.");
        setIsLoading(false);
      },
    });
  };

  return { createAuthenticationConfiguration, patchAuthenticationConfiguration, isLoading };
};

export default useCreateOrPatchAuthenticationConfiguration;
