import { Badge } from "@merge-api/merge-javascript-shared";
import { AlertStatus } from "./utils/constants";

interface StatusCellProps {
  status: AlertStatus;
}

const StatusCell = ({ status }: StatusCellProps) => {
  const badgeColor = status === AlertStatus.RESOLVED ? "gray" : "blue";

  return (
    <Badge className="text-left-align font-medium" color={badgeColor}>
      {status}
    </Badge>
  );
};

export default StatusCell;
