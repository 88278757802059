import LineIndent from "../shared/LineIndent";
import BinaryChoiceButtons from "../shared/BinaryChoiceButtons";
import AccordionCard from "../../../shared/AccordionCard";
import SFTPPGPKeyButtons from "../buttons/SFTPPGPKeyButtons";
import { PgpKeys } from "../../../../integrations/auth-config/AuthConfigShared";

interface SFTPConfigurationProps {
  shouldEndUserProvidePublicKey: boolean | undefined;
  setShouldEndUserProvidePublicKey: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  shouldEndUserProvidePgpPublicKey: boolean | undefined;
  setShouldEndUserProvidePgpPublicKey: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  isManualUploadOnlySftp: boolean | undefined;
  setIsManualUploadOnlySftp: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  pgpKey: PgpKeys;
  setPgpKey: React.Dispatch<React.SetStateAction<PgpKeys>>;
}

const SFTPConfiguration = ({
  shouldEndUserProvidePublicKey,
  setShouldEndUserProvidePublicKey,
  shouldEndUserProvidePgpPublicKey,
  setShouldEndUserProvidePgpPublicKey,
  isManualUploadOnlySftp,
  setIsManualUploadOnlySftp,
  pgpKey,
  setPgpKey,
}: SFTPConfigurationProps) => {
  return (
    <LineIndent className="mt-6">
      <BinaryChoiceButtons
        dataTestID="field-auth-config-should-end-user-provide-public-key"
        title="Should the end user provide a public key?"
        binaryChoice={shouldEndUserProvidePublicKey}
        setBinaryChoice={setShouldEndUserProvidePublicKey}
        required
      />
      <BinaryChoiceButtons
        className="mt-6"
        dataTestID="field-auth-config-should-end-user-provide-pgp-public-key"
        title="Should the end user provide a pgp public key?"
        binaryChoice={shouldEndUserProvidePgpPublicKey}
        setBinaryChoice={setShouldEndUserProvidePgpPublicKey}
        required
      />
      <BinaryChoiceButtons
        className="mt-6"
        dataTestID="field-auth-config-is-manual-upload-only-sftp"
        title="Is the auth config only for manual CSV upload?"
        binaryChoice={isManualUploadOnlySftp}
        setBinaryChoice={setIsManualUploadOnlySftp}
        required
      />
      <AccordionCard className="mt-6 mb-0" title={"Advanced"} badgeContents={1}>
        <SFTPPGPKeyButtons className="mt-6" pgpKey={pgpKey} setPgpKey={setPgpKey} />
      </AccordionCard>
    </LineIndent>
  );
};

export default SFTPConfiguration;
