import { Button, ButtonVariant, Text } from "@merge-api/merge-javascript-shared";
import { X } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { fetchWithAuth } from "../../../../../api-client/api_client";
import { APIEndpointMethod, LinkedAccount } from "../../../../../models/Entities";
import useIntegrationBuilderContext from "../../../context/useIntegrationBuilderContext";
import APIRequestTesterParamInputs from "./APIRequestTesterParamInputs";
import TestRunResponseBlock, { TestRunResponse } from "../../../../shared/TestRunResponseBlock";
import { showErrorToast } from "../../../../shared/Toasts";
import { APIEndpointIntegrationBuilder } from "../../../utils/Entities";
import TypeaheadHeader from "../../../shared/TypeaheadHeader";

export interface APIEndpointParameterValues {
  headers?: Record<string, string>;
  query_params?: Record<string, string>;
  path_params?: Record<string, string>;
  body?: Record<string, string>;
  soap_request?: Record<string, string>;
}

interface Props {
  formAPIEndpointData: () => APIEndpointIntegrationBuilder | null;
  integrationID: string;
}

const APIRequestTester = ({ integrationID, formAPIEndpointData }: Props) => {
  const { setIsRightPanelOpen } = useIntegrationBuilderContext();

  const [
    selectedTestLinkedAccount,
    setSelectedTestLinkedAccount,
  ] = useState<null | LinkedAccount>();
  const [testLinkedAccounts, setTestLinkedAccounts] = useState<LinkedAccount[]>([]);
  const [testRunResponse, setTestRunResponse] = useState<TestRunResponse | undefined>();
  const [isTestRunLoading, setIsTestRunLoading] = useState(false);
  useEffect(() => {
    fetchWithAuth({
      path: `/integrations/linked-accounts?is_test_account=True&integration=${integrationID}`,
      method: "GET",
      onResponse: (data) => {
        setTestLinkedAccounts(data.results);
        setSelectedTestLinkedAccount(data.results[0]);
      },
    });
  }, [integrationID]);

  const [parameterValues, setParameterValues] = useState<APIEndpointParameterValues>({});

  const runTestRequest = useCallback(() => {
    setIsTestRunLoading(true);
    const api_endpoint = formAPIEndpointData();
    fetchWithAuth({
      path: "/integrations/integration-builder/test-endpoint",
      method: APIEndpointMethod.POST,
      body: {
        linked_account_id: selectedTestLinkedAccount?.id,
        api_endpoint: api_endpoint,
        parameter_values: parameterValues,
      },
      onResponse: (data) => {
        setTestRunResponse(data);
        setIsTestRunLoading(false);
      },
      onError: (error) => {
        setIsTestRunLoading(false);
        error
          ?.json()
          .then((value: string | Record<string, any>) => {
            let errorMessage = "";
            if (typeof value == "string") {
              errorMessage = value;
            } else {
              errorMessage = value?.non_field_errors ?? JSON.stringify(value);
            }
            showErrorToast(`Failed to run. Error message: \n ${errorMessage}`);
          })
          .catch(() => {
            showErrorToast("Failed to run.");
          });
      },
    });
  }, [formAPIEndpointData, parameterValues, selectedTestLinkedAccount]);

  return (
    <div
      className={`flex-col w-[516px] h-full overflow-y-auto bg-white border-l-[0.5px] border-l-gray-20`}
    >
      <div className="flex w-full flex-row items-center border-b-[0.5px] border-b-gray-20 px-5 py-4">
        <Button
          className="border-white"
          variant={ButtonVariant.IconShadowHidden}
          onClick={() => setIsRightPanelOpen(false)}
        >
          <X className="text-gray-50" size={20} />
        </Button>
        <h4 className="ml-2">API tester</h4>
      </div>
      <div className="p-5 w-full">
        <TypeaheadHeader
          title="Linked Account"
          subtitle="Select the Linked Account to run this request on. A real request will run."
          value={selectedTestLinkedAccount}
          hasSource={false}
          onChange={(_: any, selectedOption: LinkedAccount | LinkedAccount[] | null) => {
            if (!Array.isArray(selectedOption)) {
              setSelectedTestLinkedAccount(selectedOption);
            }
          }}
          renderOption={(option: LinkedAccount) => (
            <Text>
              {option.end_user.organization_name} {option.id}
            </Text>
          )}
          getOptionLabel={(option: LinkedAccount) =>
            `${option.end_user.organization_name} ${option.id}`
          }
          options={testLinkedAccounts}
          disableClearable={true}
        />

        <APIRequestTesterParamInputs setParameterValues={setParameterValues} />
        <Button
          onClick={runTestRequest}
          children="Send request"
          className="mt-6"
          fullWidth
          loading={isTestRunLoading}
          disabled={!selectedTestLinkedAccount}
        />
        {testRunResponse && <TestRunResponseBlock response={testRunResponse} />}
      </div>
    </div>
  );
};

export default APIRequestTester;
