import { useCallback, useEffect, useState } from "react";
import { fetchWithAuth } from "../../../api-client/api_client";

export enum MergeFlagFeature {
  MERGE_FLAG_AI_ENDPOINTS = "MERGE_FLAG_AI_ENDPOINTS",
  MERGE_FLAG_RATE_LIMIT_GENERATOR = "MERGE_FLAG_RATE_LIMIT_GENERATOR",
  MERGE_FLAG_PAGINATION_CONFIGURATION_GENERATOR = "MERGE_FLAG_PAGINATION_CONFIGURATION_GENERATOR",
  MERGE_FLAG_SELECTIVE_SYNC_FILTER_BUILDER = "MERGE_FLAG_SELECTIVE_SYNC_FILTER_BUILDER",
  MERGE_FLAG_STEP_IO = "MERGE_FLAG_STEP_IO",
  MERGE_FLAG_STEP_IO_FOR_CANVAS = "MERGE_FLAG_STEP_IO_FOR_CANVAS",
  MERGE_FLAG_PUBLISH_MODULE_V2 = "MERGE_FLAG_PUBLISH_MODULE_V2",
  MERGE_FLAG_ASYNC_VALIDATOR = "MERGE_FLAG_ASYNC_VALIDATOR",
  MERGE_FLAG_BLUEPRINT_STEP_TRACING = "MERGE_FLAG_BLUEPRINT_STEP_TRACING",
  MERGE_FLAG_ALERT_DASHBOARD = "MERGE_FLAG_ALERT_DASHBOARD",
  MERGE_FLAG_ENABLE_CANARY_TESTING = "MERGE_FLAG_ENABLE_CANARY_TESTING",
}

export type UseMergeFlagProps = {
  feature: MergeFlagFeature;
  isEnabledForUser?: boolean;
  organizationId?: string;
  linkedAccountId?: string;
  integrationId?: string;
};

/**
 * Hook for checking if merge flag is enabled. If the user making the request is not added to the flag,
 * then requires one (and only one) of organizationId, linkedAccountId, or integrationId
 *
 * If you just want to hide UI can use `<MergeFlagWrapper />`
 */
export const useMergeFlag = ({
  feature,
  isEnabledForUser,
  linkedAccountId,
  organizationId,
  integrationId,
}: UseMergeFlagProps) => {
  // state
  const [enabled, setEnabled] = useState<boolean | undefined>(undefined);
  const [enabledForLinkedAccount, setEnabledForLinkedAccount] = useState<boolean | undefined>(
    undefined
  );
  const [enabledForOrganization, setEnabledForOrganization] = useState<boolean | undefined>(
    undefined
  );
  const [enabledForIntegration, setEnabledForIntegration] = useState<boolean | undefined>(
    undefined
  );
  const [enabledForUser, setEnabledForUser] = useState<boolean | undefined>(undefined);
  const [hasInitialized, setHasInitialized] = useState(false);

  // event handlers
  const loadMergeFlagPermissions = useCallback(() => {
    fetchWithAuth({
      path: `integrations/merge-flags/${feature}/enabled`,
      method: "POST",
      body: {
        organizationId,
        integrationId,
        linkedAccountId,
      },
      onResponse: (res: {
        feature_enabled_for_organization: boolean;
        feature_enabled_for_integration: boolean;
        feature_enabled_for_linked_account: boolean;
        feature_enabled_for_user: boolean;
      }) => {
        if (
          (organizationId && res.feature_enabled_for_organization) ||
          (integrationId && res.feature_enabled_for_integration) ||
          (linkedAccountId && res.feature_enabled_for_linked_account) ||
          (isEnabledForUser && res.feature_enabled_for_user)
        ) {
          setEnabled(true);
        } else {
          setEnabled(false);
        }
        setEnabledForUser(res.feature_enabled_for_user ?? undefined);
        setEnabledForLinkedAccount(res.feature_enabled_for_linked_account ?? undefined);
        setEnabledForOrganization(res.feature_enabled_for_organization ?? undefined);
        setEnabledForIntegration(res.feature_enabled_for_integration ?? undefined);
        setHasInitialized(true);
      },
      onError: (err) => {
        console.error(err);
        setHasInitialized(true);
      },
    });
  }, [integrationId, linkedAccountId, organizationId, feature]);

  // effects
  // initialize
  useEffect(() => {
    loadMergeFlagPermissions();
  }, [loadMergeFlagPermissions]);

  return {
    hasInitialized,
    enabled,
    enabledForUser,
    enabledForLinkedAccount,
    enabledForOrganization,
    enabledForIntegration,
  };
};
