import { Accordion, Text, Menu, MenuItem, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { Ellipsis, Plus } from "lucide-react";
import { useState } from "react";
import { APIEndpointParameter } from "src/autogenerated-types/AUTOGENERATED_ExpandedPydantic_APIEndpointParameter";
import {
  ParameterMappingForOperatorDetails,
  UserFacingFilterDetails,
  ValueTransformation,
  VersionedComponentInfo,
} from "../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import CheckboxHeader from "../../../shared/CheckboxHeader";
import { ConditionOperator, CONDITION_OPERATORS } from "../../types";
import APIEndpointParameterModal from "../api-endpoint-parameter/APIEndpointParameterModal";
import APIEndpointParameterMappingPreviewRow from "./api-endpoint-parameter-mapping/APIEndpointParameterMappingPreviewRow";

type Props = {
  operator: ConditionOperator;
  mappingsForOperator: ParameterMappingForOperatorDetails;
  removeFilterOperator: (newOperator: ConditionOperator) => void;
  userFacingDetails: UserFacingFilterDetails | null;
  addOrUpdateAPIEndpointParameter: (
    newAPIEndpointParameter: VersionedComponentInfo,
    operator: string
  ) => void;
  removeParameterMappingDetails: (parameterMappingDetailsID: string, operator: string) => void;
  setAllowManualFilterMappingInBlueprints: (newValue: boolean, operator: string) => void;
  isDisabled?: boolean;
  updateValueTransformation: (
    newValueTransformation: ValueTransformation | null,
    operator: string,
    index: number
  ) => void;
};

const EDIT_3P_FILTER = "Select existing third-party endpoint parameter";
const CREATE_NEW_3P_FILTER = "Create new third-party endpoint parameter";

const THIRD_PARTY_FILTER_DROPDOWN_OPTIONS = [CREATE_NEW_3P_FILTER, EDIT_3P_FILTER];

const ParameterMappingOperatorContainer = ({
  operator,
  userFacingDetails,
  mappingsForOperator,
  removeParameterMappingDetails,
  addOrUpdateAPIEndpointParameter,
  removeFilterOperator,
  updateValueTransformation,
  setAllowManualFilterMappingInBlueprints,
  isDisabled,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [
    editingExistingAPIEndpointParameterMapping,
    setEditExistingAPIEndpointParameterMapping,
  ] = useState<boolean>(false);
  const [
    existingAPIEndpointParameter,
    setExistingAPIEndpointParameter,
  ] = useState<APIEndpointParameter | null>(null);

  const handleMenuClick = (option: string) => {
    if (option == EDIT_3P_FILTER) setEditExistingAPIEndpointParameterMapping(true);
    else setEditExistingAPIEndpointParameterMapping(false);

    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setExistingAPIEndpointParameter(null);
    setIsModalOpen(false);
  };

  const handleEditExistingParameterMapping = (apiEndpointParameter: APIEndpointParameter) => {
    setExistingAPIEndpointParameter(apiEndpointParameter);
    setEditExistingAPIEndpointParameterMapping(true);
    setIsModalOpen(true);
  };

  return (
    <Accordion
      chevronOrientation="right"
      className={!!isDisabled ? "bg-gray-0" : ""}
      title={
        <div className="flex flex-row items-center justify-between w-full pr-6">
          <div>
            <Text variant="h5">{CONDITION_OPERATORS[operator]}</Text>
          </div>
          <div
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
              event.stopPropagation();
            }}
          >
            {!isDisabled && (
              <Menu
                ButtonProps={{
                  children: <Ellipsis size={16} />,
                  variant: ButtonVariant.IconShadowHidden,
                }}
                menuPlacement="bottom-end"
              >
                <MenuItem onClick={() => removeFilterOperator(operator)}>
                  <Text variant="h6" className="text-red-50">
                    Remove Operator
                  </Text>
                </MenuItem>
              </Menu>
            )}
          </div>
        </div>
      }
      titleClassName="px-5 py-4 w-100"
    >
      {isModalOpen ? (
        <APIEndpointParameterModal
          isModalOpen={isModalOpen}
          setIsModalOpen={handleModalClose}
          isEditingExistingAPIEndpointParameter={editingExistingAPIEndpointParameterMapping}
          userFacingDetails={userFacingDetails as UserFacingFilterDetails}
          existingAPIEndpointParameter={existingAPIEndpointParameter}
          addOrUpdateAPIEndpointParameter={(newAPIEndpointParameter: VersionedComponentInfo) =>
            addOrUpdateAPIEndpointParameter(newAPIEndpointParameter, operator)
          }
          isDisabled={isDisabled}
        />
      ) : null}
      <hr className="m-0" />
      <CheckboxHeader
        dataTestID="field-api-endpoint-should-filter-empty-values-from-body-data"
        className="mt-2 px-5"
        title="Allow manual filter mapping in blueprints"
        subtitle="If True, indicates that the values for this selective sync filter should NOT be applied automatically, and should instead be manually constructed in the blueprint via the GetSelectiveSyncConditionValue step"
        disabled={isDisabled}
        onChange={() =>
          setAllowManualFilterMappingInBlueprints(
            !mappingsForOperator?.allow_manual_filter_mapping_in_blueprints,
            operator
          )
        }
        checked={mappingsForOperator?.allow_manual_filter_mapping_in_blueprints}
      />
      <div className="px-5 pt-5 flex flex-col w-100">
        {(mappingsForOperator?.api_endpoint_filter_mappings ?? []).map(
          (parameterMapping, index) => (
            <APIEndpointParameterMappingPreviewRow
              key={parameterMapping.id}
              operator={operator}
              removeParameterMappingDetails={removeParameterMappingDetails}
              apiEndpointParameterMapping={parameterMapping}
              userFacingDetails={userFacingDetails}
              updateValueTransformation={(newValueTransformation: ValueTransformation | null) =>
                updateValueTransformation(newValueTransformation, operator, index)
              }
              handleEditExistingParameterMapping={handleEditExistingParameterMapping}
              isDisabled={isDisabled}
            />
          )
        )}
      </div>
      <div className="p-5 flex flex-row justify-between w-full items-end">
        <Menu
          ButtonProps={{
            leftIcon: <Plus size={16} />,
            color: "blueSecondary",
            children: "Third-Party Endpoint Parameter",
            disabled: !!!(
              userFacingDetails?.filter_type_id?.id &&
              userFacingDetails?.remote_key_name &&
              !isDisabled
            ),
          }}
        >
          {THIRD_PARTY_FILTER_DROPDOWN_OPTIONS.map((option) => (
            <MenuItem key={option} onClick={() => handleMenuClick(option)}>
              {option}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </Accordion>
  );
};

export default ParameterMappingOperatorContainer;
