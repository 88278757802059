import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Section } from "../utils/Entities";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import IntegrationBuilderContext from "../context/IntegrationBuilderContext";
import IntegrationBuilderLeftNavBarTab from "./components/IntegrationBuilderLeftNavBarTab";
import { getRelativePath } from "../utils/helpers";
import { ArrowLeft } from "lucide-react";
import { navigateToIntegration } from "../../../router/RouterUtils";

interface NavigationBarProps {
  sections: Section[];
  onNavigate: (path: string) => void;
  integrationID: string | undefined;
}

const IntegrationBuilderLeftNavBar = ({
  sections,
  onNavigate,
  integrationID,
}: NavigationBarProps) => {
  // hooks
  const history = useHistory();
  const { integration } = useContext(IntegrationBuilderContext);

  // state
  const [relativePathOfSelectedPage, setRelativePathOfSelectedPage] = React.useState("");

  // sets the active path on mount
  // partialPath uses the 4th item in path (ie.: admin.merge.dev/integration-builder/xyz/merge-link-steps --> merge-link-steps)
  useEffect(() => {
    setRelativePathOfSelectedPage(getRelativePath(history.location.pathname));
  }, [history.location.pathname]);

  return (
    <div className="max-w-[308px] min-w-[308px] pt-6 p-4 text-black border-r border-gray-10 bg-white">
      {integrationID && (
        <Button
          leftIcon={<ArrowLeft size={16} />}
          className="mb-6"
          size="md"
          variant={ButtonVariant.TextBlue}
          onClick={() => {
            navigateToIntegration(history, integrationID);
          }}
        >
          Back to {integration?.name}
        </Button>
      )}
      {integrationID ? (
        <h3 className="mb-2">Building {integration?.name}</h3>
      ) : (
        <h3 className="mb-2">Welcome to the Guided Integration Builder!</h3>
      )}
      <hr className="text-gray-50 h-[0.5px] my-4" />
      <div className="flex flex-col gap-y-3">
        {sections.map((item, itemIndex) => {
          return (
            <IntegrationBuilderLeftNavBarTab
              key={itemIndex}
              item={item}
              itemIndex={itemIndex}
              relativePathOfSelectedPage={relativePathOfSelectedPage}
              onNavigate={onNavigate}
            />
          );
        })}
      </div>
    </div>
  );
};

export default IntegrationBuilderLeftNavBar;
