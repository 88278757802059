import { Button, ButtonVariant, Text, Tooltip } from "@merge-api/merge-javascript-shared";
import clsx from "clsx";
import { CircleCheck, OctagonX, TriangleAlert } from "lucide-react";
import { useHistory } from "react-router-dom";
import {
  navigateToBlueprintEditor,
  navigateToIntegrationBuilderSelectiveSyncFilterBuilder,
  navigateToIntegrationBuilderSelectiveSyncFilterBuilderForId,
  navigateToTestSuitesTable,
} from "../../../../router/RouterUtils";
import { useStagedComponentContext } from "../context/StagedComponentContext";
import {
  IntegrationComponentType,
  IntegrationValidatorRuleResult,
  IntegrationValidatorRuleResultType,
  IntegrationValidatorRuleSeverityType,
  ValidatedStagedComponent,
  ValidatorRunStatusType,
} from "../types";
import IntegrationValidatorRuleOverrideDetails from "./IntegrationValidatorRuleOverrideDetails";
import { usePublishModuleContext } from "../context/PublishModuleContext";

interface Props {
  validatorRuleResult: IntegrationValidatorRuleResult;
  setIsModalOpen: (value: boolean) => void;
  className?: string;
}

interface ValidatorRuleActionProps {
  title: string;
  onClick: () => void;
}

interface ValidatoRuleAlertProps {
  icon: JSX.Element;
  title: string;
}

const getAlertProps = (
  validatorRuleResult: IntegrationValidatorRuleResult
): ValidatoRuleAlertProps => {
  const { severity, result, is_overridden } = validatorRuleResult;

  // If the result is overridden, we want to show a gray alert with a checkmark or x depending on if the overridden alert passed
  if (is_overridden) {
    return {
      icon:
        result === IntegrationValidatorRuleResultType.SUCCESS ? (
          <CircleCheck className="text-teal-70" size={16} />
        ) : (
          <OctagonX className="text-red-50" size={16} />
        ),
      title:
        result === IntegrationValidatorRuleResultType.SUCCESS
          ? validatorRuleResult.rule_name
          : validatorRuleResult.exception_message || validatorRuleResult.rule_name,
    };
  }

  // If the result is not overridden and a success, we want to show a green alert with a checkmark
  if (result === IntegrationValidatorRuleResultType.SUCCESS) {
    return {
      icon: <CircleCheck className="text-black" size={16} />,
      title: validatorRuleResult.rule_name,
    };
  }

  // All other alerts should be displayed by their severity
  switch (severity) {
    case IntegrationValidatorRuleSeverityType.BLOCKING:
      return {
        icon: <OctagonX className="text-black" size={16} />,
        title: validatorRuleResult.exception_message || validatorRuleResult.rule_name,
      };
    case IntegrationValidatorRuleSeverityType.WARNING:
      return {
        icon: <TriangleAlert className="text-black" size={16} />,
        title: validatorRuleResult.exception_message || validatorRuleResult.rule_name,
      };
    default:
      return {
        icon: <CircleCheck className="text-black" size={16} />,
        title: validatorRuleResult.exception_message || validatorRuleResult.rule_name,
      };
  }
};

const getActionButtonProps = (
  component: ValidatedStagedComponent,
  ruleResult: IntegrationValidatorRuleResult,
  integrationID: string,
  history: any
): ValidatorRuleActionProps | null => {
  const defaultActionButtonProps: { [id: string]: ValidatorRuleActionProps } = {
    [IntegrationComponentType.BLUEPRINT]: {
      title: "Go to blueprint",
      onClick: () =>
        navigateToBlueprintEditor(history, integrationID, component.component_version_id, true),
    },
    [IntegrationComponentType.API_ENDPOINT_PARAMETER]: {
      title: "Go to filter",
      onClick: () =>
        navigateToIntegrationBuilderSelectiveSyncFilterBuilder(history, integrationID, true),
    },
    [IntegrationComponentType.SELECTIVE_SYNC_FILTER_SCHEMA]: {
      title: "Go to filter",
      onClick: () =>
        navigateToIntegrationBuilderSelectiveSyncFilterBuilderForId(
          history,
          integrationID,
          true,
          ruleResult.component_version_id
        ),
    },
  };

  const ruleSpecificActionButtonProps: { [id: string]: ValidatorRuleActionProps } = {
    mapping_test_coverage: {
      title: "Go to mapping tests",
      onClick: () => {
        navigateToTestSuitesTable(history, integrationID, true);
      },
    },
  };

  if (ruleResult.rule_id in ruleSpecificActionButtonProps) {
    return ruleSpecificActionButtonProps[ruleResult.rule_id];
  }
  if (component.component_type in defaultActionButtonProps) {
    return defaultActionButtonProps[component.component_type];
  }
  return null;
};

const IntegrationValidatorRuleAlertTitle = ({
  validatorRuleResult,
  setIsModalOpen,
  className,
}: Props) => {
  const history = useHistory();
  const { component, integrationID, removeOverride } = useStagedComponentContext();
  const { validatorRunStatus } = usePublishModuleContext();
  const { icon, title } = getAlertProps(validatorRuleResult);
  const actionButtonProps = getActionButtonProps(
    component,
    validatorRuleResult,
    integrationID,
    history
  );

  const handleActionButton = (e: any) => {
    e.stopPropagation();
    actionButtonProps?.onClick();
  };

  const handleOverride = (e: any) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const handleRemoveOverride = (e: any) => {
    e.stopPropagation();
    removeOverride(validatorRuleResult);
  };

  const { is_overridden: isOverridden, severity, result, override } = validatorRuleResult;

  const canOverride =
    severity === IntegrationValidatorRuleSeverityType.BLOCKING &&
    result === IntegrationValidatorRuleResultType.FAILURE;
  const hasActionButton = result !== IntegrationValidatorRuleResultType.SUCCESS;
  const isModifyingOverridesDisabled = validatorRunStatus == ValidatorRunStatusType.IN_PROGRESS; // Disable modifying overrides while the checks are in progress

  return (
    <div className={clsx(className, "bg-transparent w-full rounded-lg")}>
      <div className="flex flex-row items-center w-full space-x-5 rounded-lg mr-3">
        {icon}
        <div className="flex flex-row items-center justify-between w-full">
          <Text className={isOverridden ? "text-gray-70" : "text-black"}>{title}</Text>
          <div className="flex flex-row items-center space-x-3">
            {isOverridden ? (
              <Tooltip
                title={
                  isModifyingOverridesDisabled
                    ? "Modifying overrides is disabled until the checks have completed"
                    : ""
                }
              >
                <Button
                  onClick={handleRemoveOverride}
                  size="sm"
                  variant={ButtonVariant.TextBlack}
                  disabled={isModifyingOverridesDisabled}
                >
                  Turn on check
                </Button>
              </Tooltip>
            ) : canOverride ? (
              <Tooltip
                title={
                  isModifyingOverridesDisabled
                    ? "Modifying overrides is disabled until the checks have completed"
                    : ""
                }
              >
                <Button
                  onClick={handleOverride}
                  size="sm"
                  variant={ButtonVariant.TextBlack}
                  disabled={isModifyingOverridesDisabled}
                >
                  Override
                </Button>
              </Tooltip>
            ) : null}
            {hasActionButton && actionButtonProps && (
              <Button onClick={handleActionButton} size="sm" variant={ButtonVariant.TertiaryWhite}>
                {actionButtonProps.title}
              </Button>
            )}
          </div>
        </div>
      </div>
      <IntegrationValidatorRuleOverrideDetails override={override} />
    </div>
  );
};

export default IntegrationValidatorRuleAlertTitle;
