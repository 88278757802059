import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { FOLDER_NAMES } from "../../../constants";
import { Integration } from "../../../models/Entities";
import { navigateToBlueprintsSubtab, navigateToTestSuitesTable } from "../../../router/RouterUtils";
import MergeText, { TextType } from "../../shared/text/MergeText";
import IntegrationEditorTopControlPanelAction, {
  IntegrationEditorTopControlPanelActionProps,
} from "./IntegrationEditorTopControlPanelAction";

const CONTRAST_SURFACE_2 = "#0C236B";

const Image = styled.img`
  border-radius: 8px;
  padding: 4px;
  height: 20px;
  width: 20px;
`;

const ControlPanelContainer = styled.div`
  background: ${CONTRAST_SURFACE_2};
  height: 40px;
  width: 100%;
  display: flex;
  position: fixed;
  align-items: center;
  flex: 1 0 auto;
  justify-content: space-between;
`;

const LeftSectionContainer = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-left: 12px;
  }
`;

const RightSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  > * {
    margin-left: 12px;
  }
`;

const ClickableMergeText = styled(MergeText)`
  cursor: pointer;
`;

type Props = {
  actions: IntegrationEditorTopControlPanelActionProps[];
  integration: Integration;
  path: string[];
};

const IntegrationEditorTopControlPanel = ({ actions, integration, path }: Props) => {
  const history = useHistory();

  return (
    <ControlPanelContainer>
      <LeftSectionContainer>
        <Image src={integration.square_image} />
        <ClickableMergeText
          isBold
          onClick={(event: React.MouseEvent<HTMLDivElement>) =>
            navigateToBlueprintsSubtab(history, integration.id, event.metaKey || event.ctrlKey)
          }
          size="12px"
          type={TextType.WHITE}
        >
          {integration.name}
        </ClickableMergeText>
        {path.map((folder) => (
          <React.Fragment key={folder}>
            <MergeText key={folder + "1"} isBold size="12px" type={TextType.WHITE}>
              /
            </MergeText>
            <ClickableMergeText
              isBold
              onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                if (folder == FOLDER_NAMES.MAPPING_TEST_FOLDER) {
                  navigateToTestSuitesTable(
                    history,
                    integration.id,
                    event.metaKey || event.ctrlKey
                  );
                } else
                  navigateToBlueprintsSubtab(
                    history,
                    integration.id,
                    event.metaKey || event.ctrlKey
                  );
              }}
              size="12px"
              type={TextType.WHITE}
            >
              {folder}
            </ClickableMergeText>
          </React.Fragment>
        ))}
      </LeftSectionContainer>
      <RightSectionContainer>
        {actions
          .filter((action) => !action?.isHidden)
          .map((action) => (
            <IntegrationEditorTopControlPanelAction key={action.text} {...action} />
          ))}
      </RightSectionContainer>
    </ControlPanelContainer>
  );
};

export default IntegrationEditorTopControlPanel;
