import { Toggle, Typeahead } from "@merge-api/merge-javascript-shared";
import { TypeaheadComparatorClusterOption } from "@merge-api/merge-javascript-shared/dist/designSystem/molecules/Typeahead/types/types";
import { Dispatch, SetStateAction } from "react";

interface AlertDashboardFiltersProps {
  activeFilters: TypeaheadComparatorClusterOption[];
  setActiveFilters: (filters: TypeaheadComparatorClusterOption[]) => void;
  availableFilters: TypeaheadComparatorClusterOption[];
  showResolved: boolean;
  setShowResolved: Dispatch<SetStateAction<boolean>>;
  showSilenced: boolean;
  setShowSilenced: Dispatch<SetStateAction<boolean>>;
}

const AlertDashboardFilters = ({
  activeFilters,
  setActiveFilters,
  availableFilters,
  showResolved,
  setShowResolved,
  showSilenced,
  setShowSilenced,
}: AlertDashboardFiltersProps) => {
  return (
    <div className="flex w-full">
      <div className="flex-grow">
        <Typeahead
          key={availableFilters.length} // Changing the key forces the component to refresh.  If we don't do this, the available filters don't get refreshed properly.
          isComparatorClusters
          options={availableFilters}
          blurOnSelect
          value={activeFilters}
          onChange={(_, value) => {
            if (value) {
              setActiveFilters(value);
            }
          }}
          placeholder={activeFilters.length === 0 ? `Filter and search` : ""}
          showSearchIcon
        />
      </div>
      <div className="flex space-x-4 ml-4">
        <Toggle
          checked={showResolved}
          label="Show resolved"
          onChange={() => {
            setShowResolved((value) => !value);
          }}
        />
        <Toggle
          checked={showSilenced}
          label="Show marked as noise"
          onChange={() => {
            setShowSilenced((value) => !value);
          }}
        />
      </div>
    </div>
  );
};

export default AlertDashboardFilters;
