import { Drawer } from "@merge-api/merge-javascript-shared";
import { AdminAlert } from "../../../../../models/Entities";
import AlertDashboardDrawerTitlePrefix from "./AlertDashboardDrawerTitlePrefix";
import AlertDashboardDrawerTitlePostfix from "./AlertDashboardDrawerPostfix";
import { getSeverity, getStatus } from "../utils/helpers";
import AlertDashboardDrawerContent from "./AlertDashboardDrawerContent";
import { ALERT_CATEGORIES } from "../utils/constants";

interface AlertDashboardDrawerProps {
  alert: AdminAlert;
  category: ALERT_CATEGORIES;
  isDrawerOpen: boolean;
  setIsDrawerOpen: (val: boolean) => void;
}

const AlertDashboardDrawer = ({
  alert,
  category,
  isDrawerOpen,
  setIsDrawerOpen,
}: AlertDashboardDrawerProps) => {
  return (
    <>
      <Drawer
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
        }}
        title={" "} // TODO (Zach H) - This forces the previx and postfix to render.  Planning to update js-shared to remove this.
        titlePrefix={<AlertDashboardDrawerTitlePrefix category={category} alert={alert} />}
        titlePostfix={
          <AlertDashboardDrawerTitlePostfix
            severity={getSeverity(alert.severity_level)}
            status={getStatus(alert.resolved_at)}
          />
        }
        truncateLongTitle
        anchor="right"
        sizeVariant="lg"
      >
        <AlertDashboardDrawerContent alert={alert} category={category} />
      </Drawer>
    </>
  );
};

export default AlertDashboardDrawer;
