import CardHeader from "../../../shared/CardHeader";
import { Card } from "@merge-api/merge-javascript-shared";
import {
  LinkingFlowStepPathIntegrationBuilder,
  LinkingFlowStepPathTypeEnums,
} from "../../../utils/Entities";
import { convertQueryValueToEnumKey } from "../../utils/helpers";
import { useEffect } from "react";
import { AuthType } from "../../../../../models/Entities";
import AdvancedStepPathFields from "./AdvancedStepPathFields";
import TypeaheadHeader from "../../../shared/TypeaheadHeader";
import { Text } from "@merge-api/merge-javascript-shared";

interface ConfigureStepPathSectionProps {
  setRequestedStepPath: React.Dispatch<
    React.SetStateAction<LinkingFlowStepPathIntegrationBuilder | undefined>
  >;
  requestedStepPath: LinkingFlowStepPathIntegrationBuilder;
  authConfigType: AuthType | undefined;
}

const selectablePathTypeOptions = [
  LinkingFlowStepPathTypeEnums.PATH_TYPE_MANUAL_LINKING,
  LinkingFlowStepPathTypeEnums.PATH_TYPE_PARTNERSHIP,
];

const scraperPathTypeOptions = [
  LinkingFlowStepPathTypeEnums.PATH_TYPE_SCRAPER,
  LinkingFlowStepPathTypeEnums.PATH_TYPE_SCRAPER_MFA,
  LinkingFlowStepPathTypeEnums.PATH_TYPE_SCRAPER_SECURITY_QUESTION,
];

const ConfigureStepPathSection = ({
  setRequestedStepPath,
  requestedStepPath,
  authConfigType,
}: ConfigureStepPathSectionProps) => {
  const presetPathType = convertQueryValueToEnumKey("presetPathType", LinkingFlowStepPathTypeEnums);

  useEffect(() => {
    if (presetPathType) {
      setRequestedStepPath({
        ...requestedStepPath,
        path_type: presetPathType,
      });
    }
  }, [presetPathType]);

  return (
    <Card className="pt-4 px-5 pb-5 bg-white mb-6 min-w-fit space-y-6">
      <CardHeader title="Configure step path" />
      <TypeaheadHeader
        dataTestID="field-step-path-path-type"
        title="Path type"
        options={
          presetPathType || scraperPathTypeOptions.includes(requestedStepPath.path_type)
            ? Object.values(LinkingFlowStepPathTypeEnums)
            : selectablePathTypeOptions
        }
        getOptionLabel={(option: LinkingFlowStepPathTypeEnums) => option}
        value={presetPathType || requestedStepPath.path_type}
        onChange={(
          _: any,
          selectedOption: LinkingFlowStepPathTypeEnums | LinkingFlowStepPathTypeEnums[] | null
        ) => {
          if (typeof selectedOption === "string") {
            setRequestedStepPath({
              ...requestedStepPath,
              path_type: selectedOption || LinkingFlowStepPathTypeEnums.PATH_TYPE_MANUAL_LINKING,
            });
          }
        }}
        renderOption={(option: LinkingFlowStepPathTypeEnums) => <Text variant="md">{option}</Text>}
        disabled={!!presetPathType || scraperPathTypeOptions.includes(requestedStepPath.path_type)}
        disableClearable={true}
      />

      <AdvancedStepPathFields
        authConfigType={authConfigType}
        requestedStepPath={requestedStepPath}
        setRequestedStepPath={setRequestedStepPath}
      />
    </Card>
  );
};

export default ConfigureStepPathSection;
