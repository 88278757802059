import { displayNameForAPICategory } from "../../../../models/Helpers";
import { ALL_CATEGORIES } from "../hooks/useLoadPaginatedIntegrations";
import { APICategory } from "../../../../models/Entities";
import { Button, Text, TextField, Typeahead } from "@merge-api/merge-javascript-shared";
import { Plus, Search } from "lucide-react";
import { INTEGRATION_BUILDER_PATH } from "../../../../router/RouterUtils";

type IntegrationsDashboardFiltersProps = {
  category: APICategory | "all";
  searchTerm: string;
  setCategory: React.Dispatch<React.SetStateAction<APICategory | "all">>;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
};

const DROPDOWN_OPTIONS: ("all" | APICategory)[] = [ALL_CATEGORIES, ...Object.values(APICategory)];

const IntegrationsDashboardFilters = ({
  category,
  searchTerm,
  setCategory,
  setSearchTerm,
}: IntegrationsDashboardFiltersProps) => {
  // getOption Label
  const getOptionLabel = (option: APICategory | "all") => {
    return option === ALL_CATEGORIES ? "All categories" : displayNameForAPICategory(option);
  };

  // path to integration
  const createIntegration = () => {
    window.open(INTEGRATION_BUILDER_PATH, "_blank");
  };

  return (
    <div className="flex flex-row items-center">
      {/* Categories Dropdown */}
      <div className="w-[280px] mr-3">
        <Typeahead
          className="bg-white"
          options={DROPDOWN_OPTIONS}
          disableClearable={true}
          onChange={(_: any, value: APICategory | "all" | null) => {
            setCategory(value || "all");
          }}
          getOptionLabel={(option: APICategory | "all") => getOptionLabel(option)}
          renderOption={(option: APICategory | "all") => <Text>{getOptionLabel(option)}</Text>}
          value={category ?? null}
          placeholder="Select category..."
        />
      </div>
      {/* Search */}
      <TextField
        className="w-full bg-white mr-3"
        type="search"
        placeholder="Search integrations..."
        value={searchTerm}
        prefix={<Search className="ml-3" size={12} />}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Button size="md" leftIcon={<Plus size={16} />} onClick={createIntegration}>
        Integration
      </Button>
    </div>
  );
};

export default IntegrationsDashboardFilters;
