import { AdminAlert } from "../../../../../models/Entities";
import {
  ALERT_CATEGORIES,
  AlertSearchFilterKey,
  AlertSearchFilterValue,
  AlertSearchQueryParams,
  AlertStatus,
  SeverityLevel,
  AlertSearchFilterConfig,
  MetricRuleNamesDisplay,
  MetricRuleNames,
} from "./constants";
import {
  TypeaheadComparatorClusterOption,
  TypeaheadComparatorEnum,
} from "@merge-api/merge-javascript-shared/dist/designSystem/molecules/Typeahead/types/types";

export const calculateDeviation = (
  metricValue: number | undefined,
  baselineValue: number | undefined
): number | null => {
  if (typeof metricValue !== "number" || typeof baselineValue !== "number") {
    return null;
  }
  return metricValue - baselineValue;
};

export const getStatus = (resolvedAt: string | Date | undefined): AlertStatus => {
  return resolvedAt ? AlertStatus.RESOLVED : AlertStatus.ACTIVE;
};

export const getSeverity = (severity: string | undefined): SeverityLevel => {
  return severity
    ? SeverityLevel[severity.toUpperCase() as keyof typeof SeverityLevel] || SeverityLevel.LOW
    : SeverityLevel.LOW;
};

export const getMetricName = (metricName: string): string => {
  const metricEnumKey = metricName as MetricRuleNames;
  return MetricRuleNamesDisplay[metricEnumKey] || "Unknown Metric";
};

export const parseErrorCodes = (
  additionalInformation?: Record<string, any>
): Record<string, number> => {
  if (!additionalInformation) return {};

  try {
    if (
      additionalInformation.error_codes &&
      typeof additionalInformation.error_codes === "object"
    ) {
      return additionalInformation.error_codes as Record<string, number>;
    }
  } catch (error) {
    console.error("Error processing additional information:", error);
  }

  return {};
};

const constructFilter = (
  alerts: AdminAlert[] | undefined,
  keyName: AlertSearchFilterKey,
  valueName: AlertSearchFilterValue,
  label: string
): TypeaheadComparatorClusterOption => {
  const uniqueValues = new Map<string, string>();

  alerts?.forEach((alert) => {
    const key = alert[keyName];
    const value = alert[valueName];
    if (key && value) uniqueValues.set(key, value); // Use the key as the Map key
  });

  return {
    value: valueName,
    label,
    comparatorOptions: [TypeaheadComparatorEnum.CONTAINS_ANY],
    target: {
      targetOptions: Array.from(uniqueValues.entries()).map(([key, value]) => ({
        value,
        label: key,
      })),
    },
  };
};

export const constructCategorySpecificAlertFilters = (
  category: ALERT_CATEGORIES,
  alerts: AdminAlert[] | undefined
): TypeaheadComparatorClusterOption[] => {
  const alertCategories = Object.values(ALERT_CATEGORIES);
  const categoryIndex = alertCategories.indexOf(category);
  const filterCategories = alertCategories.slice(0, categoryIndex + 1);

  const filterConfigs: AlertSearchFilterConfig[] = [
    {
      category: ALERT_CATEGORIES.INTEGRATION,
      key: "integration_name",
      value: "integration_id",
      displayName: "Integration Name",
    },
    {
      category: ALERT_CATEGORIES.ORGANIZATION,
      key: "organization_name",
      value: "organization_id",
      displayName: "Organization Name",
    },
    {
      category: ALERT_CATEGORIES.LINKED_ACCOUNT,
      key: "linked_account_id",
      value: "linked_account_id",
      displayName: "Linked Account ID",
    },
  ];

  const blueprintFilter = constructFilter(alerts, "blueprint_id", "blueprint_id", "Blueprint ID");

  const categoryFilters = filterConfigs
    .filter((config) => filterCategories.includes(config.category))
    .map((config) => constructFilter(alerts, config.key, config.value, config.displayName));

  return [blueprintFilter, ...categoryFilters];
};

export const constructAlertQueryParamsFromSearchFilters = (
  searchFilters: TypeaheadComparatorClusterOption[],
  showResolved: boolean,
  showSilenced: boolean
): AlertSearchQueryParams => {
  const queryParams: AlertSearchQueryParams = {};
  searchFilters.forEach((filter) => {
    const filterKey = filter.value;
    const filterValues = filter.target?.targetOptions?.map((option) => option.value);

    if (filterKey && filterValues?.length) {
      queryParams[filterKey as keyof AlertSearchQueryParams] = filterValues.join(",") as any; // Comma separate if there are more than one.
    }
  });

  queryParams["show_resolved"] = showResolved;
  queryParams["show_silenced"] = showSilenced;

  return queryParams;
};
