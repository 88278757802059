import { Text, Tooltip } from "@merge-api/merge-javascript-shared";
import { Info } from "lucide-react";
import { AlertDashboardColumn } from "./utils/constants";

interface AlertDashboardColumnTitleProps {
  column: AlertDashboardColumn;
}

const AlertDashboardColumnTitle = ({ column }: AlertDashboardColumnTitleProps) => {
  const tooltips: Partial<Record<AlertDashboardColumn, string>> = {
    [AlertDashboardColumn.OVERALL]: "Success over Total for the Blueprint ID",
    [AlertDashboardColumn.CURRENT]: "Success over Total for the Blueprint ID and Linked Account",
    [AlertDashboardColumn.DEVIATION]: "Overall minus Current",
  };

  if (column === AlertDashboardColumn.ADDITIONAL_INFORMATION) {
    // No need to display this.
    return <th scope="col"></th>;
  }

  const renderColumnWithTooltip = (tooltip: string) => (
    <Tooltip title={tooltip}>
      <div className="flex items-center">
        <Text variant="h6" className="mr-1">
          {column}
        </Text>
        <Info size={16} className="align-middle" />
      </div>
    </Tooltip>
  );

  return (
    <th scope="col">
      {tooltips[column] ? (
        renderColumnWithTooltip(tooltips[column] as string)
      ) : (
        <Text variant="h6">{column}</Text>
      )}
    </th>
  );
};

export default AlertDashboardColumnTitle;
