import { Tab, Tabs, TabsList } from "@merge-api/merge-javascript-shared";
import { useHistory, useLocation } from "react-router-dom";
import {
  getAPIEndpointsPath,
  getAuthConfigTablePath,
  getBlueprintsPath,
  getIntegrationChangelogPath,
  getPublishRequestsPath,
  getReportTemplatesPath,
  getScrapersPath,
  getTestSuitesTablePath,
  getUIDTemplateCategoryPath,
} from "../../../router/RouterUtils";

const IntegrationTabs = ({
  integrationID,
  categories,
}: {
  integrationID: string;
  categories: string[] | undefined;
}) => {
  const location = useLocation();
  const history = useHistory();

  const baseTabData = [
    {
      label: "Blueprints",
      value: "blueprints",
      destination: () => getBlueprintsPath(integrationID),
    },
    {
      label: "UID",
      value: "uid",
      destination: () =>
        getUIDTemplateCategoryPath(
          integrationID,
          categories && categories.length > 0 ? categories[0] : ""
        ),
    },
    {
      label: "API Endpoints",
      value: "apiEndpoints",
      destination: () => getAPIEndpointsPath(integrationID),
    },
    {
      label: "Auth Configs",
      value: "authConfigs",
      destination: () => getAuthConfigTablePath(integrationID),
    },
    {
      label: "Report Templates",
      value: "reportTemplates",
      destination: () => getReportTemplatesPath(integrationID),
    },
    {
      label: "Scrapers",
      value: "scrapers",
      destination: () => getScrapersPath(integrationID),
    },
    {
      label: "Mapping Tests",
      value: "mappingTests",
      destination: () => getTestSuitesTablePath(integrationID),
    },
    {
      label: "Publish Requests",
      value: "publishRequests",
      destination: () => getPublishRequestsPath(integrationID),
    },
    {
      label: "Changelog",
      value: "changelog",
      destination: () => getIntegrationChangelogPath(integrationID),
    },
  ];

  const tabData = baseTabData;

  const findCurrentTab = () => {
    const matchingTab = tabData.find((tab) =>
      location.pathname.includes(tab.destination().slice(1))
    );
    return matchingTab ? matchingTab.value : "blueprints";
  };

  const onTabChange = (_: any, newValue: string | number | null) => {
    if (typeof newValue === "string") {
      const selectedTab = tabData.find((tab) => tab.value === newValue);
      if (selectedTab && selectedTab.destination) {
        history.push(selectedTab.destination());
      }
    }
  };

  return (
    <div className="w-full mt-4 mb-6">
      <div className="border-b-[1px] border-b-solid border-b-slate-10 flex-wrap">
        <Tabs value={findCurrentTab()} variant="underline" onChange={onTabChange}>
          <TabsList className="border-b-0">
            {tabData.map((tab) => (
              <Tab className="whitespace-nowrap" key={tab.value} value={tab.value}>
                {tab.label}
              </Tab>
            ))}
          </TabsList>
        </Tabs>
      </div>
    </div>
  );
};

export default IntegrationTabs;
