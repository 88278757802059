import React, { useState } from "react";
import { Button, ButtonVariant, Typeahead, Text } from "@merge-api/merge-javascript-shared";
import { MappingTestBlock, MappingTestRequestMock } from "../../../../models/MappingTests";
import AddMappingTestRequestMockModal from "./AddMappingTestRequestMockModal";
import MappingTestV2RequestMock from "./MappingTestV2RequestMock";
import { RequestMockFilterOptions } from "../../../../models/Entities";
import { Plus } from "lucide-react";

type Props = {
  requests: MappingTestRequestMock[];
  mappingTestBlocks: MappingTestBlock[];
  addRequestMock: (requestMock: MappingTestRequestMock) => void;
  editRequestMock: (requestMock: MappingTestRequestMock) => void;
  pasteRequestMock: (requestMock: MappingTestRequestMock) => void;
  deleteRequestMock: (requestMock: MappingTestRequestMock) => void;
  saveMappingTest: () => void;
};

const MappingTestV2RequestsContainer: React.FC<Props> = ({
  requests,
  addRequestMock,
  editRequestMock,
  pasteRequestMock,
  deleteRequestMock,
  mappingTestBlocks,
  saveMappingTest,
}) => {
  const [showAddRequestMockModal, setShowAddRequestMockModal] = useState<Boolean>(false);
  const [copiedRequestMock, setCopiedRequestMock] = useState<MappingTestRequestMock>();
  const [requestFilter, setRequestFilter] = useState("All requests");

  const filterRequests = (filter: string) => {
    switch (filter) {
      case RequestMockFilterOptions.ALL_REQUESTS:
        return requests;

      case RequestMockFilterOptions.USED_REQUESTS:
        return requests.filter((request) =>
          mappingTestBlocks.some((block) => {
            if (!block.ordered_blueprints_meta || block.ordered_blueprints_meta.length === 0) {
              return false;
            }

            return block.ordered_blueprints_meta.some((orderedBlueprintMeta) =>
              Object.values(orderedBlueprintMeta).some((mappingTestBlockBlueprintMeta) =>
                mappingTestBlockBlueprintMeta.request_mocks.includes(request.name || "")
              )
            );
          })
        );

      case RequestMockFilterOptions.UNUSED_REQUESTS:
        return requests.filter(
          (request) =>
            !mappingTestBlocks.some((block) => {
              if (!block.ordered_blueprints_meta || block.ordered_blueprints_meta.length === 0) {
                return false;
              }

              return block.ordered_blueprints_meta.some((orderedBlueprintMeta) =>
                Object.values(orderedBlueprintMeta).some((mappingTestBlockBlueprintMeta) =>
                  mappingTestBlockBlueprintMeta.request_mocks.includes(request.name || "")
                )
              );
            })
        );
    }

    return requests;
  };

  const filteredRequests: MappingTestRequestMock[] = filterRequests(requestFilter);

  return (
    <div className="flex flex-column items-center">
      <Typeahead
        className="mb-4"
        placeholder="Select a filter..."
        options={Object.values(RequestMockFilterOptions)}
        value={requestFilter ?? null}
        getOptionLabel={(option: string) => option}
        renderOption={(option: string) => <Text>{option}</Text>}
        onChange={(_, value) => {
          if (value) setRequestFilter(value);
        }}
        disableClearable={true}
      />
      {showAddRequestMockModal && (
        <AddMappingTestRequestMockModal
          setShowAddRequestMockModal={setShowAddRequestMockModal}
          onHide={() => setShowAddRequestMockModal(false)}
          onSubmit={addRequestMock}
        />
      )}
      {filteredRequests.map((requestMock) => (
        <MappingTestV2RequestMock
          key={requestMock.id}
          requestMock={requestMock}
          editable={true}
          editRequestMock={editRequestMock}
          deleteRequestMock={deleteRequestMock}
          setCopiedRequestMock={setCopiedRequestMock}
          saveMappingTest={saveMappingTest}
        />
      ))}
      <Button
        className="w-4/5"
        variant={ButtonVariant.TertiaryWhite}
        leftIcon={<Plus size={16} />}
        onClick={() => setShowAddRequestMockModal(true)}
      >
        New Request Mock
      </Button>
      <Button
        className="mt-4 w-4/5"
        variant={ButtonVariant.TertiaryWhite}
        onClick={() => pasteRequestMock(copiedRequestMock as MappingTestRequestMock)}
        disabled={!copiedRequestMock}
        leftIcon={<Plus size={16} />}
      >
        Paste Request Mock
      </Button>
    </div>
  );
};

export default MappingTestV2RequestsContainer;
