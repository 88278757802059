import { fetchWithAuth } from "../../../../api-client/api_client";
import { HTTPMethod } from "../../../../models/HTTPMethods";
import { showSuccessToast } from "../../../shared/Toasts";
import { OverrideRuleRequestInput } from "../types";

const useRuleOverrideActions = (integrationID: string) => {
  const createRuleOverride = (input: OverrideRuleRequestInput) => {
    fetchWithAuth({
      path: `/integrations/${integrationID}/integration-validator/override-rule`,
      method: HTTPMethod.POST,
      body: {
        ...input,
        is_disabled: true,
      },
      onResponse: () => {
        showSuccessToast(
          "Rule overridden successfully. It will no longer be enforced.",
          "top-right"
        );
      },
    });
  };

  const deleteRuleOverride = (input: OverrideRuleRequestInput) => {
    fetchWithAuth({
      path: `/integrations/${integrationID}/integration-validator/override-rule`,
      method: HTTPMethod.DELETE,
      body: input,
      onResponse: () => {
        showSuccessToast("Rule turned back on. It will now be enforced", "top-right");
      },
    });
  };

  return { createRuleOverride, deleteRuleOverride };
};

export default useRuleOverrideActions;
