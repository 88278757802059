export enum ComponentDiffType {
  ADDED = "ADDED",
  DELETED = "DELETED",
  UPDATED = "UPDATED",
}

export enum ValidatorRunStatusType {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  ABORTED = "ABORTED",
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
}

export type OptionalModelData = null | Record<string, string>;

export type OverrideRuleRequestInput = {
  component_id?: string;
  component_version_id?: string;
  rule_id: string;
  comment?: string;
  asana_url?: string;
};

export type StagedComponent = {
  old_model_data: OptionalModelData;
  new_model_data: OptionalModelData;
  component_id: string;
  component_version_id: string;
  name: string;
  diff_type: ComponentDiffType;
  comment: string | null;
};

export type ValidatedStagedComponent = StagedComponent & {
  component_type: string;
  validator_results: IntegrationValidatorRuleResult[];
};

export type PublishModuleInfo = {
  has_changes: boolean;
  can_publish: boolean;
  staged_changes: Record<string, StagedComponent[]>;
};

export type PublishModuleInfoV2 = {
  has_changes: boolean;
  can_publish: boolean;
  staged_changes: Record<string, ValidatedStagedComponent[]>;
};

export type AsyncPublishModuleInfo = {
  status: ValidatorRunStatusType;
  publish_module_summary: PublishModuleInfoV2;
};

export type CanaryTestInfo = {
  is_integration_in_canary_testing: boolean;
  num_available_las_for_canary_testing: number;
};

export type BlueprintAdvancedConfigurationsType = {
  reset_timestamps_on_publish: boolean;
};

export type PublishIntegrationVersionRequestBodyType = {
  ticket: string | undefined;
  description: string | undefined;
  blueprint_advanced_configurations:
    | Record<string, BlueprintAdvancedConfigurationsType>
    | undefined;
  override_ticket?: string;
  override_comment?: string;
};

export enum PublishModalScreenType {
  CONTEXT = "CONTEXT",
  CONFIRMATION = "CONFIRMATION",
  CONFIGURATION = "CONFIGURATION",
}

export enum BlueprintValidatorResultOptions {
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  FAILURE = "FAILURE",
}

export enum BlueprintValidatorRuleResultOptions {
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

export enum BlueprintValidatorRuleResultType {
  WARNING = "WARNING",
  BLOCKER = "BLOCKER",
}

export enum ValidatorRules {
  BPV_RULE_STALE_PARAMETERS = "BPV_RULE_STALE_PARAMETERS",
  BPV_RULE_UNIQUE_IDENTIFIERS_CHANGED = "BPV_RULE_UNIQUE_IDENTIFIERS_CHANGED",
  BPV_RULE_VALID_CUSTOM_FUNCTION_SYNTAX = "BPV_RULE_VALID_CUSTOM_FUNCTION_SYNTAX",
  BPV_RULE_MAPPING_TEST_COVERAGE = "BPV_RULE_MAPPING_TEST_COVERAGE",
  BPV_RULE_PAGINATION_SETUP = "BPV_RULE_PAGINATION_SETUP",
  BPV_RULE_INTEGRATION_CHECKLIST = "BPV_RULE_INTEGRATION_CHECKLIST",
  BPV_RULE_INITIALIZE_WRITTEN_COMMON_MODEL_IN_CREATE_BP = "BPV_RULE_INITIALIZE_WRITTEN_COMMON_MODEL_IN_CREATE_BP",
  BPV_RULE_N_PLUS_ONE_API_REQUEST = "BPV_RULE_N_PLUS_ONE_API_REQUEST",
}

export enum BlueprintValidatorRules {
  BPV_RULE_STALE_PARAMETERS = "Stale parameters",
  BPV_RULE_UNIQUE_IDENTIFIERS_CHANGED = "Unique indentifier",
  BPV_RULE_VALID_CUSTOM_FUNCTION_SYNTAX = "Custom function syntax",
  BPV_RULE_MAPPING_TEST_COVERAGE = "Mapping test coverage",
  BPV_RULE_PAGINATION_SETUP = "Pagination setup",
  BPV_RULE_INTEGRATION_CHECKLIST = "Integration Checklist",
}

export type BlueprintValidatorRuleResult = {
  result: BlueprintValidatorRuleResultOptions;
  result_type: BlueprintValidatorRuleResultType;
  rule: string;
  exception_message: string;
  additional_information: any;
  error_message: string;
};

export type BlueprintValidatorResult = {
  id: string;
  result: BlueprintValidatorResultOptions;
  blocking_rules: any;
  warning_rules: any;
  rule_results: Array<BlueprintValidatorRuleResult>;
};

export enum IntegrationValidatorRuleSeverityType {
  INFO = "INFO",
  WARNING = "WARNING",
  BLOCKING = "BLOCKING",
  MANDATORY = "MANDATORY",
}

export enum IntegrationValidatorRuleResultType {
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
}

export type IntegrationValidatorRuleOverride = {
  rule_id: string;
  component_version_id?: string;
  component_id?: string;
  integration_id?: string;
  is_disabled: boolean;
  skip_validation: boolean;
  user_id?: string;
  user_name?: string;
  user_email?: string;
  severity?: IntegrationValidatorRuleSeverityType;
  comment?: string;
  asana_url?: string;
};

export type IntegrationValidatorRuleResult = {
  rule_id: string;
  rule_name: string;
  component_version_id: string;
  component_id: string;
  integration_id: string;
  result: IntegrationValidatorRuleResultType;
  severity: IntegrationValidatorRuleSeverityType;
  exception_message?: string | undefined;
  additional_information?: object | undefined;
  is_overridden: boolean;
  is_new_failure: boolean;
  override: IntegrationValidatorRuleOverride | null;
};

export enum IntegrationComponentType {
  BLUEPRINT = "BLUEPRINT",
  SCRAPER = "SCRAPER",
  MAPPING_TEST = "MAPPING_TEST",
  API_ENDPOINT_PARAMETER = "API_ENDPOINT_PARAMETER",
  SELECTIVE_SYNC_FILTER_SCHEMA = "SELECTIVE_SYNC_FILTER_SCHEMA",
}

export enum IntegrationComponentNames {
  MAPPING_TEST = "MappingTest",
  BLUEPRINT = "Blueprint",
  SCRAPER = "Scraper",
  API_ENDPOINT_FILTER = "APIEndpointParameter",
  SELECTIVE_SYNC_FILTER_SCHEMA = "SelectiveSyncFilterSchema",
}

export const IntegrationComponentTypeToNameMap: Record<string, IntegrationComponentNames> = {
  [IntegrationComponentType.BLUEPRINT]: IntegrationComponentNames.BLUEPRINT,
  [IntegrationComponentType.SCRAPER]: IntegrationComponentNames.SCRAPER,
  [IntegrationComponentType.MAPPING_TEST]: IntegrationComponentNames.MAPPING_TEST,
  [IntegrationComponentType.API_ENDPOINT_PARAMETER]: IntegrationComponentNames.API_ENDPOINT_FILTER,
  [IntegrationComponentType.SELECTIVE_SYNC_FILTER_SCHEMA]:
    IntegrationComponentNames.SELECTIVE_SYNC_FILTER_SCHEMA,
};

export const mapDisplayIntegrationComponentName = {
  [IntegrationComponentNames.MAPPING_TEST]: "mapping test",
  [IntegrationComponentNames.BLUEPRINT]: "Blueprint",
  [IntegrationComponentNames.SCRAPER]: "Scraper",
  [IntegrationComponentNames.API_ENDPOINT_FILTER]: "API Endpoint Filter",
  [IntegrationComponentNames.SELECTIVE_SYNC_FILTER_SCHEMA]: "Selective Sync Filter Schema",
};

export type BlueprintVersionUniqueIdentifierFormatInformation = {
  unique_identifier_formats_by_blueprint_version_common_model_step_id: {
    // common model ID
    [key: string]: {
      // blueprint version ID
      [key: string]: {
        // step id: [fields used to construct uid]
        [key: string]: string[];
      };
    };
  };
  // Holds the names for the blueprint version IDs
  blueprint_version_id_to_name: { [key: string]: string };
};
