import { Form } from "react-bootstrap";
import { SmallTextMutedParagraph } from "../../shared/text/MergeText";
import useBlueprintContext from "../context/useBlueprintContext";
import {
  isPaginationTimestampAvailable,
  checkPaginationTimestampUsed,
} from "../utils/BlueprintEditorUtils";
import TypeaheadFormField from "./TypeaheadFormField";
interface Props {
  stepID: string;
}

const BlueprintEditorRightPanelDateRangeLoopForm = (props: Props) => {
  const { blueprint, selectedStep, setStepUsesPaginationTimestamp } = useBlueprintContext();

  const canTogglePaginationTimestamps = isPaginationTimestampAvailable(
    blueprint.operation_type,
    blueprint.trigger_type
  );
  const paginationTimestampUsed = checkPaginationTimestampUsed(selectedStep);

  const updateUsePaginationTimeStamps = () => {
    setStepUsesPaginationTimestamp(props.stepID, !checkPaginationTimestampUsed(selectedStep));
  };

  return (
    <form>
      {canTogglePaginationTimestamps && (
        <>
          <SmallTextMutedParagraph className="mt-3">
            Be careful when enabling this. This will disable modified at timestamps for any nested
            API Request Loops and take priority.
          </SmallTextMutedParagraph>
          <Form.Group className="mb-9">
            <Form.Check
              key={`use-pagination-timestamp-toggle-${props.stepID}-key`}
              id={`use-pagination-timestamp-toggle-${props.stepID}-id`}
              type="switch"
              label="Enable Pagination Timestamps"
              defaultChecked={paginationTimestampUsed}
              onChange={updateUsePaginationTimeStamps}
            />
          </Form.Group>
        </>
      )}
      <TypeaheadFormField
        title={"Start Date"}
        subtitle={
          "The first date to pull. Put the date in the format of timestamp taken in / returned by the integration."
        }
        valueKey={"start_date"}
        parameterType={"string"}
      />
      <TypeaheadFormField
        title={"End Date"}
        subtitle={
          "The last date to pull. Put the date in the format of timestamp taken in / returned by the integration. Leave blank if you want the end date to be today."
        }
        valueKey={"end_date"}
        parameterType={"string"}
      />
      <TypeaheadFormField
        title={"Interval"}
        subtitle={
          "The time interval to increment by. Must be lowercase and plural (e.g. days, minutes)."
        }
        valueKey={"interval"}
        parameterType={"string"}
      />
      <TypeaheadFormField
        title={"Increment"}
        subtitle={"The quantity of the time interval each loop iteration should increment by."}
        valueKey={"increment"}
        parameterType={"string"}
      />
    </form>
  );
};

export default BlueprintEditorRightPanelDateRangeLoopForm;
