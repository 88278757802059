import { APIEndpointParameter } from "../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_APIEndpointParameter";
import {
  VersionedComponentAPIActions,
  VersionedComponentAPIComponentKeys,
  VersionedComponentsAPIRequestData,
} from "../../../../versioned-components/types";
import { makeVersionedComponentsAPIRequest } from "../../../VersionedComponentsAPIClient";
import { convertExpandedPydanticModelToNativePydanticModel } from "../../utils";

interface CreateOrPatchAPIEndpointParameterProps {
  apiEndpointParameter?: APIEndpointParameter;
  integrationID: string;
  componentID?: string;
  onResponse: (data: any) => void;
  onError?: (data: any) => void;
}

const useCreateOrUpdateAPIEndpointParameter = () => {
  const API_ENDPOINT_FILTER_CLASS = VersionedComponentAPIComponentKeys.APIEndpointParameter;
  const component_key = API_ENDPOINT_FILTER_CLASS;

  const createAndStageAPIEndpointParameter = ({
    apiEndpointParameter,
    integrationID,
    onResponse,
  }: CreateOrPatchAPIEndpointParameterProps) => {
    if (!apiEndpointParameter) return;

    const pydanticNativeAPIEndpointParameter = convertExpandedPydanticModelToNativePydanticModel(
      apiEndpointParameter as APIEndpointParameter,
      API_ENDPOINT_FILTER_CLASS
    );

    const componentID = (pydanticNativeAPIEndpointParameter as APIEndpointParameter).id;

    const request_body: VersionedComponentsAPIRequestData = {
      component_key,
      component_id: componentID,
      component: pydanticNativeAPIEndpointParameter,
    };

    makeVersionedComponentsAPIRequest({
      integrationID,
      versioned_components_api_action: VersionedComponentAPIActions.CREATE_AND_STAGE_NEW_COMPONENT,
      successToastMessage: "Successfully created and staged API Endpoint Filter",
      request_body,
      onResponse,
    });
  };

  const patchAPIEndpointParameter = ({
    apiEndpointParameter,
    integrationID,
    onResponse,
  }: CreateOrPatchAPIEndpointParameterProps) => {
    const pydanticNativeAPIEndpointParameter = convertExpandedPydanticModelToNativePydanticModel(
      apiEndpointParameter as APIEndpointParameter,
      API_ENDPOINT_FILTER_CLASS
    );

    const componentID = (pydanticNativeAPIEndpointParameter as APIEndpointParameter).id;

    const request_body: VersionedComponentsAPIRequestData = {
      component_key,
      component_id: componentID,
      component: pydanticNativeAPIEndpointParameter,
    };

    makeVersionedComponentsAPIRequest({
      integrationID,
      versioned_components_api_action:
        VersionedComponentAPIActions.UNSTAGE_SAVE_DRAFT_AND_STAGE_COMPONENT,
      request_body,
      onResponse,
    });
  };

  const unstageAPIEndpointParameter = ({
    integrationID,
    componentID,
    onResponse,
    onError,
  }: CreateOrPatchAPIEndpointParameterProps) => {
    const request_body: VersionedComponentsAPIRequestData = {
      component_key,
      component_id: componentID,
    };

    makeVersionedComponentsAPIRequest({
      integrationID,
      versioned_components_api_action: VersionedComponentAPIActions.UNSTAGE_COMPONENT,
      request_body,
      successToastMessage: "Successfully unstaged APIEndpointParameter",
      onResponse,
      onError,
    });
  };

  return {
    createAndStageAPIEndpointParameter,
    patchAPIEndpointParameter,
    unstageAPIEndpointParameter,
  };
};

export default useCreateOrUpdateAPIEndpointParameter;
