import { useState } from "react";
import { CanaryTestInfo } from "../types";
import { fetchWithAuth } from "../../../../api-client/api_client";

interface Props {
  integrationID: string;
}

const useLoadCanaryTestInfo = ({ integrationID }: Props) => {
  const [canaryTestInfo, setCanaryTestInfo] = useState<CanaryTestInfo | undefined>(undefined);

  const fetchCanaryTestInfo = () => {
    fetchWithAuth({
      path: `/integrations/${integrationID}/canary-test-info`,
      method: "GET",
      onResponse: (data: CanaryTestInfo) => {
        setCanaryTestInfo(data);
      },
      onError: (error) => {
        console.error("Error fetching canary test info", error);
      },
    });
  };

  return {
    canaryTestInfo,
    setCanaryTestInfo,
    fetchCanaryTestInfo,
  };
};

export default useLoadCanaryTestInfo;
