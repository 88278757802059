import { Typeahead } from "@merge-api/merge-javascript-shared";
import { FilterOptionsState } from "@mui/base/useAutocomplete";
import { JSXElementConstructor, ReactElement, SyntheticEvent } from "react";
import HeaderBase from "./HeaderBase";

interface Props<TValue> {
  title: string;
  subtitle?: string;
  learnMoreText?: string;
  options: TValue[];
  onChange: (
    event:
      | SyntheticEvent<Element, Event>
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>
      | React.FocusEvent<Element, Element>
      | null,
    value: TValue | TValue[] | null
  ) => void;
  value?: TValue | null;
  disabled?: boolean;
  className?: string;
  getOptionLabel?: (option: TValue) => string;
  renderOption?: (option: TValue) => ReactElement<unknown, string | JSXElementConstructor<unknown>>;
  hasSource?: boolean;
  required?: boolean;
  filterOptions?: (options: TValue[], state: FilterOptionsState<TValue>) => TValue[];
  dataTestID?: string;
  disableClearable?: boolean;
}

function TypeaheadHeader<TValue>({
  title,
  subtitle,
  options,
  learnMoreText,
  renderOption,
  onChange,
  value,
  className,
  disabled = false,
  hasSource = false,
  required = false,
  disableClearable = false,
  filterOptions,
  getOptionLabel,
  dataTestID,
}: Props<TValue>) {
  return (
    <div className={className}>
      <HeaderBase
        title={title}
        subtitle={subtitle}
        hasSource={hasSource}
        required={required}
        dataTestID={dataTestID}
        learnMoreText={learnMoreText}
      />
      <Typeahead
        options={options}
        renderOption={renderOption}
        onChange={onChange}
        value={value}
        disabled={disabled}
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel}
        disableClearable={disableClearable}
      />
    </div>
  );
}

export default TypeaheadHeader;
