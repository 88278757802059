import {
  TypeaheadComparatorClusterOption,
  TypeaheadComparatorEnum,
} from "@merge-api/merge-javascript-shared/dist/designSystem/molecules/Typeahead/types/types";
import { firstLetterUpperCase } from "../../../../../utils";
import { AdminAlert } from "src/models/Entities";

export enum ALERT_CATEGORIES {
  INTEGRATION = "INTEGRATION",
  ORGANIZATION = "ORGANIZATION",
  LINKED_ACCOUNT = "LINKED_ACCOUNT",
}

export enum SeverityLevel {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  CRITICAL = "CRITICAL",
}

export enum AlertStatus {
  RESOLVED = "Resolved",
  ACTIVE = "Active",
}

export enum MetricRuleNames {
  BLUEPRINT_SUCCESS_RATE_ALERT = "BLUEPRINT_SUCCESS_RATE_ALERT",
}

export const MetricRuleNamesDisplay: Record<MetricRuleNames, string> = {
  [MetricRuleNames.BLUEPRINT_SUCCESS_RATE_ALERT]: "Success rate",
};

export enum AlertDashboardColumn {
  INTEGRATION = "Integration",
  DATE = "Date",
  STATUS = "Status",
  SEVERITY = "Severity",
  METRIC = "Metric",
  OVERALL = "Overall",
  CURRENT = "Current",
  DEVIATION = "Deviation",
  ORGANIZATION = "Organization",
  LINKED_ACCOUNT_ID = "Linked Account ID",
  ADDITIONAL_INFORMATION = "Additional Info",
}

// Each table has a different ordering due to some fields not being shared.
export const TABLE_TYPE_COLUMN_ORDERINGS = {
  [ALERT_CATEGORIES.INTEGRATION]: [
    AlertDashboardColumn.INTEGRATION,
    AlertDashboardColumn.DATE,
    AlertDashboardColumn.STATUS,
    AlertDashboardColumn.SEVERITY,
    AlertDashboardColumn.METRIC,
    AlertDashboardColumn.CURRENT,
    AlertDashboardColumn.ADDITIONAL_INFORMATION,
  ],
  [ALERT_CATEGORIES.ORGANIZATION]: [
    AlertDashboardColumn.ORGANIZATION,
    AlertDashboardColumn.DATE,
    AlertDashboardColumn.STATUS,
    AlertDashboardColumn.SEVERITY,
    AlertDashboardColumn.INTEGRATION,
    AlertDashboardColumn.METRIC,
    AlertDashboardColumn.OVERALL,
    AlertDashboardColumn.CURRENT,
    AlertDashboardColumn.DEVIATION,
    AlertDashboardColumn.ADDITIONAL_INFORMATION,
  ],
  [ALERT_CATEGORIES.LINKED_ACCOUNT]: [
    AlertDashboardColumn.LINKED_ACCOUNT_ID,
    AlertDashboardColumn.DATE,
    AlertDashboardColumn.STATUS,
    AlertDashboardColumn.SEVERITY,
    AlertDashboardColumn.INTEGRATION,
    AlertDashboardColumn.ORGANIZATION,
    AlertDashboardColumn.METRIC,
    AlertDashboardColumn.OVERALL,
    AlertDashboardColumn.CURRENT,
    AlertDashboardColumn.DEVIATION,
    AlertDashboardColumn.ADDITIONAL_INFORMATION,
  ],
};

export const AlertDashboardColumnTooltip: Partial<Record<AlertDashboardColumn, string>> = {
  [AlertDashboardColumn.OVERALL]: "Success over Total for the Blueprint ID",
  [AlertDashboardColumn.CURRENT]: "Success over Total for the Blueprint ID and Linked Account",
  [AlertDashboardColumn.DEVIATION]: "Overall minus Current",
};

export type AlertSearchFilterKey =
  | "integration_name"
  | "organization_name"
  | "linked_account_id"
  | "blueprint_id";
export type AlertSearchFilterValue =
  | "integration_id"
  | "organization_id"
  | "linked_account_id"
  | "blueprint_id";

export interface AlertSearchFilterConfig {
  category: ALERT_CATEGORIES;
  key: AlertSearchFilterKey;
  value: AlertSearchFilterValue;
  displayName: string;
}

export type AlertsData = {
  alerts: AdminAlert[] | undefined;
  hasNext: boolean;
  onNext: () => void;
  isLoading: boolean;
};

export type AlertSearchQueryParams = {
  id?: string;
  alert_level?: ALERT_CATEGORIES;
  severity_level?: string;
  linked_account_id?: string;
  integration_id?: string;
  organization_id?: string;
  blueprint_id?: string;
  show_resolved?: boolean;
  show_silenced?: boolean;
  metric_name?: string;
};

export const DEFAULT_SEVERITY_TYPEAHEAD_OPTION: TypeaheadComparatorClusterOption = {
  value: "severity_level",
  label: "Severity",
  comparatorOptions: [TypeaheadComparatorEnum.CONTAINS_ANY],
  target: {
    targetOptions: Object.values(SeverityLevel).map((level) => ({
      value: level,
      label: firstLetterUpperCase(level),
    })),
  },
};

export const DEFAULT_METRIC_NAME_TYPEAHEAD_OPTION: TypeaheadComparatorClusterOption = {
  value: "metric_name",
  label: "Metric rules",
  comparatorOptions: [TypeaheadComparatorEnum.CONTAINS_ANY],
  target: {
    targetOptions: Object.values(MetricRuleNames).map((rule) => ({
      value: rule,
      label: MetricRuleNamesDisplay[rule],
    })),
  },
};

export const availableConstantAlertFilters: TypeaheadComparatorClusterOption[] = [
  DEFAULT_SEVERITY_TYPEAHEAD_OPTION,
  DEFAULT_METRIC_NAME_TYPEAHEAD_OPTION,
];
