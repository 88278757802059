import { Button, Card, Tooltip } from "@merge-api/merge-javascript-shared";
import { usePublishModuleContext } from "./context/PublishModuleContext";
import PublishModulePublishModal from "./IntegrationStagedVersions/PublishModulePublishModal";
import { MergeFlagFeature, useMergeFlag } from "../../shared/hooks/useMergeFlag";
import clsx from "clsx";
import PublishModuleCanaryTestingPublishInfo from "./PublishModuleCanaryTestingPublishInfo";

const PublishModulePublishCard = () => {
  const {
    integrationID,
    publishModuleInfo,
    isPublishBlocked,
    isSaving,
    overrideTicket,
    overrideComment,
    isPublishModalOpen,
    setIsPublishModalOpen,
    onPublish,
  } = usePublishModuleContext();

  const { enabled: isCanaryTestingEnabled } = useMergeFlag({
    feature: MergeFlagFeature.MERGE_FLAG_ENABLE_CANARY_TESTING,
    isEnabledForUser: true,
  });

  return (
    <div>
      <Card className="p-4 divide-y divide-solid divide-gray-10">
        <div
          className={clsx(
            "flex flex-row items-center w-full justify-between",
            isCanaryTestingEnabled ? "pb-4" : ""
          )}
        >
          <div className="flex flex-row items-center space-x-2">
            <Tooltip
              title={
                isPublishBlocked
                  ? "Cannot publish. Blocking rules must be addressed or overridden and mapping tests must pass before publishing."
                  : ""
              }
            >
              <Button
                loading={isSaving}
                disabled={isSaving || isPublishBlocked}
                onClick={() => setIsPublishModalOpen(true)}
              >
                Publish staged versions
              </Button>
            </Tooltip>
          </div>
        </div>
        {isCanaryTestingEnabled && <PublishModuleCanaryTestingPublishInfo />}
      </Card>

      <>
        <PublishModulePublishModal
          open={isPublishModalOpen}
          onPublish={onPublish}
          onClose={() => setIsPublishModalOpen(false)}
          stagedChanges={publishModuleInfo?.staged_changes}
          integrationID={integrationID}
          overrideComment={overrideComment}
          overrideTicket={overrideTicket}
        />
      </>
    </div>
  );
};

export default PublishModulePublishCard;
