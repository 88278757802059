import { Text, Typeahead } from "@merge-api/merge-javascript-shared";

interface Props {
  title: string;
  placeholder?: string;
  onChange:
    | ((
        event:
          | React.MouseEvent<Element, MouseEvent>
          | React.KeyboardEvent<Element>
          | React.FocusEvent<Element, Element>
          | null,
        value: any
      ) => void)
    | undefined;
  value: any;
  options: any[];
  clearable?: boolean;
}

const LeftPanelSelect = ({
  title,
  placeholder,
  onChange,
  value,
  options,
  clearable = true,
}: Props) => {
  return (
    <div className="flex flex-col space-y-2 w-full">
      <Text variant="h6">{title}</Text>
      <Typeahead
        className="w-full"
        placeholder={placeholder}
        onChange={(
          event:
            | React.SyntheticEvent<Element, Event>
            | React.MouseEvent<Element, MouseEvent>
            | React.KeyboardEvent<Element>
            | React.FocusEvent<Element, Element>
            | null,
          selectedValue: any | null
        ) => {
          if (onChange) {
            onChange(
              event as
                | React.MouseEvent<Element, MouseEvent>
                | React.KeyboardEvent<Element>
                | React.FocusEvent<Element, Element>
                | null,
              selectedValue
            );
          }
        }}
        value={value ?? null}
        options={options}
        getOptionLabel={(option: any) => option.label || option.toString()}
        renderOption={(option: any) => <Text>{option.label || option.toString()}</Text>}
        disableClearable={!clearable}
      />
    </div>
  );
};

export default LeftPanelSelect;
