import { Card, Link, Text } from "@merge-api/merge-javascript-shared";
import { getBlueprintEditorPath } from "../../../../../router/RouterUtils";
import { ExternalLink } from "lucide-react";
import MergeCopyToClipboard from "../../../../../components/shared/MergeCopyToClipboard";

interface AlertDashboardDrawerRelatedBlueprintsProps {
  blueprintId: string;
  relatedBlueprints: string[];
  integrationId: string;
  blueprintName?: string;
}

const AlertDashboardDrawerRelatedBlueprints = ({
  blueprintId,
  relatedBlueprints,
  integrationId,
  blueprintName,
}: AlertDashboardDrawerRelatedBlueprintsProps) => {
  if (relatedBlueprints.length === 0) {
    return null;
  }

  return (
    <div className="mb-6">
      <Text variant="h4" className="mb-5">
        Related Blueprints
      </Text>
      <Card>
        {relatedBlueprints.map((versionId, index) => {
          return (
            <div key={index} className="border rounded-md p-6 flex justify-between items-center">
              <div className="flex items-center gap-2">
                <Link
                  variant="md"
                  href={getBlueprintEditorPath(integrationId, versionId)}
                  className="flex items-center gap-2"
                >
                  <Text variant="h6">{blueprintName || blueprintId}</Text>
                  <ExternalLink size={16} />
                </Link>
              </div>
              <Text variant="md">
                Version ID: {versionId} <MergeCopyToClipboard textToCopy={versionId} />
              </Text>
            </div>
          );
        })}
      </Card>
    </div>
  );
};

export default AlertDashboardDrawerRelatedBlueprints;
