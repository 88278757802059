import { Accordion, ButtonVariant, Menu, MenuItem, Text } from "@merge-api/merge-javascript-shared";
import { MoreHorizontal } from "lucide-react";
import TextFieldHeader from "../../../shared/TextFieldHeader";
import FieldOverridesFields from "./FieldOverridesFields";
import { AuthConfigIntegrationBuilder, LinkChoiceStepOption } from "../../../utils/Entities";
import React, { useState } from "react";
import clsx from "clsx";
import AdvancedOptionConfigurationFields from "./AdvancedOptionConfigurationFields";
import TypeaheadHeader from "../../../shared/TypeaheadHeader";

interface Props {
  index: number;
  authConfigs: AuthConfigIntegrationBuilder[] | undefined;
  requestedLinkChoiceStepOption: LinkChoiceStepOption;
  onCallbackDeleteLinkChoiceStepOption: (index: number) => void;
  onCallbackUpdateLinkChoiceStepOption: (index: number, keyValuePairs: Record<string, any>) => void;
}

const ConfigureOptionCard = ({
  index,
  authConfigs,
  requestedLinkChoiceStepOption,
  onCallbackDeleteLinkChoiceStepOption,
  onCallbackUpdateLinkChoiceStepOption,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <Accordion
      chevronSize={16}
      chevronOrientation="right"
      onChange={() => setIsExpanded(!isExpanded)}
      title={
        <div
          data-testid={`accordion-option-card-${index}`}
          className="flex flex-row items-center justify-between w-full pr-6"
        >
          <div className="flex flex-row items-center justify-start space-x-2">
            <div>
              <Text variant="h6">Option {(1 + index).toString()}</Text>
            </div>
            <div>
              <Text variant="lg">{requestedLinkChoiceStepOption.name}</Text>
            </div>
          </div>
          <div
            data-testid={`button-option-card-menu-${index}`}
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
              event.stopPropagation();
            }}
          >
            <Menu
              ButtonProps={{
                children: <MoreHorizontal size={16} className="text-gray-50" />,
                variant: ButtonVariant.IconShadowHidden,
                color: "transparent",
              }}
              menuPlacement="bottom-end"
            >
              <MenuItem onClick={() => onCallbackDeleteLinkChoiceStepOption(index)}>
                <div data-testid={`button-option-card-delete-step-${index}`}>
                  <Text className="text-red-50">Delete step</Text>
                </div>
              </MenuItem>
            </Menu>
          </div>
        </div>
      }
      titleClassName={clsx(
        "px-5 py-4 bg-gray-0 transition-all duration-500",
        isExpanded ? "rounded-t-md" : "rounded-md"
      )}
      variant={isExpanded ? "outline" : "none"}
    >
      <div className="px-5 py-4 space-y-6 border-t-[0.5px] border-gray-20">
        <TextFieldHeader
          dataTestID={`field-option-card-name-${index}`}
          title="Name"
          subtitle="End user facing name"
          value={requestedLinkChoiceStepOption.name}
          onChange={(event) =>
            onCallbackUpdateLinkChoiceStepOption(index, { name: event.target.value })
          }
        />
        <TypeaheadHeader
          dataTestID={`field-option-card-auth-config-override-${index}`}
          title="Authentication configuration override"
          options={(authConfigs || []).map((authConfig) => authConfig.name)}
          onChange={(_: any, selectedOption: string | string[] | null) => {
            let finalSelectedOption: string | null = null;

            if (Array.isArray(selectedOption)) {
              finalSelectedOption = selectedOption.length > 0 ? selectedOption[0] : null;
            } else {
              finalSelectedOption = selectedOption;
            }

            onCallbackUpdateLinkChoiceStepOption(index, {
              auth_configuration_override_id:
                authConfigs?.find((authConfig) => authConfig.name === finalSelectedOption)?.id ||
                null,
            });
          }}
          value={
            authConfigs?.find(
              (authConfig) =>
                authConfig.id === requestedLinkChoiceStepOption.auth_configuration_override_id
            )?.name || null
          }
        />

        <FieldOverridesFields
          indexStepOption={index}
          requestedLinkChoiceStepOption={requestedLinkChoiceStepOption}
          onCallbackUpdateLinkChoiceStepOption={onCallbackUpdateLinkChoiceStepOption}
        />
        <AdvancedOptionConfigurationFields
          indexStepOption={index}
          requestedLinkChoiceStepOption={requestedLinkChoiceStepOption}
          onCallbackUpdateLinkChoiceStepOption={onCallbackUpdateLinkChoiceStepOption}
        />
      </div>
    </Accordion>
  );
};

export default React.memo(ConfigureOptionCard);
