import { Text, Tooltip } from "@merge-api/merge-javascript-shared";
import { AdminAlert } from "../../../../models/Entities";
import SeverityCell from "./SeverityCell";
import IntegrationCell from "./IntegrationCell";
import StatusCell from "./StatusCell";
import CurrentDataCell from "./CurrentDataCell";
import MetricNameCell from "./MetricNameCell";
import MergeCopyToClipboard from "../../../../components/shared/MergeCopyToClipboard";
import { calculateDeviation, getSeverity, getStatus } from "./utils/helpers";
import { formatDate } from "../../../../components/shared/utils/SharedComponentUtils";
import { AlertDashboardColumn } from "./utils/constants";
import { ChevronRight } from "lucide-react";

type AlertDashboardTableCellProps = {
  column: string;
  alert: AdminAlert;
};

const AlertDashboardTableCell = ({ column, alert }: AlertDashboardTableCellProps) => {
  switch (column) {
    case AlertDashboardColumn.DATE:
      return <Text>{formatDate(alert?.alert_comparison_period_start, "MMM DD, YYYY", false)}</Text>;
    case AlertDashboardColumn.ORGANIZATION:
      return (
        <Tooltip title={alert?.organization_name}>
          <Text variant="h6">{alert?.organization_name}</Text>
        </Tooltip>
      );
    case AlertDashboardColumn.LINKED_ACCOUNT_ID:
      return (
        <Tooltip title={alert?.linked_account_id || ""}>
          <Text variant="h6">
            {alert?.linked_account_id}{" "}
            <MergeCopyToClipboard textToCopy={alert?.linked_account_id || ""} />
          </Text>
        </Tooltip>
      );
    case AlertDashboardColumn.INTEGRATION:
      return (
        <IntegrationCell
          integrationName={alert?.integration_name || ""}
          integrationSquareImageUrl={alert?.integration_square_image_url}
        />
      );
    case AlertDashboardColumn.STATUS:
      return <StatusCell status={getStatus(alert.resolved_at)}></StatusCell>;
    case AlertDashboardColumn.SEVERITY:
      return <SeverityCell severity={getSeverity(alert.severity_level)} />;
    case AlertDashboardColumn.METRIC:
      return <MetricNameCell metricName={alert.metric_name}></MetricNameCell>;
    case AlertDashboardColumn.OVERALL:
      return <Text>{alert.baseline_value}%</Text>;
    case AlertDashboardColumn.CURRENT:
      return (
        <CurrentDataCell
          currentData={alert?.metric_value}
          baselineData={alert?.baseline_value}
        ></CurrentDataCell>
      );
    case AlertDashboardColumn.DEVIATION:
      return <Text>{calculateDeviation(alert.metric_value, alert.baseline_value)}%</Text>;
    case AlertDashboardColumn.ADDITIONAL_INFORMATION:
      return <ChevronRight size={16} />;
    default:
      return <Text> WIP </Text>;
  }
};

export default AlertDashboardTableCell;
