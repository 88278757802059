import { Text } from "@merge-api/merge-javascript-shared";
import { getMetricName } from "../utils/helpers";
import MergeTable from "../../../../../components/shared/MergeTable";
import { AlertDashboardColumn, AlertDashboardColumnTooltip } from "../utils/constants";
import CurrentDataCell from "../CurrentDataCell";

interface AlertDashboardDrawerSuccessRateProps {
  current: number;
  overall: number;
  deviation: number | null;
  metricName: string;
}

interface HeaderCellProps {
  column: AlertDashboardColumn;
}

const HeaderCell = ({ column }: HeaderCellProps) => (
  <th>
    <Text variant="h6">{column}</Text>
    <Text variant="sm" className="text-gray-50">
      {AlertDashboardColumnTooltip[column]}
    </Text>
  </th>
);

const AlertDashboardDrawerSuccessRate = ({
  current,
  overall,
  deviation,
  metricName,
}: AlertDashboardDrawerSuccessRateProps) => {
  const metricRuleName = getMetricName(metricName) || "Unknown Metric";

  const columnOrder = [
    AlertDashboardColumn.OVERALL,
    AlertDashboardColumn.CURRENT,
    AlertDashboardColumn.DEVIATION,
  ];

  return (
    <div className="mb-6">
      <Text variant="h4" className="mb-5">
        {metricRuleName}
      </Text>
      <MergeTable
        hover
        hasMarginBottom={false}
        hasMorePaddingOnFirstElement
        header={
          <>
            {columnOrder.map((column) => (
              <HeaderCell key={column} column={column} />
            ))}
          </>
        }
        borderBottom="1px solid rgb(237, 242, 249)"
        content={
          <tr>
            <td>
              <Text variant="lg">{overall}%</Text>
            </td>
            <td>
              <CurrentDataCell currentData={current} baselineData={overall} />
            </td>
            <td>
              <Text variant="lg">{deviation}%</Text>
            </td>
          </tr>
        }
      />
    </div>
  );
};

export default AlertDashboardDrawerSuccessRate;
