import { format } from "date-fns";
import {
  AuthType,
  ResponseBodyAdditionalParsing,
  ResponseBodyAdditionalParsingRule,
} from "../../../models/Entities";
import { Row } from "../shared/postman-table/PostmanTableRow";
import {
  APIEndpointBodyParsingConfiguration,
  GeneralAuthType,
  LinkedAccountCredentialSelection,
  Section,
} from "./Entities";

export const getDisplayNameForCredentialSelection = (
  selection: LinkedAccountCredentialSelection
): string => {
  switch (selection) {
    case LinkedAccountCredentialSelection.API_KEY:
      return "API Key";
    case LinkedAccountCredentialSelection.SUBDOMAIN:
      return "Subdomain";
    case LinkedAccountCredentialSelection.CLIENT_ID:
      return "Client ID";
    case LinkedAccountCredentialSelection.CLIENT_SECRET:
      return "Client Secret";
    case LinkedAccountCredentialSelection.OTHER:
      return "Other";
    default:
      return "";
  }
};

export const mapAuthTypeToGeneralAuthType = (
  authType: AuthType | undefined
): GeneralAuthType | undefined => {
  switch (authType) {
    case AuthType.BASIC_AUTH:
    case AuthType.OAUTH2:
    case AuthType.OAUTH1:
    case AuthType.BASIC_AUTH_WITH_TOKEN_EXCHANGE:
      return GeneralAuthType.API;
    case AuthType.AUTH_TYPE_SCRAPER:
      return GeneralAuthType.SCRAPER;
    case AuthType.AUTH_TYPE_SFTP:
      return GeneralAuthType.SFTP;
    default:
      return undefined;
  }
};

// auth state mappin
export const authTypeMapping = {
  [GeneralAuthType.SCRAPER]: AuthType.AUTH_TYPE_SCRAPER,
  [GeneralAuthType.SFTP]: AuthType.AUTH_TYPE_SFTP,
  [GeneralAuthType.WEB_CONNECTOR]: AuthType.AUTH_TYPE_WEB_CONNECTOR,
  [GeneralAuthType.API]: undefined,
};

export const mapAuthTypeToOAuthStates = (
  authType: AuthType | undefined
): { isOAuth: boolean | undefined; isNonOAuthBasedTokenExchange: boolean | undefined } => {
  switch (authType) {
    case AuthType.OAUTH2:
      return { isOAuth: true, isNonOAuthBasedTokenExchange: false };
    case AuthType.BASIC_AUTH_WITH_TOKEN_EXCHANGE:
      return { isOAuth: false, isNonOAuthBasedTokenExchange: true };
    case AuthType.BASIC_AUTH:
      return { isOAuth: false, isNonOAuthBasedTokenExchange: false };
    case AuthType.OAUTH1:
      return { isOAuth: true, isNonOAuthBasedTokenExchange: false };
    default:
      // instantiate as undefined
      return { isOAuth: undefined, isNonOAuthBasedTokenExchange: undefined };
  }
};

// parsing strings into PostmanTable rows
export const parseStringToPostmanTableRows = (authString: string | undefined): Row[] => {
  let rows: Row[] = [];
  if (authString) {
    rows = authString.split(/, ?/).map((item) => {
      // authString.split(/, ?/) splits the string by either ", " or ","
      const itemParts = item.split(/: ?/); // item.split(/: ?/) splits each item by either ": " or ":"
      const key = itemParts[0];
      const value = itemParts.slice(1).join(":");
      return {
        active: true,
        key,
        value,
      };
    });
  }
  // ensure there's always a blank row at the end
  rows.push({
    active: true,
    key: "",
    value: "",
  });

  return rows;
};

// parsing dicts into PostmanTable rows
export const parsePropertiesDictToPostmanTableRows = (
  paramsDict: Object,
  withType: boolean = true,
  withEmptyRow: boolean = true
): Row[] => {
  let rows: Row[] = [];
  const propertiesDict = (paramsDict as any)["properties"];
  for (const key in propertiesDict) {
    const typeValue = propertiesDict[key]["type"] ?? propertiesDict[key];
    rows.push({
      active: true,
      key,
      value: withType ? typeValue : "",
    });
  }
  // ensure there's always a blank row at the end
  if (withEmptyRow) {
    rows.push({
      active: true,
      key: "",
      value: "",
    });
  }

  return rows;
};

// parsing dicts into PostmanTable rows
export const parseDictToPostmanTableRows = (dict: Object, withEmptyRow: boolean = true): Row[] => {
  let rows: Row[] = [];
  for (const key in dict) {
    const value = (dict as any)[key];
    rows.push({
      active: true,
      key,
      value,
    });
  }
  // ensure there's always a blank row at the end
  if (withEmptyRow) {
    rows.push({
      active: true,
      key: "",
      value: "",
    });
  }

  return rows;
};

export const parsePostmanTableRowsToPropertiesDict = (rows: Row[]): Record<string, any> => {
  if (rows.length === 0 || (rows.length === 1 && !rows[0].key)) {
    return {};
  }
  const properties: Record<string, any> = {};
  for (const row of rows) {
    // Skip rows where the key is empty, assuming these might be the empty rows added intentionally
    if (row.key) {
      properties[row.key] = { type: row.value };
    }
  }
  return { properties };
};

export const parsePostmanTableRowsToDict = (rows: Row[]): Record<string, any> => {
  const resultingDict: Record<string, any> = {};
  for (const row of rows) {
    // Skip rows where the key is empty, assuming these might be the empty rows added intentionally
    if (row.key) {
      resultingDict[row.key] = row.value;
    }
  }
  return resultingDict;
};

// Parse PostmanTable active, non-empty rows to dict
// Helpful for API tester, to mimic Blueprint behavior
export const parsePostmanTableActiveNonEmptyRowsToDict = (rows: Row[]): Record<string, any> => {
  const resultingDict: Record<string, any> = {};
  for (const row of rows) {
    if (row.key && row.active && row.value) {
      resultingDict[row.key] = row.value;
    }
  }
  return resultingDict;
};

// This function converts the parsing configs used in our pydantic model to the parsing rules used in the frontend
export const parseBodyParsingConfigurationsToParsingRules = (
  parsingConfigs: APIEndpointBodyParsingConfiguration[]
): ResponseBodyAdditionalParsing => {
  let additionalParsingRules: ResponseBodyAdditionalParsing = parsingConfigs.map((config) => ({
    path: config.key_path.join(","),
    parser: config.format,
  }));

  return additionalParsingRules;
};

// This function converts the parsing rules used in the frontend to the parsing configs used in our pydantic model
export const parseParsingRulesToBodyParsingConfigurations = (
  parsingRules: ResponseBodyAdditionalParsing
): APIEndpointBodyParsingConfiguration[] => {
  let parsingConfgs: APIEndpointBodyParsingConfiguration[] = parsingRules
    .filter((config: ResponseBodyAdditionalParsingRule) => config.path.trim() !== "")
    .map((config: ResponseBodyAdditionalParsingRule) => ({
      key_path: config.path.split(",").filter((part) => part.trim() !== ""),
      format: config.parser,
    }));

  return parsingConfgs;
};

// transforming PostmanTable rows into strings for auth config submission
export const formatPostmanRowsToString = (rows: Row[] | undefined): string | undefined => {
  if (!rows) {
    return undefined;
  }
  const filteredRows = rows.filter(
    (row) => row.active && (row.key?.trim() !== "" || row.value?.trim() !== "")
  );
  const resultString = filteredRows
    .map((row) => {
      const key = row.key?.trim() || "";
      const value = row.value?.trim() || "";
      if (key && value) {
        return `${key}: ${value}`;
      }
      return "";
    })
    .filter((part) => part !== "")
    .join(", ");

  // return undefined if the resulting string is empty, otherwise return the string
  return resultString !== "" ? resultString : undefined;
};

// transforms strings by removing or adding back https://
export const removeHttpsFromString = (url: string | undefined): string | undefined => {
  if (url !== undefined || url !== null) {
    return url?.replace(/^https?:\/\//, "");
  }
  return undefined;
};

export const addHttpsToString = (url: string | undefined): string | undefined => {
  if (url !== undefined) {
    return `https://${url}`;
  }
  return undefined;
};

// transforms array of sections into array of both sections and sub-sections
// used for IntegrationBuilder left-nav
export const flattenSections = (unformattedSections: Section[]) => {
  let flatSections: Section[] = [];
  unformattedSections.forEach((section) => {
    flatSections.push(section);
    if (section.subSections) {
      for (let index in section.subSections) {
        flatSections.push(section.subSections[index]);
      }
    }
  });
  return flatSections;
};

export const getLastStringOfUrlPath = (url: string) => {
  const urlItemsArray = url.split("/");
  return urlItemsArray[urlItemsArray.length - 1];
};

// Gets relative path, so that we can identify what page we're on in Integration Builder
export const getRelativePath = (path: string) => {
  return path.split("/").length > 3 ? path.split("/")[3] : "";
};

export const formatDate = (date: string, date_format: string = "yyyy-MM-dd HH:mm:ss") => {
  if (!date) {
    return "";
  }
  return format(new Date(date), date_format);
};

export const isValidURL = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

export const formatShorthandDate = (dateString: string) => {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  return date.toLocaleString("en-US", options);
};

export const getDuration = (start: string, end: string): string => {
  if (!start || !end) {
    return "-";
  }
  const startDate = new Date(start);
  const endDate = new Date(end);
  const diff = endDate.getTime() - startDate.getTime();

  const minutes = Math.floor(diff / (1000 * 60));
  const seconds = Math.floor((diff % (1000 * 60)) / 1000);

  return `${minutes}m${seconds}s`;
};

export const formatDateRange = (startDate: string | Date, endDate: string | Date): string => {
  // Cases:
  // Input: "2024-11-30T00:00:00Z", "2025-01-02T00:00:00Z"
  // Output: "Nov 30, 2024 - Jan 2, 2025"

  // Input: "2024-11-12T00:00:00Z", "2024-12-15T00:00:00Z"
  // Output: "Nov 12-Dec 15, 2024"

  // Input: "2024-11-12T00:00:00Z", "2024-11-14T00:00:00Z"
  // Output: "Nov 12-14, 2024"
  const start = typeof startDate === "string" ? new Date(startDate) : startDate;
  const end = typeof endDate === "string" ? new Date(endDate) : endDate;
  const startMonth = start.toLocaleString("default", { month: "short" });
  const startDay = start.getDate();
  const startYear = start.getFullYear();

  const endMonth = end.toLocaleString("default", { month: "short" });
  const endDay = end.getDate();
  const endYear = end.getFullYear();

  if (startYear === endYear) {
    if (startMonth === endMonth) {
      return `${startMonth} ${startDay}-${endDay}, ${startYear}`;
    } else {
      return `${startMonth} ${startDay}-${endMonth} ${endDay}, ${startYear}`;
    }
  } else {
    return `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;
  }
};
