import { snakeCaseToFirstLetterUpperCase } from "../../../../utils";
import AlertDashboardFilters from "./AlertDashboardFilters";
import { ALERT_CATEGORIES, AlertsData, availableConstantAlertFilters } from "./utils/constants";
import { TypeaheadComparatorClusterOption } from "@merge-api/merge-javascript-shared/dist/designSystem/molecules/Typeahead/types/types";
import { constructCategorySpecificAlertFilters } from "./utils/helpers";
import { Dispatch, SetStateAction, useMemo } from "react";

interface AlertDashboardHeaderProps {
  category: ALERT_CATEGORIES;
  alertsData: AlertsData;
  searchFilters: TypeaheadComparatorClusterOption[];
  setSearchFilters: (filters: TypeaheadComparatorClusterOption[]) => void;
  showResolved: boolean;
  setShowResolved: Dispatch<SetStateAction<boolean>>;
  showSilenced: boolean;
  setShowSilenced: Dispatch<SetStateAction<boolean>>;
}

const AlertDashboardHeader = ({
  category,
  alertsData,
  searchFilters,
  setSearchFilters,
  showResolved,
  setShowResolved,
  showSilenced,
  setShowSilenced,
}: AlertDashboardHeaderProps) => {
  const availableSearchFilters = useMemo(() => {
    return [
      ...constructCategorySpecificAlertFilters(category, alertsData?.alerts),
      ...availableConstantAlertFilters,
    ];
  }, [category, alertsData.alerts]);
  return (
    <>
      <div className="my-4 bg-gray-20 h-[0.5px]" />
      <h3 className="mt-10">{snakeCaseToFirstLetterUpperCase(category)}</h3>
      <br />
      <AlertDashboardFilters
        activeFilters={searchFilters}
        setActiveFilters={setSearchFilters}
        availableFilters={availableSearchFilters}
        showResolved={showResolved}
        setShowResolved={setShowResolved}
        showSilenced={showSilenced}
        setShowSilenced={setShowSilenced}
      />
      <br />
    </>
  );
};

export default AlertDashboardHeader;
