import { AlertStatus, SeverityLevel } from "../utils/constants";
import SeverityCell from "../SeverityCell";
import StatusCell from "../StatusCell";

interface AlertDashboardDrawerTitlePostfixProps {
  severity: SeverityLevel;
  status: AlertStatus;
}

const AlertDashboardDrawerTitlePostfix = ({
  severity,
  status,
}: AlertDashboardDrawerTitlePostfixProps) => {
  return (
    <div className="flex space-x-2">
      <SeverityCell severity={severity} />
      <StatusCell status={status} />
    </div>
  );
};

export default AlertDashboardDrawerTitlePostfix;
