import { Dialog, Text } from "@merge-api/merge-javascript-shared";
import {
  ComponentDiffType,
  IntegrationComponentNames,
  StagedComponent,
  mapDisplayIntegrationComponentName,
} from "../types";
import { unstageMappingTest } from "../../../mapping-tests/utils/MappingTestFetchUtils";
import { showErrorToast, showSuccessToast } from "../../../shared/Toasts";
import { unstageBlueprintVersion } from "../../../blueprint-editor/utils/BlueprintEditorAPIClient";
import { useState } from "react";
import { unstageScraper } from "../../../scraper/utils/ScraperAPIClient";
import useCreateOrUpdateSelectiveSyncFilterSchema from "../../../integration-builder/selective-sync-filters/useCreateOrUpdateSelectiveSyncFilterSchema";
import useCreateOrUpdateAPIEndpointParameter from "../../../integration-builder/selective-sync-filters/components/api-endpoint-parameter/useCreateOrUpdateAPIEndpointParameter";

interface Props {
  open: boolean;
  onClose: () => void;
  modelClassName: IntegrationComponentNames;
  stagedComponent: StagedComponent;
  onRefreshAllChecks: () => void;
  integrationID: string;
}

const UnstageModal = ({
  open,
  onClose,
  modelClassName,
  stagedComponent,
  onRefreshAllChecks,
  integrationID,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const displayModelClassName = mapDisplayIntegrationComponentName[modelClassName];
  const {
    unstageSelectiveSyncFilterSchema,
    restoreSelectiveSyncFilterSchema,
  } = useCreateOrUpdateSelectiveSyncFilterSchema();

  const { unstageAPIEndpointParameter } = useCreateOrUpdateAPIEndpointParameter();

  const handleUnstaging = () => {
    switch (modelClassName) {
      case IntegrationComponentNames.MAPPING_TEST:
        setIsLoading(true);
        unstageMappingTest({
          mappingTestID: stagedComponent.component_id,
          onResponse: () => {
            setIsLoading(false);
            showSuccessToast("Successfully unstaged.");
            onClose();
            onRefreshAllChecks();
          },
          onError: () => {
            setIsLoading(false);
            showErrorToast("Failed to unstage.");
            onClose();
          },
        });
        break;
      case IntegrationComponentNames.BLUEPRINT:
        setIsLoading(true);
        unstageBlueprintVersion({
          versionID: stagedComponent.component_version_id,
          onSuccess: () => {
            setIsLoading(false);
            showSuccessToast("Successfully unstaged.");
            onClose();
            onRefreshAllChecks();
          },
          onError: () => {
            setIsLoading(false);
            showErrorToast("Failed to unstage.");
            onClose();
          },
        });
        break;
      case IntegrationComponentNames.SCRAPER:
        setIsLoading(true);
        unstageScraper({
          scraperID: stagedComponent.component_id,
          onSuccess: () => {
            setIsLoading(false);
            showSuccessToast("Successfully unstaged.");
            onClose();
            onRefreshAllChecks();
          },
        });
        break;
      case IntegrationComponentNames.API_ENDPOINT_FILTER:
        setIsLoading(true);
        unstageAPIEndpointParameter({
          integrationID: integrationID,
          componentID: stagedComponent.component_id,
          onResponse: () => {
            setIsLoading(false);
            showSuccessToast("Successfully unstaged.");
            onClose();
            onRefreshAllChecks();
          },
          onError: () => {
            setIsLoading(false);
            showErrorToast("Failed to unstage APIEndpointParams.");
            onClose();
          },
        });
        break;
      case IntegrationComponentNames.SELECTIVE_SYNC_FILTER_SCHEMA:
        setIsLoading(true);
        if (stagedComponent.diff_type === ComponentDiffType.DELETED) {
          restoreSelectiveSyncFilterSchema({
            integrationID: integrationID,
            componentID: stagedComponent.component_id,
            onResponse: () => {
              setIsLoading(false);
              showSuccessToast("Successfully restored.");
              onClose();
              onRefreshAllChecks();
            },
            onError: () => {
              setIsLoading(false);
              showErrorToast("Failed to restore.");
              onClose();
            },
          });
        } else {
          unstageSelectiveSyncFilterSchema({
            integrationID: integrationID,
            componentID: stagedComponent.component_id,
            onResponse: () => {
              setIsLoading(false);
              showSuccessToast("Successfully unstaged.");
              onClose();
              onRefreshAllChecks();
            },
            onError: () => {
              setIsLoading(false);
              showErrorToast("Failed to unstage.");
              onClose();
            },
          });
        }
        break;
      default:
        showErrorToast(
          "Failed to unstage. Did not find model class. Please reach out to engineering."
        );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={"Unstage " + displayModelClassName}
      primaryButtonText="Unstage"
      primaryButtonLoading={isLoading}
      onPrimaryButtonClick={handleUnstaging}
      variant="md"
      children={
        <Text>
          Are you sure you want to unstage {displayModelClassName} <b>{stagedComponent.name}</b>?
        </Text>
      }
    />
  );
};

export default UnstageModal;
