import { Badge, Text, Card } from "@merge-api/merge-javascript-shared";

interface AlertDashboardDrawerErrorCodeDataProps {
  errorCodeCounts: Record<string, number>;
}

const AlertDashboardDrawerErrorCodeData = ({
  errorCodeCounts,
}: AlertDashboardDrawerErrorCodeDataProps) => {
  return (
    <div className="mb-6">
      <Text variant="h4" className="mb-5">
        Error codes
      </Text>
      <div className="grid grid-cols-1 gap-4">
        {Object.entries(errorCodeCounts).map(([code, count]) => (
          <Card className="border rounded-md p-4 flex justify-between items-center">
            <div className="flex flex-col gap-1 w-full">
              <Badge size="lg" color="yellow" className="self-start">
                <Text variant="h6">{code}</Text>
              </Badge>
              <Text variant="sm" className="text-gray-50">
                Count: {count}
              </Text>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default AlertDashboardDrawerErrorCodeData;
