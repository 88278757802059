import SpinnerButton from "../../shared/SpinnerButton";
import { showErrorToast } from "../../shared/Toasts";
import { AuthType } from "../../../models/Entities";

import {
  updateAuthenticationConfiguration,
  createTestAuthConfiguration,
  deleteTestAuthConfiguration,
} from "../utils/IntegrationsAPIClient";

export type AuthConfigUpdateProps = {
  id?: string;
  name?: string | undefined;
  authType: AuthType;
  additionalHeaderFields?: string | undefined;
  headerFormatForBasicAuth?: string | undefined;
  additionalAuthFields?: Array<string> | undefined;
  serviceAccountFieldKeys?: Array<string> | undefined;
  basicAuthKeyTokenExchangeRequestDataConvertToString?: boolean | undefined;
  tokenExchangeURL?: string | undefined;
  headerFormatForTokenExchange?: string | undefined;
  requestDataFormat?: string | undefined;
  pathToAuthKey?: Array<string> | undefined;
  lifespanOfToken?: string | undefined | null;
  lifespanKeyPath?: Array<string> | undefined;
  OAuthClientID?: string | undefined;
  OAuthClientSecret?: string | undefined;
  OAuthAuthorizeURL?: string | undefined;
  parametersToExclude?: Array<string> | undefined;
  OAuthGrantType?: string | undefined;
  OAuthClientCredentialSource?: string | undefined;
  OAuthParamsScopes?: string | undefined;
  additionalQueryParameters?: Array<string> | undefined;
  OAuthTokenURL?: string | undefined;
  OAuthRefreshURL?: string | undefined;
  OAuthTokenURLParamsLocation?: string | undefined;
  OAuthTokenURLFormat?: string | undefined;
  OAuthAccessTokenExpirationKeyPath?: Array<string> | undefined;
  OAuthResponseTokenKeyPath?: Array<string> | undefined;
  OAuthAccessTokenLifespan?: string | undefined | null;
  OAuthRefreshTokenLifespan?: string | undefined;
  OAuthRefreshFieldsToExclude?: Array<string> | undefined;
  OAuthRequestFieldsToExclude?: Array<string> | undefined;
  OAuthTokenURLHeaderKeysToExclude?: Array<string> | undefined;
  OAuthShouldRefreshAccessToken?: boolean | undefined;
  OAuthShouldFetchAccessToken?: boolean | undefined;
  OAuthUsePKCE?: boolean | undefined;
  integrationID: string;
  integrationCategory: string | undefined;
};

export enum ParamsLocation {
  query_params = "QUERY_PARAMS",
  body_params = "BODY_PARAMS",
}

export const QUERY_PARAMS_LOCATION_CHOICES = [
  { name: "query_params", id: ParamsLocation.query_params },
  { name: "body_params", id: ParamsLocation.body_params },
];

export enum GrantTypes {
  authorization_code = "AUTHORIZATION_CODE",
  client_credentials = "CLIENT_CREDENTIALS",
  jwt_bearer = "JWT_BEARER",
}

export enum PgpKeys {
  PRIMARY = "primary",
  RSA3072 = "rsa3072",
}

export const GRANT_TYPE_CHOICES = [
  { name: "authorization_code", id: GrantTypes.authorization_code },
  { name: "client_credentials", id: GrantTypes.client_credentials },
  { name: "jwt_bearer", id: GrantTypes.jwt_bearer },
];

export enum ClientCredentialSource {
  customer = "CUSTOMER",
  merge = "MERGE",
}

export const CLIENT_CREDENTIAL_SOURCE_CHOICES = [
  { name: "customer", id: ClientCredentialSource.customer },
  { name: "merge", id: ClientCredentialSource.merge },
];

const callUpdateAuthConfig = (
  authConfigUpdates: AuthConfigUpdateProps,
  onSuccess: () => void,
  setLoading: (isLoading: boolean) => void
) => {
  updateAuthenticationConfiguration({
    authConfigUpdates,
    onSuccess,
    onError: () => {
      showErrorToast("Save failed.");
      setLoading(false);
    },
  });
};

type ButtonProps = {
  text: string;
  onSuccess: () => void;
  setLoading: (isLoading: boolean) => void;
  isLoading: boolean;
  authConfigUpdateProps: AuthConfigUpdateProps;
};

export const callCreateTestAuthConfig = (
  authConfigID: string,
  authConfigUpdates: AuthConfigUpdateProps,
  linkToken: string,
  onSuccess: (data: any) => void,
  onError: () => void
) => {
  createTestAuthConfiguration({
    authConfigID,
    authConfigUpdates,
    linkToken,
    onSuccess,
    onError: () => {
      showErrorToast("Test failed.");
      onError();
    },
  });
};

export const callDeleteTestAuthConfig = (
  integrationID: string,
  testAuthConfigID: string,
  onSuccess: (data: any) => void,
  onError: () => void
) => {
  deleteTestAuthConfiguration({
    testAuthConfigID,
    integrationID,
    onSuccess,
    onError,
  });
};

export function SaveAuthConfigButton(props: ButtonProps) {
  const { text, onSuccess } = props;
  return (
    <SpinnerButton
      text={text}
      className="btn-block btn-primary"
      isLoading={props.isLoading}
      onClick={() => {
        props.setLoading(true);
        // Raise warning for editing existing paths
        callUpdateAuthConfig(props.authConfigUpdateProps, onSuccess, props.setLoading);
      }}
    />
  );
}
