import { Text } from "@merge-api/merge-javascript-shared";
import { getMetricName } from "./utils/helpers";

interface MetricNameCellProps {
  metricName: string;
}

const MetricNameCell = ({ metricName }: MetricNameCellProps) => {
  const metricRuleName = getMetricName(metricName) || "Unknown Metric";

  return <Text> {metricRuleName} </Text>;
};

export default MetricNameCell;
