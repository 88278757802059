import ClickableText from "../../../shared/ClickableText";
import TextFieldHeader from "../../../shared/TextFieldHeader";
import TypeaheadHeader from "../../../shared/TypeaheadHeader";
import {
  LinkChoiceStepOptionFormFieldValue,
  LinkChoiceStepOptionFormFieldValueEnums,
  LinkChoiceStepOption,
  LinkChoiceStepOptionFormFieldDisplayNameMap,
  LinkChoiceStepOptionFormFieldDisplayNameReverseMap,
} from "../../../utils/Entities";
import { deleteFormFieldValue, updateFormFieldValue } from "../../utils/helpers";
import { Text } from "@merge-api/merge-javascript-shared";

interface Props {
  indexFormFieldValue: number;
  formFieldValue: LinkChoiceStepOptionFormFieldValue;
  indexStepOption: number;
  requestedLinkChoiceStepOption: LinkChoiceStepOption;
  onCallbackUpdateLinkChoiceStepOption: (index: number, keyValuePairs: Record<string, any>) => void;
}

const FieldOverrideFieldsCard = ({
  indexFormFieldValue,
  formFieldValue,
  indexStepOption,
  requestedLinkChoiceStepOption,
  onCallbackUpdateLinkChoiceStepOption,
}: Props) => {
  return (
    <div className="flex flex-column border-t-[0.5px] border-gray-20 px-5 pt-4 pb-3">
      <div className="pb-5">
        <TypeaheadHeader
          dataTestID={`field-option-card-override-field-${indexStepOption}-${indexFormFieldValue}`}
          title="Override field"
          subtitle="Select one of the following fields to override"
          options={Object.values(LinkChoiceStepOptionFormFieldValueEnums)?.map(
            (formFieldValueEnum) => LinkChoiceStepOptionFormFieldDisplayNameMap[formFieldValueEnum]
          )}
          getOptionLabel={(option: string) => option}
          value={LinkChoiceStepOptionFormFieldDisplayNameMap[formFieldValue.field_name]}
          onChange={(_: any, selectedOption: string | string[] | null) => {
            if (typeof selectedOption === "string") {
              updateFormFieldValue(
                onCallbackUpdateLinkChoiceStepOption,
                requestedLinkChoiceStepOption,
                indexStepOption,
                indexFormFieldValue,
                {
                  field_name: LinkChoiceStepOptionFormFieldDisplayNameReverseMap[selectedOption],
                }
              );
            }
          }}
          renderOption={(option: string) => <Text variant="md">{option}</Text>}
          disableClearable={true}
        />
      </div>
      <div className="pb-3">
        <TextFieldHeader
          dataTestID={`field-option-card-override-value-${indexStepOption}-${indexFormFieldValue}`}
          title="Override value"
          subtitle="Override value for the selected field"
          value={formFieldValue.field_value}
          onChange={(event) => {
            updateFormFieldValue(
              onCallbackUpdateLinkChoiceStepOption,
              requestedLinkChoiceStepOption,
              indexStepOption,
              indexFormFieldValue,
              {
                field_value: event.target.value,
              }
            );
          }}
        />
      </div>
      <ClickableText
        dataTestID={`button-option-card-delete-override-${indexStepOption}-${indexFormFieldValue}`}
        text="Delete override"
        color="red"
        hasArrow={false}
        onClick={() =>
          deleteFormFieldValue(
            onCallbackUpdateLinkChoiceStepOption,
            requestedLinkChoiceStepOption,
            indexStepOption,
            indexFormFieldValue
          )
        }
      />
    </div>
  );
};

export default FieldOverrideFieldsCard;
