import { Dispatch, SetStateAction, useState } from "react";
import { fetchWithAuth } from "../../../../api-client/api_client";
import { AsyncPublishModuleInfo, PublishModuleInfoV2, ValidatorRunStatusType } from "../types";

interface Props {
  integrationID: string;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isAsyncValidatorEnabled: boolean | undefined;
  setValidatorRunStatus: Dispatch<SetStateAction<ValidatorRunStatusType | undefined>>;
}

const useLoadValidatedStagedVersions = ({
  integrationID,
  setIsLoading,
  isAsyncValidatorEnabled,
  setValidatorRunStatus,
}: Props) => {
  const [publishModuleInfoV2, setPublishModuleInfoV2] = useState<PublishModuleInfoV2 | undefined>(
    undefined
  );

  const fetchValidatedStagedVersions = (shouldIgnoreLoadingState: boolean = false) => {
    if (!shouldIgnoreLoadingState) {
      setIsLoading(true);
    }
    if (isAsyncValidatorEnabled) {
      fetchWithAuth({
        path: `/integrations/versioning/${integrationID}/staged-version-with-async-validator-results`,
        method: "GET",
        onResponse: (data: AsyncPublishModuleInfo) => {
          if (data) {
            setValidatorRunStatus(data.status);
            setPublishModuleInfoV2(data.publish_module_summary);
          } else {
            setPublishModuleInfoV2(undefined);
            setValidatorRunStatus(undefined);
          }
          setIsLoading(false);
        },
        onError: (error) => {
          setIsLoading(false);
          console.error("Error fetching async validated staged versions", error);
        },
      });
    } else {
      fetchWithAuth({
        path: `/integrations/versioning/${integrationID}/staged-version-with-validator-results`,
        method: "GET",
        onResponse: (data: PublishModuleInfoV2) => {
          setIsLoading(false);
          setPublishModuleInfoV2(data);
        },
        onError: (error) => {
          setIsLoading(false);
          console.error("Error fetching validated staged versions", error);
        },
      });
    }
  };

  return {
    publishModuleInfoV2,
    setPublishModuleInfoV2,
    fetchValidatedStagedVersions,
  };
};

export default useLoadValidatedStagedVersions;
