import {
  BlueprintOperationType,
  BlueprintTrigger,
  BlueprintTriggerType,
} from "../../../models/Blueprints";
import useBlueprintContext from "../context/useBlueprintContext";
import {
  getTriggerFrequencyString,
  isBlueprintWriteOperation,
} from "../utils/BlueprintEditorUtils";
import StepCard from "../../shared/StepCard";
import classNames from "classnames";

interface Props {
  trigger: BlueprintTrigger;
  operationType: BlueprintOperationType;
  triggerType: BlueprintTriggerType | undefined;
}

const BlueprintTriggerCard = ({ trigger, operationType, triggerType }: Props) => {
  const { selectedStep, setSelectedStep } = useBlueprintContext();
  const { schedule_interval_value, schedule_interval_unit } = trigger.intermediate_trigger_schedule;

  const isWrite = isBlueprintWriteOperation(operationType);

  const isHighlighted = selectedStep !== undefined && "slow_trigger_schedule" in selectedStep;
  const cardClass = classNames(
    isHighlighted ? "step-card-highlighted" : "step-card-not-highlighted"
  );

  let subTitle = "";
  if (isWrite) {
    subTitle = "Triggered by incoming API request";
  } else {
    switch (operationType) {
      case BlueprintOperationType.WEBHOOK_SETUP:
      case BlueprintOperationType.FETCH:
        if (triggerType === BlueprintTriggerType.REPORT_RECEIVED)
          subTitle = "Triggered by report upload";
        else if (triggerType === BlueprintTriggerType.WEBHOOK)
          subTitle = "Triggered by incoming Webhook";
        else if (triggerType === BlueprintTriggerType.WEB_CONNECTOR)
          subTitle = "Triggered by incoming web connector request";
        else subTitle = getTriggerFrequencyString(schedule_interval_value, schedule_interval_unit);
        break;
      case BlueprintOperationType.VALIDATE:
        subTitle = "Triggered at completion of linking flow";
        break;
      case BlueprintOperationType.ENDPOINT_MODIFICATION:
        subTitle = "Triggered by a parent blueprint\nor a passthrough request";
        break;
      case BlueprintOperationType.FETCH_FILTER_OPTIONS:
        subTitle = "Triggered by selective sync filter setup flow";
        break;
      case BlueprintOperationType.ACCOUNT_DELETION:
        subTitle = "Triggered by linked account deletion";
        break;
      default:
        break;
    }
  }

  return (
    <StepCard
      cardClassName={cardClass}
      title={
        operationType === BlueprintOperationType.WEBHOOK_SETUP ? "Trigger Webhook Setup" : "Trigger"
      }
      subtitle={subTitle}
      stepIconClassName="fe-clock"
      onClick={
        (operationType === BlueprintOperationType.FETCH ||
          operationType === BlueprintOperationType.WEBHOOK_SETUP) &&
        triggerType === BlueprintTriggerType.PERIODIC_TASK
          ? () => setSelectedStep(trigger)
          : () => null
      }
    />
  );
};

export default BlueprintTriggerCard;
